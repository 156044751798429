import { FormHelperText, useTextFieldError } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import Chips, { ChipsProps } from './Chips';
import { red } from '../../constants';

interface FormChipsProps extends ChipsProps {
    classes: ChipsProps['classes'] & { errorContainer?: string };
};

const FormChips: React.FC<FormChipsProps> = ({ id, classes: propClasses, ...rest }) => {

    const errorText = useTextFieldError(id);
    const classes = useStyles();

    return (
        <div>
            <Chips id={id} classes={{ chip: clsx(propClasses?.chip, errorText && classes.error), container: propClasses?.container }} {...rest} />
            <FormHelperText className={propClasses.errorContainer} text={errorText} error />
        </div>
    )
};

export default FormChips;

const useStyles = makeStyles(({
    error: {
        borderColor: red
    }
}));