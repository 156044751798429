import { useMemo } from "react";

import { Candidate, CandidateTableTypes, TableColumn, TableProps } from "../types";
import { SOCKET_CANDIDATE_REQUEST_STATUS_CHANGE } from "../constants";

import { Table } from "./Table";

// takes the url it would like to use in the table and where it would like to go from there
interface CandidateTableProps extends Omit<TableProps, "columns"> {
  requestId?: string;
  type?: CandidateTableTypes;
  additionalColumns?: TableColumn<Candidate>[];
}

const GenericCandidateTable: React.FC<CandidateTableProps> = ({ requestId, type, additionalColumns = [], ...rest }) => {

  const columns: TableColumn<Candidate>[] = useMemo(() => [
    { field: 'imagePath', title: '', type: 'image' },
    { field: 'name', title: 'שם מלא', type: 'boldSearch' },
    { field: 'gender', title: 'מִין' },
    { field: 'city', title: 'עיר' },
    { field: 'phone', title: 'טלפון', type: 'boldSearch' },
    { field: 'email', title: 'מייל', type: 'boldSearch' },
    ...additionalColumns,
  ], [additionalColumns]);

  return (
    <Table
      //TODO: why are we using the location and not type here?
      changeRowsSocketEvent={requestId && type === CandidateTableTypes.Pending ? SOCKET_CANDIDATE_REQUEST_STATUS_CHANGE : undefined}
      columns={columns}
      requestId={requestId}
      {...rest}
    />
  );
};

export default GenericCandidateTable;
