import Chip from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface TableChipProps {
    label?: string;
    color?: string;
};

export const TableChip: React.FC<TableChipProps> = ({ label, color }) => {

    const classes = useStyles();

    return (
        <Chip
            size="small"
            className={classes.oval}
            label={label}
            style={{
                color: color ?? 'transparent',
                border: color ? `1px solid ${color}` : 'transparent',
                // backgroundColor: color ? `${color}22` : 'transparent',
            }}
        />
    )
};

const useStyles = makeStyles({
    oval: {
        fontFamily: 'Assistant-Regular',
        backgroundColor: 'transparent',
    },
});