import { createRules, fullNameRegex, activePhoneRegex, Option } from "@hilma/forms"
import { isDate, isMobilePhone, length, isString, isEnum, isDefined, isNumber } from 'class-validator';
import moment from "moment";

import { Grade, Subject } from "../../types";
import { gradeLabels } from "..";

export const requestForSubstituteRules = createRules({
    schoolName: { required: true, classValidator: (value: { id: number, name: string }) => isNumber(value.id) && isString(value.name) },
    phone: { required: true, activeRegex: activePhoneRegex, classValidator: (value: string) => isMobilePhone(value, 'he-IL') && (new RegExp(/^\d+/)).test(value) && length(value, 9, 10) },
    date: { dataType: 'date', required: true, classValidator: (value: string) => isDate(value) && moment().isSameOrBefore(new Date(value).setHours(23)), activeRegexErr: 'יש לבחור תאריך שעדיין לא עבר' },
    time: { dateType: 'timeRange', required: true },
    note: { name: 'הערות', required: false, greaterThan: 2, smallerThan: 2000, classValidator: (value: string) => isString(value) },
    sector: { required: true, classValidator: (value: Option) => isString(value.id) && isString(value.value) },
    grade: { required: true, classValidator: (value: Option) => isNumber(value.id) && isEnum(value.value, gradeLabels) },
    subject: { required: true, classValidator: (value: Subject) => isNumber(value.id) && isString(value.heName) },
})