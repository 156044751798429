import {  Language } from '@hilma/forms';
import { createI18nHooksAndProvider, TranslatedI18n } from '@hilma/i18n';
import { createRef } from 'react';
import { createI18n } from './createTextFunctions';
import errorText from './errorText';

const i18n = createI18n({
	error: errorText,
}); // Put here all the texts you want to translate

export const {
	I18nProvider,
	contexts,
	useLanguage,
	createI18nHook,
	isLanguage,
	useChangeLanguage,
	useDirection,
	useLanguageEffect,
	useNoLanguagePathname,
	usePathLanguage,
	useSwitchedPath,
	useTransform,
	useTransformObject,
	createTranslateHook,
	createTranslateTextsHook,
	createTranslatedI18nHook,
	LanguageRoutes
} = createI18nHooksAndProvider(Language, i18n);

export type Localize<T = any> = {
	[K in keyof T as K | `${Language}${Capitalize<string & K>}`]: T[K];
}
export type I18n = typeof i18n;
export const useI18n = createI18nHook<I18n>();
export const useTranslate = createTranslateHook<I18n>()
export const useTranslatedI18n = createTranslatedI18nHook<I18n>();
export const useTranslateTexts = createTranslateTextsHook<I18n>();
export const createI18nRef = () => createRef<TranslatedI18n<I18n>>();