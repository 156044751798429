import makeStyles from "@material-ui/core/styles/makeStyles";
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

import { ServerResponseForRequest } from "../types";
import { teal } from "../constants";

import { FormattedDate } from "./FormattedDate";
import { FormattedTime } from "./FormattedTime";


const SimpleRequest: React.FC<{ data: ServerResponseForRequest, open: boolean }> = ({ open, data }) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.info}>
                <div className={classes.title}>
                    <div className={classes.schoolName}>{data?.school?.name}</div>
                    <div className={classes.details}>{data.subject?.heName}</div>
                </div>
                <div className={classes.box}>
                    <FormattedDate classes={{ text: classes.details }} date={data.date} />
                    <span className={classes.details}>{data.heAddress?.description ?? data.heAddress ?? ""}</span>
                    <FormattedTime classes={{ time: classes.details }} startTime={data.startTime} endTime={data.endTime} />
                    <span className={classes.details}>{data.phone}</span>
                </div>
            </div>
            {open ? <ExpandLess fontSize="large" color="primary" /> : <ExpandMore fontSize="large" color="primary" />}
        </div>
    )
}

export default SimpleRequest

export const useStyles = makeStyles(theme => ({
    container: {
        background: 'white',
        height: 100,
        display: 'flex',
        alignItems: 'center',
        padding: '20px 0',
        justifyContent: 'space-between',
        borderRadius: 10,
        [theme.breakpoints.down(800)]: {
            height: '100px'
        }
    },
    info: {
        width: '80%'
    },
    box: {
        display: 'flex',
        width: "100%",
        justifyContent: 'space-between',
        marginTop: "1em",
        fontFamily: 'Assistant-semiBold',
        [theme.breakpoints.down(800)]: {
            justifyContent: 'flex-start',
            textAlign: 'initial',
            flexWrap: 'wrap',
            gap: 8
        }
    },
    details: {
        color: teal,
        fontSize: '0.9rem',
        fontFamily: 'Assistant-semiBold',
    },
    schoolName: {
        color: teal,
        fontSize: '1.3rem',
        fontFamily: 'Assistant-Bold',
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
}));
