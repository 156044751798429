import { FormImage, FormInput, FormSelect, useAlert, useForm, useGetError, useGoogleMaps } from '@hilma/forms';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import { useState } from 'react';

import { CitiesAutocomplete, LocationAutocomplete } from '../../componenets';
import { SchoolSignUpScreens, Sector as Sect } from "../../types";
import { sectorsObject, teal } from "../../constants";
import { useGetOptions } from '../../hooks';

interface SchoolInfoFormProps {
	setCurrentScreen: React.Dispatch<React.SetStateAction<SchoolSignUpScreens>>;
}

const SchoolInfoForm: React.FC<SchoolInfoFormProps> = ({ setCurrentScreen }) => {

	const classes = useStyles();
	const mapsLoading = useGoogleMaps();
	const sectors = useGetOptions(Sect, sectorsObject, 'sector');
	const form = useForm();
	const alert = useAlert();
	const getError = useGetError();

	const [loading, setLoading] = useState(false);


	const onSave = () => {
		setLoading(true);

		try {

			setTimeout(() => { }, 0);

			form.handleSubmit();

			if (!!Object.keys(form.errors).length || !Object.values(form.touched).length) return;

			setCurrentScreen(SchoolSignUpScreens.ManagerInfo);

		} catch (err: any) {
			console.error(err);
			alert(getError(err));
		} finally {
			setLoading(false);
		}

	};

	return (
		<div className={classes.formFields}>

			<Box className={classes.inputContainer}>
				<Box>
					<FormInput id='schoolName' description="שם בית הספר" />
					<FormImage size={2_000_000} id='image' />
				</Box>

				<Box className={classes.otherSide}>
					<FormInput id='schoolSymbol' description="סמל מוסד" />
					<FormInput id='phone' description="טלפון" />

					<FormSelect id='sector' description='מגזר' options={sectors} />
					<CitiesAutocomplete loading={loading} id='city' description='יישוב' />
					<LocationAutocomplete address={form.values.heAddress} id='heAddress' description='כתובת' loading={loading || mapsLoading} />
				</Box>
			</Box>

			<Button className={classes.nextButton} onClick={onSave}>המשך</Button>

		</div>
	);
}

export default SchoolInfoForm;

const useStyles = makeStyles(theme => ({
	formFields: {
		width: '80vw',
		margin: '0 auto',
		display: 'flex',
		flexDirection: 'column',
		'& span': {
			animation: 'none'
		},
		'& .MuiInput-input': {
			borderRadius: 5
		},
		[theme.breakpoints.down(800)]: {
			width: '90vw'
		}
	},
	inputContainer: {
		display: 'flex',
		gap: '5vw',
		[theme.breakpoints.down(800)]: {
			flexDirection: 'column'
		}
	},
	otherSide: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between'
	},
	nextButton: {
		background: teal,
		color: 'white',
		fontFamily: 'Assistant-Bold',
		width: 'fit-content',
		alignSelf: 'flex-end',
		'&:hover': {
			background: '#256578'
		}
	},
}));