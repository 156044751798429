import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';

interface ChipItemProps {
    value: number | string;
    onClick: () => void;
    selected?: boolean;
    disabled?: boolean;
    className?: string;
}

const ChipItem: React.FC<ChipItemProps> = ({ value, onClick, selected, disabled, className }) => {
    const classes = useStyles();

    return (
        <div
            className={clsx(classes.chipItem, (selected ? classes.selected : classes.notSelected), (disabled && classes.disabled), className)}
            onClick={!disabled ? onClick : () => { }}>
            {value}
        </div>
    );
}

export default ChipItem

export const useStyles = makeStyles(theme => ({
    icon: {
        color: "#35788C",
        height: "0.7em",
        cursor: "pointer",
        marginInlineStart: "5px"
    },

    chipItem: {
        width: "fit-content",
        padding: "0.1em 0.6em",
        borderRadius: "100px",
        fontSize: '1em',
        color: "#3E504C",
        fontFamily: 'Assistant',
        display: "flex",
        alignItems: "center",
        marginInlineEnd: "1em",
        // marginBottom: "1em",
        border: "0.9px solid #3E504C",
        cursor: "pointer",

        [theme.breakpoints.down('sm')]: {
            marginTop: "10px",
            fontSize: "1.2em"
        },
    },

    selected: {
        backgroundColor: "#CDEDF4",
        opacity: "1"
    },

    notSelected: {
        opacity: "0.5"
    },

    disabled: {
        cursor: "auto",
        '&$selected': {
            backgroundColor: "#e2e2e2"
        }
    }
}));