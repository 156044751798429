import React, {ReactElement, useState} from 'react';
import { MdDelete } from 'react-icons/md';
import {ConfirmPopup} from "../../../general";
import {ButtonProps} from "@material-ui/core/Button";
import {DeleteButtonProps} from "../../../types/interfaces/delete-button-props.interface";

interface DeleteCellProps {
    onDelete(): void;
    deleteButtonProps?: DeleteButtonProps;
}

const DeleteCell: React.FC<DeleteCellProps> = ({ onDelete ,deleteButtonProps}) => {
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const onConfirm = async () => {
        setDeleteModalVisible(false);
        await onDelete();
    }

    const onSecondConfirm = async ()=>{
        setDeleteModalVisible(false)
    }
    return (
        <div style={{position: 'relative'}}>
        <div style={{
            height: "78px",
            display: "flex",
            alignItems: "center",
            backgroundColor: "",
            justifyContent: "space-around"
        }} onClick={() => setDeleteModalVisible(true)}>
            <MdDelete />
        </div>
        
		{deleteModalVisible ? (
                <ConfirmPopup
                    onClose={onSecondConfirm}
                    onConfirm={onConfirm}
                    onSecondConfirm={onSecondConfirm}
                    open={true}
                    {...deleteButtonProps}
                />
			// <div style={{zIndex:100 ,position:"fixed", width:'100%', background:"rgba(128, 128, 128, 0.3)", height:"100%", display:'flex', alignItems:"center", justifyContent:"center", top: 0, left: 0}}>
			// 	<div style={{width: "20%", background: "white", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", borderRadius: "20px", padding: "25px"}}>
			// 		<span style={{fontSize:'18px', marginBottom: '10px'}}>Are you sure you want to delete this Row</span>
			// 		<div style={{width:'100%',height:"50px", display:'flex', alignItems:"center", justifyContent:"space-around",}} >
			// 			<div style={{height:"80%", width:"40%", color:"#fff", background:"green", borderRadius:"10px",display:'flex', alignItems:"center", justifyContent:"center" }} onClick={() => setDeleteModalVisible(false)}>Cancel</div>
			// 			<div style={{height:"80%", width:"40%",color:"#fff", background:"grey", borderRadius:"10px",display:'flex', alignItems:"center", justifyContent:"center" }} onClick={() => {setDeleteModalVisible(false); onDelete()}}>Delete</div>
			// 		</div>
			// 	</div>
			// </div>
            )
            : null
        }
        </div>
    );
}

export default DeleteCell;
