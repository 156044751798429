import { ReactElement, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';

import Button from '../../general/button/Button';
import { usePopupStyles } from '..';

export interface ConfirmPopupProps {
    open: boolean;
    title?: string | ReactElement | undefined;
    text?: string | ReactElement | undefined;
    confirmText?: string;
    onConfirm?: () => Promise<void>;
    secondConfirmText?: string;
    onSecondConfirm?: () => Promise<void>;
    onClose?: () => void;
    children?: React.ReactNode;
    classname?: string;
}

const ConfirmPopup: React.FC<ConfirmPopupProps> = ({ open, title, text, confirmText, onConfirm, secondConfirmText, onSecondConfirm, onClose, classname, children }) => {

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const classes = usePopupStyles()

    const handleConfirm = async (second?: boolean) => {
        if (!onConfirm) return
        setButtonLoading(true)
        if (second && onSecondConfirm) await onSecondConfirm()
        else await onConfirm()
        setButtonLoading(false)
    }

    return (
        <Dialog dir="rtl" open={open}>
            <div className={classes.container}>
                <IconButton className={classes.cancel} disabled={buttonLoading} onClick={onClose && onClose} children={<CloseIcon color="primary" />} />
                <div className={clsx(classes.content, classname)}>
                    <div className={classes.title}>{title}</div>
                    <div className={classes.text}>{text}</div>
                    {children}
                    <div className={classes.bottom}>
                        {secondConfirmText && <Button loading={buttonLoading} onClick={() => handleConfirm(true)} children={secondConfirmText || "מחיקה"} />}
                        <Button loading={buttonLoading} onClick={() => handleConfirm()} children={confirmText || "אישור"} />
                    </div>
                </div>
            </div>
        </Dialog >
    )
}
export default ConfirmPopup
