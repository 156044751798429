import { useNavigate, useParams } from 'react-router-dom';
import { useAlert } from '@hilma/forms';
import axios from 'axios';

import { GenericCandidateTable, TitleAndBackArrow } from '../../componenets';
import { Candidate, RequestTableTypes } from '../../types';
import { errors } from '../../constants';

const ReassignRequestPopup: React.FC = () => {

    const { requestId } = useParams<{ requestId: string }>();
    const navigate = useNavigate();
    const alert = useAlert();

    const onClose = () => {
        navigate(`/requests/${RequestTableTypes.Closed}/${requestId}`);
    }

    const handleSendAssignRequest = async (chosenCandidates: Candidate[]) => {
        try {
            await axios.post('/api/request-candidate/admin-approved', { chosenCandidateIds: chosenCandidates.map(c => c.candidateId), requestId });
            alert('הודעה נשלחה למורים', 'success');
            navigate(`/requests/${RequestTableTypes.Pending}/${requestId}`);
        } catch (e) {
            console.error('error: ', e);
            alert(errors.generalError);
        }
    }

    return (
        <>
            <TitleAndBackArrow title='בחר מורים להם תשלח אפשרות לשיבוץ:' onClick={onClose} />
            <GenericCandidateTable
                checkboxButtons={[{ children: 'שליחת אפשרות לשיבוץ', onClick: handleSendAssignRequest }]}
                dataUrl={`/api/request-candidate/get-requests-approved-candidates/${requestId}`}
                hideTitle
                checkbox
            />
        </>
    )
};

export default ReassignRequestPopup;