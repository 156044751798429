import { Sector } from "../types";

export const sectorsObject: Record<Sector, string> = {
	[Sector.UltraOrthodox]: 'חרדי',
	[Sector.Arab]: 'ערבי',
	[Sector.State]: 'ממלכתי',
	[Sector.StateReligious]: 'ממלכתי-דתי',
}

export const sectorsArray: { id: string; value: string }[] =
	Object.keys(sectorsObject).map((key) => ({ id: key, value: sectorsObject[key as Sector] }))


