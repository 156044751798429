import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

import { lightGray, teal } from '../constants';

const TermsAndConditions: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={clsx(classes.bold, classes.large)}>תנאי שימוש</div>

            <div className={classes.bold}>המערכת</div>
            <div className={classes.number}>1. <div>מיזם "מורה להחלפה" משמש כמאגר של מורים מחליפים ("<strong>המורים</strong>"), המקוטלגים על פי זמינות, שכבת גילאי לימוד ומקצוע ("<strong>המאגר</strong>"). המערכת ("<strong>המערכת</strong>"), אשר מופעלת על ידי  "מורה להחלפה" ("<strong>המפעיל</strong>"), בשיתוף עם משרד החינוך והמבוססת על המאגר, מאפשרת לבתי ספר במערכת החינוך בישראל לאתר מורים מחליפים זמינים, המתאימים לשכבת הגיל והמקצוע המבוקשים ולבחור את המורה המתאים ביותר עבורם על פי קורות החיים, התעודות, המלצות ותעודות יושר של המורים שבמאגר.</div></div>
            <div className={classes.number}>2. <div>בתי הספר אשר משתמשים במערכת ("<strong>בתי הספר</strong>") רשאים להסמיך אדם ("<strong>המשתמש</strong>") אשר ישתמש במערכת בשמם ומטעמם.</div></div>
            <div className={classes.number}>3. <div>השימוש של המשתמשים במערכת כפוף להוראות תנאי שימוש אלו ("<strong>תנאי השימוש</strong>"). על המשתמש לקרוא תנאי שימוש אלו במלואם בקפידה, לפני כל שימוש במערכת. תשומת לב המשתמש מופנית לכך שתנאי השימוש מהווים הסכם מחייב בין המשתמש לבין המפעיל, וכי ככל שהמשתמש אינו מסכים להוראה כלשהי מתנאי השימוש, עליו להפסיק באופן מיידי לעשות שימוש במערכת. בעצם השימוש במערכת מובעת הסכמה לתנאי השימוש, כפי נוסחם מעת לעת.</div></div>
            <div className={classes.number}>4. <div>המפעיל שומר לעצמו את הזכות לשנות את תנאי השימוש, על פי שיקול דעתו הבלעדי, ושינוי זה ייכנס לתוקפו מיד עם פרסומו במערכת (או במועד אחר שייקבע על ידי המפעיל). המפעיל אינו מתחייב למסור למשתמש הודעה מראש על שינויים כאמור, ולכן יש לבחון את תנאי השימוש לפני כל שימוש במערכת.</div></div>
            <div className={classes.number}>5. <div>המפעיל שומר לעצמו את הזכות לשנות את המערכת, תכולתה, עיצובה ו/או אופן פעולתה לפי שיקול דעתו הבלעדי, וזאת ללא כל צורך ליתן הודעה מראש ו/או בדיעבד למשתמש.</div></div>
            <div className={classes.number}>6. <div>המפעיל רשאי לחסום בפני כל משתמש את השימוש במערכת על פי שיקול דעתו הבלעדי וללא כל הודעה מוקדמת.</div></div>
            <div className={classes.number}>7. <div>לשם נוחות הקריאה תנאי השימוש מנוסחים בלשון זכר, אולם הם מתייחסים לגברים ונשים באותה המידה. מקום בו נעשה שימוש בלשון רבים, הכוונה גם ללשון יחיד, ולהיפך.</div></div>

            <div className={classes.bold}>רישום וכניסה למערכת</div>
            <div className={classes.number}>8. <div>כל משתמש יכול להירשם למערכת באמצעות הזנת נתונים מזהים, פרטי בית הספר וקוד המשתמש והסיסמא האישיים שניתנו לו על ידי משרד החינוך-סיסמה אחידה. ("<strong>פרטי הזיהוי</strong>").</div></div>
            <div className={classes.number}>9. <div>לאחר הזנת פרטי הזיהוי ונתונים אישיים נוספים על המשתמש, ייפתח למשתמש חשבון במערכת ("<strong>החשבון</strong>").</div></div>
            <div className={classes.number}>10.<div> באחריותו של המשתמש לשמור על פרטי הזיהוי שלו ולא למסור אותם לאחר. בהתאם לכך, המשתמש מתחייב (א) לא לגלות את פרטי הזיהוי שלו לאחר; (ב) לשמור את פרטי הזיהוי שלו במקום נסתר ומוגן; ו- (ג) ליידע את המפעיל מיד עם היוודע הדבר על כל שימוש לא מורשה בחשבון שלו.</div></div>

            <div className={classes.bold}>מכרזים</div>
            <div className={classes.number}>11. <div>המערכת מאפשרת למשתמש לאתר מורים מחליפים לשיעורים בבית הספר באמצעות הגשת הצעות לאיתור מורה מחליף ("<strong>מכרזים</strong>").</div></div>
            <div className={classes.number}>12. <div>לאחר הגשת מכרז, המשתמש יקבל את תוצאות המכרז אשר כוללות את פרטי המורים אשר מתאימים למכרז אשר הוגש על ידו ("<strong>תוצאות המכרז</strong>").</div></div>
            <div className={classes.number}>13. <div>תוצאות המכרזים מותאמות לזמינות, שכבת גילאי לימוד ומקצוע של המורים (כפי שאלו מוזנים על ידי המורים למערכת), וכן לנתונים אודות "התנהגות" המורים במערכת (כגון שיעורי ההיענות שלהם למכרזים קודמים, נתוני הנבצרות שלהם משיעורים שנקבעו להם באמצעות המערכת.</div></div>
            <div className={classes.number}>14. <div>לאחר קבלת תוצאות המכרז, המשתמש רשאי לבחור בכל אחד מהמורים הכלולים בתוצאות המכרז. מורים אשר נבחרו על ידי המשתמש כאמור יקבלו באמצעות החשבונות שלהם הודעה אשר משמעותה הצעה לשיבוץ לשיעור מסוים כמורה מחליף בבית הספר.</div></div>
            <div className={classes.number}>15. <div>לאחר היענות המורה למכרז, המשתמש יעביר למורה האמור את החומר הנדרש לשיעור שיועבר על ידו והנחיות נוספות ככל הנדרש, ובלבד שהחומר וההנחיות הללו יועברו אל המורה בהתראה סבירה מראש לפני השיעור המיועד ובכתב.</div></div>

            <div className={classes.bold}>אבטחת מידע</div>
            <div className={classes.number}>16. <div>נתונים השמורים במערכת מוגנים באמצעות אמצעי אבטחת מידע ראויים שנועדו לשמור על סודיות, שלמות וזמינות הנתונים על בתי הספר והמורים הכלולים במאגר.</div></div>
            <div className={classes.number}>17. <div>עם זאת, המידע השמור על גבי המכשיר של המשתמש אינו מוגן באמצעים אלו, והמשתמש הוא זה האחראי להגנתו. לשם כך מומלץ להפעיל כלי אבטחת מידע, כגון אך לא רק אנטי וירוס.</div></div>

            <div className={classes.bold}>קניין רוחני</div>
            <div className={classes.number}>18. <div>המערכת, המאגר ותכניהם הינם רכושו הבלעדי של המפעיל. המפעיל הינו הבעלים ובעל רישיון שימוש בכל זכויות יוצרים, זכויות בסימני המסחר, זכויות בעיצובים, וכן בכל זכויות קניין רוחני אחר, בין אם רשומות ובין אם אינן רשומות, הגלומות במערכת ותכניה, לרבות, אך מבלי למעט, המאגר, גרפים, תבניות, אובייקטים, עיצובים, סימנים, סמלים, דמויות, אייקונים, סרטונים, תמונות, טקסטים, מודלים, מדדים, חישובים, רעיונות, הצעות, המלצות, הערכות, דוחות וניתוחים הכלולים במערכת. אין בשימוש אשר נעשה במערכת או בשירותים הגלומים בה כדי להקנות למשתמש זכות כלשהי מזכויות הקניין הרוחני כאמור במערכת, מלבד הזכות לעשות שימוש במערכת או בשירותים הגלומים בה בהתאם לאמור בתנאי השימוש.</div></div>
            <div className={classes.number}>19. <div>המפעיל נותן למשתמש הרשאה הכפופה לתנאי השימוש ושאינה ניתנת להעברה או להסבה לצד שלישי, לעשות שימוש במערכת ובשירותים הגלומים בה.</div></div>
            <div className={classes.number}>20. <div>המשתמש נותן למפעיל הרשאה להשתמש בתוכן והמידע האחר שהוא מזין למערכת לצורך מתן השירותים המגולמים במערכת ובהתאם לתנאי השימוש.</div></div>
            <div className={classes.number}>21. <div>בנוסף, המשתמש נותן למפעיל הרשאה לא מוגבלת בזמן והניתנת להעברה או להסבה לצד שלישי, להשתמש בתוכן ומידע אחר שהוא מזין למערכת ושאינו מזהה אדם ספציפי (דהיינו, מידע אנונימי) לכל מטרה שהיא.</div></div>

            <div className={classes.bold}>פרטיות</div>
            <div className={classes.number}>22. <div>המפעיל (ו/או מי מטעמו) אוסף ושומר מידע המתקבל מן המשתמש וכן מפעילותו של המשתמש במערכת. המידע שנאסף ונשמר על ידי המפעיל כאמור כולל, בין השאר, את שם המשתמש, פרטי קשר שלו, בית הספר שהוא מועסק או מוצב בו, המכרזים שהוא הגיש באמצעות המערכת ויתר נתוני השימוש שלו במערכת.</div></div>
            <div className={classes.number}>23. <div>בעצם הרישום למערכת ובכל פעם שהמשתמש משתמש במערכת, המשתמש נותן את הסכמתו כי המפעיל (ו/או מי מטעמו) יאסוף מידע על המשתמש, יעשה שימוש במידע ואף יעביר את המידע לצדדים שלישיים, בהתאם לאמור בתנאי השימוש.</div></div>
            <div className={classes.number}>24. <div>כל שימוש במידע על ידי צדדים שלישיים הינו באחריותם הבלעדית של אותם צדדים שלישיים והמפעיל לא יישא באחריות כלשהי לשימוש כאמור.</div></div>
            <div className={classes.number}>25. <div>יובהר, כי אין על המשתמש חובה למסור פרטים כלשהם למפעיל, אך אם לא ימסור פרטים כאמור, עשויה חוויית המשתמש להיפגם והמערכת (כולה או חלקה) לא תהיה זמינה למשתמש.</div></div>
            <div className={classes.number} id="26">26. <div>המפעיל רשאי לעשות שימוש במידע על המשתמש למטרות הבאות: (א) לצורך הפעלת המערכת ומתן השירותים הגלומים בה; (ב) לצורך התאמת המערכת ושירותיה לצרכים וההעדפות של המשתמש; (ג) על מנת לשפר את המערכת והשירותים הגלומים בה ולהציע שירותים נוספים; (ד) על מנת ליצור עם המשתמש קשר ככל שנדרש לצורך מתן השירותים הגלומים במערכת; (ה) על מנת לקיים חובה חוקית או דרישה של בית משפט או רשות ציבורית שמחייבת את המפעיל; ו- (ו) על מנת להגן על הזכויות או האינטרסים של המפעיל או של צד שלישי כלשהו.</div></div>
            <div className={classes.number}>27. <div>כמו כן, המפעיל רשאי להשתמש במידע שאינו מזהה אדם כלשהו (דהיינו, מידע אנונימי), למטרות נוספות על אלו המפורטות בסעיף <a href='#26'>26</a> לעיל.</div></div>
            <div className={classes.number}>28. <div>המפעיל רשאי למסור את המידע לצדדים שלישיים הבאים או למטרות הבאות: (א) לחברות שמוחזקות על ידי המפעיל ולחברות קשורות שלהן (יחד עם המפעיל- "<strong>הקבוצה</strong>")), לצרכי הקבוצה ובהתאם לתנאי השימוש; (ב) לצדדים שלישיים אשר נותנים למפעיל שירותים; (ג) לשותפים עסקיים של המפעיל; (ד) ככל שהמפעיל ימכור או יסב את זכויותיו (כולן או חלקן) במערכת או במאגר לצד שלישי; או (ה) לכל מטרה אחרת המפורטת בסעיף <a href='#26'>26</a> לעיל.</div></div>
            <div className={classes.number}>29. <div>המשתמש נותן בזאת את הסכמתו לכך שהמפעיל יעביר, על פי שיקול דעתו, את המידע מחוץ לגבולות ישראל ויאחסן את המידע מחוץ לגבולות ישראל, הגם שדין המדינה אליה מועברים ו/או בה מאוחסן המידע מבטיח רמת הגנה הפחותה מרמת ההגנה על מידע הקבועה בדין הישראלי.</div></div>
            <div className={classes.number}>30. <div>המשתמש זכאי לעיין, בעצמו או על ידי אדם אחר שהוסמך על ידו בכתב או על ידי אפוטרופסו, במידע שעליו המוחזק במאגר של המפעיל.</div></div>
            <div className={classes.number}>31. <div>משתמש שעיין במידע עליו ומצא כי הוא אינו נכון, שלם, ברור או מעודכן, רשאי לפנות למפעיל בבקשה לתקן את המידע או למוחקו.</div></div>

            <div className={classes.bold}>העלאת תוכן למערכת</div>
            <div className={classes.number}>32. <div>המשתמש רשאי להעלות למערכת תוכן ולשתף אחרים בתוכן האמור בכפוף להוראות סעיף <a href='#33'>33</a> מטה.</div></div>
            <div className={classes.number} id="33">33. <div>המפעיל שומר לעצמו את הזכות להסיר כל תוכן שהוא מכל סיבה שהיא, בין אם נכלל בתנאי השימוש ובין אם לאו, שהועלה על ידי משתמש, ללא צורך במתן הודעה מראש למשתמש שהעלה את התוכן ומבלי שיהיה עליו לנמק את הסרת התוכן. אין בהוראות סעיף זה בכדי לחסום את המפעיל מפני כל סעד משפטי אחר נגד משתמש, והמפעיל שומר לעצמו את מלוא הזכויות לנקוט בהליכים משפטיים בהתאם.</div></div>

            <div className={classes.bold}>חובות המשתמש</div>
            <div className={classes.number}>34. <div>המשתמש מתחייב לעשות שימוש במערכת, בשירותים שניתנים באמצעותה, במידע, בתכנים ו/או בשימושים שלהם אך ורק על פי החוק ולא לחבר קישור למערכת ו/או להשתמש במערכת, במידע, בתכנים ו/או בשימושים שלהם לצורך העלאה, הורדה, הפצה, פרסום או שידור של (א) מידע או חומר אחר באופן המפר זכויות כלשהן, לרבות, זכויות קניין רוחני, זכויות להגנת הפרטיות ו/או כל זכות אחרת; (ב) מידע או חומר אחר שהוא פוגעני, מאיים, מעליב, משמיץ, גזעני, בלתי ראוי, פורנוגרפי, מכיל לשון הרע, מפלה ו/או מכיל אלימות; (ג) מידע או חומר אחר הכולל וירוס, תוכנה זדונית, סוס טרויאני, או תוכנה אחרת העלולה לחבל במערכות מחשב של המפעיל ו/או צדדים שלישיים כלשהם או באופן העלול להגביל, להאט או למנוע מאחרים את השימוש במערכת ו/או לפגוע בחוויית השימוש של המערכת; (ד) מידע או חומר אחר המפר דין כלשהו; (ה) מידע הפוגע בשם הטוב ו/או במוניטין של המפעיל; ו/או (ו) מידע או חומר אחר הכולל פרסומת מכל סוג שהוא בלי הרשאה מראש ובכתב של המפעיל.</div></div>
            <div className={classes.number}>35. <div>המשתמש מתחייב שלא לעשות שינויים ו/או להתערב בצורה כלשהי בקוד המקור של המערכת, ו/או לנסות להעתיק את קוד המקור של המערכת ו/או להפיצו בכל אופן שהוא.</div></div>
            <div className={classes.number}>36. <div>המשתמש מסכים כי, מבלי לפגוע בכל זכות אחרת של המפעיל, במקרים בהם המפעיל יחשוש כי השימוש של המשתמש במערכת אינו מתיישב עם הוראות תנאי השימוש ו/או כל דין, יהא המפעיל רשאי להתחקות אחר השימוש של המשתמש במערכת, למנוע מהמשתמש גישה למערכת וכן כל פעולה אחרת אשר המפעיל ימצא לנכון לנקוט כדי להגן על קניינו ו/או על זכויותיו ו/או על זכויות צדדים שלישיים.</div></div>

            <div className={classes.bold}>שיפוי והגבלת אחריות</div>
            <div className={classes.number}>37. <div>המערכת, המידע וכן כל התכנים והשימושים שלהם מוצעים כמות שהם.</div></div>
            <div className={classes.number}>38. <div>המשתמש מסכים ומאשר כי השימוש במערכת נעשה על אחריותו הבלעדית. מובהר, כי המפעיל וכל מי שפועל מטעמו מסירים מעצמם כל אחריות לכל נזק ישיר או עקיף, תוצאתי או מיוחד מכל סוג שהוא, שייגרם למשתמש ו/או לצד שלישי כלשהו כתוצאה מן השימוש במערכת ו/או במידע והתכנים, לרבות כתוצאה מההסתמכות עליהם.</div></div>
            <div className={classes.number}>39. <div>מובהר, כי אין בהכללתו של מורה מסוים במאגר או בתוצאות המכרז כדי להוות חוות דעת או הערכה של המפעיל ביחס למורה כלשהו בכל אופן שהוא.</div></div>
            <div className={classes.number}>40. <div>מבלי לגרוע מכלליות האמור מובהר, כי הנתונים והמסמכים אודות המורים, כגון מסמכי קורות החיים והתעודות שלהם אינם מסופקים על ידי המפעיל כי אם על ידי המורים, ולכן המפעיל אינו נושא בכל אחריות בנוגע לנכונות, דיוק, טיב, רמת ואמינות המידע הכלול בנתונים והמסמכים הללו, לשגיאות, הטעיות, פגיעה באדם או בישות, הפרות חוק או כל פגיעה שהיא הנגרמת מהשימוש או ההסתמכות על הנתונים והמסמכים הללו.</div></div>
            <div className={classes.number}>41. <div>עוד מובהר, כי המערכת משמשת כאמצעי לתיווך בין המורים לבין בתי הספר בלבד, וכי המפעיל אינו צד להתקשרות ביניהם בכל צורה שהיא. בהתאם לכך, המפעיל אינו אחראי לקיום התחייבויות המורים או בתי הספר במסגרת התקשרויות ביניהם כאמור.</div></div>
            <div className={classes.number}>42. <div>המפעיל לא יהיה אחראי לכל תקלה מסוג כלשהו, לרבות תקלות ברשת האינטרנט, בקווי תקשורת, ברשת סלולרית, ברשת תקשורת אלחוטית, במכשיר נייד (לרבות נזק הנגרם למכשיר נייד מהכניסה למערכת ו/או השימוש בה, לרבות מחיקת מידע וצורך בפרמוט המכשיר), בחומרה (לרבות שרתים של המפעיל) ו/או בתוכנה ו/או עקב תחזוקה של המערכת אשר תידרש מעת לעת ו/או תיקונים במערכת. המפעיל לא יהיה אחראי בגין נזקים ישירים או עקיפים, תוצאתיים או מיוחדים מכל סוג שהוא, לרבות אובדן הכנסות או רווחים, אשר ייגרמו כתוצאה מכך שמסיבה כלשהי המערכת ו/או תכניה לא יהיו זמינים בעת מסוימת או לתקופה כלשהי.</div></div>
            <div className={classes.number}>43. <div>המשתמש ישפה ויפצה את המפעיל ו/או מי מטעמו בגין כל נזק, חבות, עלויות, הוצאות ו/או הפסדים (לרבות הוצאות משפטיות) אשר ייגרמו למפעיל ו/או מי מטעמו בקשר עם כל מעשה או מחדל של המשתמש, בקשר עם השימוש במערכת ו/או בתכניה וכן בקשר עם כל הפרה של תנאי השימוש.</div></div>

            <div className={classes.bold}>כללי</div>
            <div className={classes.number}>44. <div>המשתמש מסכים שאין בשימוש במערכת בכל אופן שהוא בכדי ליצור בינו לבין המפעיל כל קשר או יחסים אשר חורגים מעבר לאלה המפורטים בתנאי השימוש.</div></div>
            <div className={classes.number}>45. <div>תנאי השימוש, כנוסחם מעת לעת, מהווים את ההסכם היחיד התקף בין המפעיל לבין המשתמש, ולא יהיה תוקף לכל מצג, הבטחה, הסכמה או התחייבות, בין בכתב ובין בעל פה, שאינם כלולים בתנאי השימוש.</div></div>
            <div className={classes.number}>46. <div>במקרה שבו סעיף כלשהו בתנאי השימוש יימצא לא תקף, בלתי אכיף או בלתי חוקי מסיבה כלשהי על ידי בית משפט מוסמך, תוקפם או אכיפתם של יתר תנאי השימוש לא ייפגעו; מבלי לגרוע מן האמור לעיל, הסעיף שנמצא לא תקף, בלתי אכיף או בלתי חוקי יפורש באופן הקרוב ביותר לנוסחו ולמטרתו, באופן שבעקבות פרשנות שכזו הוא לא יימצא לא תקף, בלתי אכיף או בלתי חוקי.</div></div>
            <div className={classes.number}>47. <div>במידה שהמפעיל לא השתמש או השתהה מלהשתמש בזכות מהזכויות המוקנות לו על פי תנאי השימוש, במקרה מסוים או בסדרת מקרים, לא יראו בכך ויתור על זכות מזכויותיו. אף זכות של המפעיל אינה ניתנת לוויתור אלא בכתב, בהודעה חתומה על ידו. סטייה מתנאי השימוש על ידי המפעיל במקרה מסוים או בסדרת מקרים, לא תהווה תקדים ולא ילמדו ממנה גזירה שווה לכל מקרה אחר בעתיד.</div></div>
            <div className={classes.number}>48. <div>מוסכם כי סמכות השיפוט הבלעדית והייחודית בכל הקשור לתנאי השימוש, ביצועם, פירושם, וכל עניין הקשור ו/או נובע מהם, מוקנית לבית המשפט המוסמך בתל אביב ולו בלבד וזה ייקבע בהתאם להוראות הדין הישראלי.</div></div>
            <div className={classes.number}>49. <div>המפעיל רשאי להסב את זכויותיו במערכת ועל פי תנאי השימוש, כולן או מקצתן, לצד שלישי ללא צורך בקבלת הסכמת המשתמש. המשתמש אינו רשאי להסב זכות כלשהי ללא קבלת הסכמה מפורשת מראש ובכתב מאת המפעיל.</div></div>
            <div className={classes.number}>50. <div>בכל שאלה בנוגע לתנאי השימוש, ניתן ליצור קשר באמצעות משלוח דואר אלקטרוני לכתובת הבאה: moralhahlafa@gmail.com</div></div>
        </div>
    )
};

export default TermsAndConditions;

const useStyles = makeStyles(theme => ({
    container: {
        color: teal,
        textAlign: 'left',
        background: lightGray,
        padding: '40px 100px',
        fontFamily: 'Assistant-Regular',
        [theme.breakpoints.down('sm')]: {
            padding: '20px 25px'
        }
    },
    bold: {
        fontFamily: 'Assistant-Bold',
        fontSize: '1.1rem',
        paddingTop: 20,
        paddingBottom: 10,
    },
    number: {
        paddingLeft: 30,
        lineHeight: 1.4,
        display: 'flex',
        gap: 5,
        textAlign: 'justify'
    },
    large: {
        fontSize: '1.5rem',
        textAlign: 'center'
    }
}));