import makeStyles from '@material-ui/core/styles/makeStyles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import IconButton from '@material-ui/core/IconButton';
import { useFormInputStyles } from '@hilma/forms';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { AdminTable, AdminTableTypes } from '../../types';
import { teal } from '../../constants';

export interface AdminSchoolProps {
	option: AdminTable,
	changeStar(): void,
	canDelete?: boolean,
	showStar: boolean,
	showEdit: boolean,
	onRemove(): void,
	isStar?: boolean,
	schoolId?: string
}

const AdminSchool: React.FC<AdminSchoolProps> = ({ isStar, option, canDelete, onRemove, changeStar, showEdit, showStar, schoolId }) => {

	const navigate = useNavigate();
	const classes = useStyles();
	const inputStyles = useFormInputStyles();

	return (
		<div className={clsx(classes.container, inputStyles.input)}>
			<div className={classes.borderContainer}>
				<div className={classes.name}>{option.name}</div>
				<div className={classes.phone}>{option.adminPhone}</div>
				<div className={classes.email}>{option.email}</div>
			</div>
			<div className={classes.buttonsContainer}>
				{showStar && <IconButton
					onClick={changeStar}
					size="small"
					color="primary"
					children={<Tooltip title={'מנהל ראשי'}>{isStar ? <StarIcon /> : <StarBorderIcon />}</Tooltip>}
				/>}
				{showEdit && <IconButton size="small" onClick={() => navigate(`/admins/${AdminTableTypes.Approved}/${option.id}`, { state: schoolId })} color='primary'><EditIcon /></IconButton>}
				{canDelete ? <IconButton
					color="primary"
					size="small"
					onClick={onRemove}
					children={<AddCircleIcon className={classes.removeIcon} />} />
					: <IconButton size="small" disabled className={classes.disabledButton} />}
			</div>
		</div>
	)
};

export default AdminSchool;

const useStyles = makeStyles(({
	removeIcon: {
		transform: 'rotate(45deg)'
	},

	disabledButton: {
		width: 30,
		height: 30
	},

	name: {
		minWidth: 130,
		textAlign: 'initial'
	},

	phone: {
		width: '12ch'
	},

	email: {
		flex: 1
	},

	container: {
		display: 'flex',
		justifyContent: 'space-between',
		color: teal,
		fontFamily: 'Assistant-Regular',
	},

	borderContainer: {
		borderRadius: "3px",
		paddingLeft: 10,
		width: '100%',
		display: 'flex',
		alignItems: 'center'
	},

	buttonsContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: 15,
		marginRight: 10,
	}
}));