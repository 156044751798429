import { useEffect, useMemo, useState } from 'react';
import { FormInput, FormProvider, GridifyChildren, useAlert, useForm, useOnSubmitRef, useFormInputStyles, FormSelect, useGetError } from '@hilma/forms';
import { useNavigate, useParams } from 'react-router-dom';
import makeStyles from '@material-ui/styles/makeStyles';
import { ButtonProps } from '@material-ui/core/Button';
import { provide, useAsyncEffect } from '@hilma/tools';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { useGetUser } from '@hilma/auth';
import axios from 'axios';
import clsx from 'clsx';

import { TitleAndBackArrow, EditAndDeleteButtons, MultipleSelectAutoComplete, SchoolSelect } from '../../componenets';
import { AdminTableTypes, MultipleSelectOption } from '../../types';
import { adminRules, errors } from '../../constants';
import { FormStyled } from '../../general';
import { useLocation } from 'react-router';
import { SchoolTableTypes } from '../../types/enums/school-table-type.enum';
import { ConnectUserToSchool } from './ConnectUserToSchool';
import Cookies from 'js-cookie';
import { MdDelete } from "react-icons/md"
import { BsPlusCircle } from "react-icons/bs"

interface AdminProps {
	type?: AdminTableTypes;
	isProfileEdit?: boolean;
}

const initialValues = {
	name: '',
	email: '',
	adminPhone: '',
	// school: '',
	schools: [],
	connectedUsers: []
}

const Admin: React.FC<AdminProps> = ({ type, isProfileEdit }) => {

	const [options, setOptions] = useState<MultipleSelectOption[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [newUser, setNewUser] = useState<string>("");

	const { adminId } = useParams<{ adminId: string }>();

	const formInputClasses = useFormInputStyles();
	const onSubmitRef = useOnSubmitRef();
	const navigate = useNavigate();
	const getUser = useGetUser();
	const classes = useStyles();
	const alert = useAlert();
	const form = useForm();
	const getError = useGetError();

	const location = useLocation();

	const admin = Cookies.get("isAdmin")
	var reg = /^\d+$/;
	const phoneRegExp = /^[0][1-9]{1}[0-9]{7,8}$/


	const [connectedUsers, setConnectedUsers] = useState<{ name: string, phone: string, }[]>([])

	function setNewUserValidation(val: string) {
		if ((reg.test(val) && val.length < 11) || val === "") {
			setNewUser(val)
		}
	}


	async function deleteUser(userToDelete: string) {
		const res = await axios.post("/api/candidate/disconnect-candidate-to-school-admin", { username: userToDelete })
		try {
			if (res) {
				const newData = { ...form.values }
				const index = newData.connectedUsers.findIndex((val: string) => val === userToDelete)
				newData.connectedUsers.splice(index, 1)
				form.setValues({ ...newData })

			}
		} catch (err: any) {
			console.error(err);
			alert(getError(err));
			return []
		}

	}
	async function addUser() {

		if (!phoneRegExp.test(newUser)) {
			alert("אנא בדוק את מספר הטלפון")
			return
		}
		try {
			const res = await axios.post("/api/candidate/connect-candidate-to-school-admin", { username: newUser })
			const newData = { ...form.values }
			newData.connectedUsers.push(newUser)
			form.setValues({ ...newData })
			setNewUser("")
		} catch (err: any) {
			console.error(err);
			if (err.data.key === 'alreadyConnected') {
				alert(`המשתמש כבר מקושר ל ${err.data.message} אנא צור קשר עם האדמין על מנת להעבירו`)
			}
			else if (err.data.key === 'userDoseNotExist') {
				alert('מספר הטלפון אינו במערכת')
			}
			else {
				alert(getError(err))
			}

		}
	}


	useEffect(() => {
		getAllConnectedUsers()
	}, [])

	async function getAllConnectedUsers() {
		try {
			const { data } = await axios.get("/api/candidate/get-candidate-connected-to-school-admin")
			const connectedUsersData: [] = data.map((val: { phone: string }) => val.phone)
			return connectedUsersData
		} catch (err: any) {
			alert(getError(err));
			console.error(err);
		}
	}

	const saveAdmin = (add?: boolean) => {
		if (loading) return;
		onSubmitRef.current = () => save(add);
		const requiredValues = isProfileEdit ? ['name', 'adminPhone', 'email'] : ['name', 'adminPhone', 'email', 'schools', 'connectedUsers'];
		form.setFieldValue('requiredValues', requiredValues);
		form.handleSubmit();
	}

	const save = async (add?: boolean) => {
		try {
			if (Object.values(form.errors).some(e => !!e)) return;
			setLoading(true);
			const { data } = await axios.post(`/api/admin/${add ? 'add' : 'update'}/${adminId ?? ''}`,
				{ ...form.values, /* schoolId: form.values?.school?.id  */ });
			alert(add ? errors.addDetailsSuccess : errors.updateDetailsSuccess, 'success');
			if (isProfileEdit) {
				await getUser();
				setLoading(false);
			} else if (!location.state /* didn't come from school editing page */)
				navigate(`/admins/${AdminTableTypes.Approved}/${add ? data.id : ''}`);

			else
				navigate(`/schools/${SchoolTableTypes.Approved}/${location.state}`, { state: true })

		} catch (error: any) {
			if (error.status === 409) {
				alert(errors.emailExists);
				setLoading(false);
				return form.setFieldError('email', errors.emailExists)
			}
			alert(add ? errors.addAdminError : errors.updateDetailsError);
			setLoading(false);
		}
	}

	const handleDelete = async () => {
		try {
			setLoading(true);
			await axios.delete(`/api/admin/delete/${adminId}`);
			alert(errors.deleteAdminSuccess, "success");
			navigate(`/admins/${type}`);
		} catch (error: any) {
			console.error("error: ", error);
			alert(errors.deleteAdminError);
			setLoading(false);
		}
	}

	const ovalButtonProps = useMemo<ButtonProps>(() => {
		if (!type) return { children: 'הוספה', variant: 'contained', color: 'secondary', loading, startIcon: null, style: { color: 'white' }, onClick: () => saveAdmin(true) };
		return { children: 'עדכן', variant: 'contained', color: 'secondary', loading, startIcon: null, style: { color: 'white' }, onClick: () => saveAdmin() };
	}, [type, saveAdmin, loading]);

	useAsyncEffect(async () => {
		try {
			if (!adminId) return;
			setLoading(true);
			const { data } = await axios.get(`/api/admin/get-school-admin-details/${adminId}`);
			data.connectedUsers = await getAllConnectedUsers()
			form.setValues({ ...data,/*  school: data?.school[0], */connectedUsers: [...(data?.connectedUsers || [])], schools: [...(data?.school || []), ...(data?.mainSchool || [])] });
		} catch (error) {
			console.error('error: ', error);
			alert(errors.getDataError);
		} finally {
			setLoading(false);
		}
	}, [adminId]);

	useAsyncEffect(async () => {
		if (isProfileEdit) return;
		try {
			const { data } = await axios.get(`/api/school/get-admin-school-select-options/${adminId}`);//check server side

			setOptions(data.map((school: any) => ({ ...school, hideDelete: Number(school.hideDelete) })));
		} catch (err: any) {
			alert(getError(err));
			console.error(err)
		}
	}, []);


	return (
		<>
			<TitleAndBackArrow
				hideBack={isProfileEdit}
				title={form?.values?.name}
				onClick={() => !location.state ? navigate(`/admins/${AdminTableTypes.Approved}`) : navigate(`/schools/${SchoolTableTypes.Approved}/${location.state}`, { state: true })}
				endComponent={
					<EditAndDeleteButtons
						editButtonProps={ovalButtonProps}
						deleteButtonProps={!isProfileEdit && form.values?.canDelete ? {} : undefined}
						deletePopupProps={{
							title: 'מחיקת מנהל',
							text: <div>האם אתה בטוח שברצונך למחוק את המנהל<strong> לצמיתות</strong>?</div>,
							confirmText: 'מחיקה',
							onConfirm: handleDelete,
						}} />}
			/>
			<FormStyled>
				<GridifyChildren>
					<div>
						<div>
							<FormInput disabled={loading} id='name' description='שם' />
							<FormInput disabled={loading} id='adminPhone' description='טלפון' />
							<FormInput disabled={loading} id='email' description='מייל' />
						</div>
						{/* <SchoolSelect disabled={loading || isProfileEdit || type && !form.values?.canDelete} loading={loading && !form.values.school} id='school' description='בית ספר' /> */} {/* uncomment for one school */}
						<div>
							{isProfileEdit && !form.values.schools?.length ? null : <InputLabel htmlFor='schools' className={clsx(formInputClasses.label, classes.label)}>בתי ספר</InputLabel>}
							{isProfileEdit ?
								form.values.schools?.map((school: any) => <Input disabled disableUnderline className={clsx(formInputClasses.input)} value={school.name} />)
								:
								<MultipleSelectAutoComplete
									id='schools'
									placeHolder='בחר בית ספר'
									fetchUrl={`/api/school/get-admin-school-select-options/${adminId}`}
									isLoading={loading && !form.values.schools?.length}
									renderOptionProps={(option: any) => ({ canDelete: !options.find(o => o.id === option.id)?.hideDelete })}
								/>
							}
						</div>
					</div>
					{admin || true && <div>
						<div className={classes.connectedUsersHeadlineDiv}>
							<h4 >משתמשים מקושרים</h4>
						</div>
						{form.values.connectedUsers?.map((user: any, index: number) => {
							return (
								<div className={classes.connectedUsers}>
									<div className={classes.inputDiv}>
										<Input disabled disableUnderline className={clsx(formInputClasses.input)} value={user} />
									</div>
									<div className={classes.iconDiv}>
										<MdDelete title='delete' tabIndex={index} className={classes.deleteUser} onClick={() => deleteUser(user)} />
									</div>
								</div>
							)
						})}
						<div className={classes.connectedUsers}>
							<div className={classes.inputDiv}>
								<Input disableUnderline className={clsx(formInputClasses.input)} placeholder="הוסף משתמש חדש" onChange={(e) => setNewUserValidation(e.target.value)} value={newUser} />
							</div>
							<div className={classes.iconDiv}>
								<BsPlusCircle title='add' tabIndex={1} className={classes.deleteUser} onClick={addUser} />
							</div>
						</div>

					</div>}
				</GridifyChildren>
			</FormStyled>


		</>
	)
};

const AdminComp: React.FC<AdminProps> = (props) => (
	<FormProvider initialValues={initialValues} rules={adminRules}>
		<Admin {...props} />
	</FormProvider>
);
export default AdminComp;

const useStyles = makeStyles({
	label: {
		marginTop: 5
	},
	connectUser: {
		width: "7vw",
		minWidth: "fit-content",
		borderRadius: "200px",
		alignSelf: "flex-end",
		margin: "10px 0px",
		whiteSpace: "nowrap",
		padding: "5px 10px",
		backgroundColor: "#42BFDD",
		border: "none",
		color: "white",
		fontFamily: "Assistant-SemiBold",
		fontSize: "0.9rem",
		'&:hover': {
			backgroundColor: '#39707d',
		},
	},
	connectedUsersHeadlineDiv: {
		display: "flex",
		flexDirection: "row",
		justifySelf: 'flex-start',
	},
	connectedUsers: {
		width: "100%",
		whiteSpace: "nowrap",
		display: "flex",
		flexDirection: "row",
		justifySelf: 'flex-start',
		alignContent: "flexEnd"


	},
	inputDiv: {
		width: "90%",

	},
	deleteUser: {
		fontSize: "2rem",
		color: 'gray',

		'&:hover': {
			color: '#39707d',
			// color: '#39707d',
			cursor: "pointer"
		},
	},
	iconDiv: {
		// backgroundColor: "green",
		width: "10%",
		display: "flex",
		flexDirection: "row",
		justifyContent: 'center',
		alignItems: "center"
	}

});