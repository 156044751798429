import { ReactElement, useCallback, useState } from 'react';
import produce from 'immer';
import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableRowMui from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import Slide from '@material-ui/core/Slide';
import DeleteCell from './tableCells/DeleteCell';
import { useSetTableCheckedCells, useTableCheckedCells, useTableHasCheckbox, useTableHasWholeRowClick, useTableLeavingRows, useTableRowsPerPage, useTablePage, useTableEnteringRows, useTableHideArrowButton } from '../../hooks';
import { ColumnTypeConfig, TableColumnKey, TableRow } from '../../types';
import {errors, teal, turquoise} from '../../constants';
import React from 'react';
import { ArrowCell, CheckboxCell, AllCells } from './tableCells';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import {DeleteButtonProps} from "../../types/interfaces/delete-button-props.interface";

interface BodyTableProps<T extends TableRow = TableRow> {
	rows: any[];
	config: ColumnTypeConfig<T>;
	columnKeys: Array<TableColumnKey<T>>;
	arrowOnClick?(row: {}, index: number): void;
	onAction(row: {}, action: string): void;
	actionButtons: string[]
	to?(row: {}, index: number): string;
	deleteButtonProps?: DeleteButtonProps;
};



const BodyTable = <T extends TableRow = TableRow>({ columnKeys, rows, arrowOnClick, to, onAction, actionButtons, config, deleteButtonProps }: BodyTableProps<T>): ReactElement => {
	const [loading, setLoading] = useState<boolean>(false);
	const setCheckedCells = useSetTableCheckedCells();
	const hideArrowButton = useTableHideArrowButton();
	const wholeRowClick = useTableHasWholeRowClick();
	const checkedCells = useTableCheckedCells();
	const rowsPerPage = useTableRowsPerPage();
	const entering = useTableEnteringRows();
	const checkbox = useTableHasCheckbox();
	const leaving = useTableLeavingRows();
	const page = useTablePage();
	const classes = useStyles();
	const navigate = useNavigate();
	const isMobile = useMediaQuery('(max-width: 800px)');
	const [deletePopup, setDeletePopup] = useState<boolean>(false);
	const handleCheckboxClick = useCallback(
		(checked: boolean, index: number) => {
			!checked ?
				setCheckedCells(prevChecked => prevChecked.filter((val) => val !== index)) :
				setCheckedCells(produce(draft => { draft.push(index) }));
		}, [setCheckedCells]);
	const handleDelete = async (row:any) => {
		try {
			setLoading(true);
			await axios.delete(`/api/school/delete/${row.id}`);
			// alert(errors, "success");
			navigate(`/schools/${row.type}`);
		} catch (error: any) {
			console.error("error: ", error);
			alert(errors.deleteAdminError);
			setLoading(false);
		}
	}
	return (
		<>

		<TableBody>
			{rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage).map((row: T, index: number) => {
				const isChecked = checkedCells.includes(page * rowsPerPage + index);
				const inLeaving = leaving ? leaving.includes(page * rowsPerPage + index) : false;
				const inEntering = entering ? entering.includes(page * rowsPerPage + index) : false;
				return (
					<Slide timeout={!(inLeaving) ? 0 : 500} in={!(inLeaving)} key={index} direction="right">
						<TableRowMui
							style={!isMobile ? { backgroundColor: isChecked ? teal + '20' : 'transparent', cursor: wholeRowClick ? 'pointer' : undefined, height: "3rem" } : { position: 'relative' }}
							className={clsx(inEntering && classes.entering, classes.borderBottom, classes.mobileContainer)}
							key={index}
						>
							{checkbox && (
								<CheckboxCell
									isChecked={isChecked}
									index={page * rowsPerPage + index}
									handleCheckboxClick={handleCheckboxClick}
								/>
							)}
							{columnKeys.map((keyName: TableColumnKey<T>, index) =>
								<React.Fragment key={index}>
									<AllCells
										addTitle={isMobile}
										row={row}
										index={index}
										keyField={keyName as TableColumnKey<TableRow>}
										config={config}
										onClick={() => wholeRowClick && arrowOnClick && arrowOnClick(row, index)}
									/>
									{/* {handleDeleteRow && index === columnKeys.length - 1 && <DeleteCell onDelete={handleDeleteRow} />} */}
								</React.Fragment>
							)}
							{
								actionButtons.map(action => 
									{
										switch(action) {
											case 'delete':
												return <DeleteCell onDelete={() => {onAction(row, 'delete')}}
																   deleteButtonProps={deleteButtonProps}
												/>
											default: 
												return null
										}
									}
							)
							}
							<td className={classes.arrowWrapper}>
								{!hideArrowButton && (arrowOnClick || to) && (
									<ArrowCell
										div
										to={to}
										row={row}
										arrowOnClick={arrowOnClick}
										index={page * rowsPerPage + index}
									/>
									
								)}
							</td>
						</TableRowMui>
					</Slide>
				)
			})}
		</TableBody>
		</>
	)
};

export default BodyTable;

const useStyles = makeStyles(theme => ({
	entering: {
		animation: '$borderGreen 1.6s ease',
		borderRadius: 2,
	},
	"@keyframes borderGreen": {
		"0%": {
			boxShadow: `0px 0px 0px 0px ${turquoise}`,
			borderRadius: 2
		},
		"30%": {
			boxShadow: `0px 0px 0px 3px ${turquoise}`,
			borderRadius: 2
		},
		"100%": {
			boxShadow: `0px 0px 0px 0px ${turquoise}`,
			borderRadius: 2
		},
	},
	borderBottom: {
		borderBottom: `1px solid #00000016`
	},
	mobileContainer: {
		[theme.breakpoints.down(800)]: {
			border: '1px solid #007A8D',
			borderRadius: 20,
			display: 'flex',
			flexDirection: 'column',
			marginBottom: 20,
			maxWidth: '100%',
			padding: '10px 20px',
			'&>td': {
				padding: '5px'
			}
		}
	},
	arrowWrapper: {
		[theme.breakpoints.down(800)]: {
			all: 'inherit',
			border: 'none',
			padding: '0 !important',
			right: 0,
			top: 0,
			position: 'absolute',
		}
	}
}))