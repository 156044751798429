import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useOn, useSocket } from '@hilma/socket.io-react';
import Typography from '@material-ui/core/Typography';
import { useAsyncEffect } from '@hilma/tools';
import Badge from '@material-ui/core/Badge';
import { useAlert } from '@hilma/forms';
import Tab from '@material-ui/core/Tab';
import axios from 'axios';
import clsx from 'clsx';

import { TableGetResponse, TabTuple } from '../../types';
import { errors } from '../../constants';

interface TabLinkProps {
	pathName: string;
	tab: TabTuple;
	subTopBar?: boolean;
}

const TabLink: React.FC<TabLinkProps> = ({ pathName, subTopBar, tab: [title, path, amount], ...rest }) => {

	const [count, setCount] = useState(0);

	const classes = useStyles({ color: amount?.color, background: amount?.background });
	const socket = useSocket();
	const alert = useAlert();

	useAsyncEffect(async () => {
		if (!amount) return;
		try {
			const { data: { count } } = await axios.get<TableGetResponse>(amount.url);
			count && setCount(count);
		} catch (error) {
			console.error('error tabs get amount: ', error);
			alert(errors.getDataError);
		}
	}, []);

	useEffect(() => {
		if (!amount?.socketEvent) return;
		socket.emit(amount?.socketEvent);
		return () => {
			amount?.socketEvent && socket.off(amount?.socketEvent);
		}
	}, [amount]);

	useOn(amount?.socketEvent || '', ({ increase }: { increase?: number }) => {
		setCount(prev => prev + (increase || 0) > 0 ? prev + (increase || 0) : 0)
	});

	return (
		<Link className={classes.link} to={`/${pathName}/${path}`}>
			<Tab
				className={clsx(subTopBar ? classes.subTopBar : classes.regTopBar, classes.label)}
				classes={{ selected: classes.selected }}
				label={
					<div className={classes.label}>
						<Typography className={classes.title}>{title}</Typography>
						{count ?
							<Badge classes={{ anchorOriginTopRightRectangle: classes.badge }} badgeContent={count} />
							: null}
					</div>
				}
				{...rest}
			/>
		</Link>
	);
}

export default TabLink;

const useStyles = makeStyles(theme => ({
	link: {
		textDecoration: 'none',
		color: theme.palette.primary.main,
	},
	label: {
		fontFamily: "Assistant-SemiBold",
		paddingBottom: 0,
		display: 'flex',
		gap: 10,
	},
	title: {
		[theme.breakpoints.down(800)]: {
			whiteSpace: 'nowrap'
		}
	},
	regTopBar: {
		fontSize: '1.1rem',
	},
	selected: {
		fontFamily: 'Assistant-Bold'
	},
	subTopBar: {
		fontSize: '0.9rem',
	},
	count: {
		background: 'red',
		width: '1rem',
		height: '1rem',
		borderRadius: '0.5rem'
	},
	badge: {
		background: ({ background }: { background?: string, color?: string }) => background,
		color: ({ color }: { background?: string, color?: string }) => color,
		transform: 'translate(0%, 0%)',
		fontFamily: 'Assistant-SemiBold',
		position: 'relative',
	}
}));