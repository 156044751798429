import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncEffect } from '@hilma/tools';

import { SOCKET_ADMIN_REMOVE_REQUESTS, SOCKET_ADMIN_REQUESTS, gradeSelect, requestStatusSelect, dateSelect, purple, teal, error } from '../../constants';
import { RequestTableTypes, SelectDropdownMenu, SelectDropdown, TableColumn, RequestTable, RequestStatus } from '../../types';
import { ArrowCellButton } from '../../componenets';
import { useGetDropdownData } from '../../hooks';

import RequestTableGeneric from './RequestTableGeneric';
import NoRequests from './NoRequests';

interface RequestTablesProps {
	type: RequestTableTypes;
}

const RequestTables: React.FC<RequestTablesProps> = ({ type }) => {
	const [subjectOptions, setSubjectOptions] = useState<SelectDropdownMenu[]>([{ value: 'all', label: 'כל המקצועות' }]);
	const getDropdownData = useGetDropdownData();
	const navigate = useNavigate();

	const selects: SelectDropdown[] = useMemo(() => [
		{
			selectName: "subject",
			defaultValue: 'all',
			selectMenu: subjectOptions
		}, gradeSelect, ...(type === RequestTableTypes.Archive ? [requestStatusSelect] : []), dateSelect,
	], [subjectOptions, type]);

	const additionalColumns: TableColumn<RequestTable>[] = useMemo(() => {
		switch (type) {
			case RequestTableTypes.Closed:
				return [{ field: 'acceptorName', title: 'מורה להחלפה', type: 'boldSearch' }];
			case RequestTableTypes.Pending:
				return [{ customRender: (row) => <ArrowCellButton onClick={() => navigate(`/requests/${type}/${row.id}`)} label='מורה להחלפה' /> }];
			case RequestTableTypes.Archive:
				return [{ field: 'acceptorName', title: 'שם מחליף', type: 'boldSearch' }, { title: 'סטטוס', field: 'status', type: 'oval' }];
		}
	}, [type]);

	useAsyncEffect(async () => setSubjectOptions(await getDropdownData('/api/subject/get-subjects-select-options')), []);

	return (
		<RequestTableGeneric
			ovalConfig={{ [RequestStatus.Closed]: ['עם שיבוץ', purple], [RequestStatus.Pending]: ['ללא שיבוץ', teal], [RequestStatus.Deleted]: ['נמחק', error] }}
			tableTitle={type === RequestTableTypes.Pending ? 'קריאות פתוחות' : type === RequestTableTypes.Closed ? 'קריאות סגורות' : 'ארכיון קריאות'}
			shouldRemoveRowOnDeleteSocketEvent={(row, rowsToRemove) => rowsToRemove?.findIndex(r => Number(r.id) === Number(row.id)) > -1}
			addButtonOnClick={type === RequestTableTypes.Pending ? () => navigate('/requests/add') : undefined}
			deleteSocketEvent={type === RequestTableTypes.Pending ? SOCKET_ADMIN_REMOVE_REQUESTS : undefined}
			to={type === RequestTableTypes.Closed ? (row) => `/requests/${type}/${row.id}` : undefined}
			addSocketEvent={type === RequestTableTypes.Pending ? SOCKET_ADMIN_REQUESTS : undefined}
			customNoRows={type === RequestTableTypes.Pending ? <NoRequests /> : undefined}
			arrowOnClickLabel={type === RequestTableTypes.Pending ? 'מורה להחלפה' : undefined}
			arrowOnClick={(row) => navigate(`/requests/${type}/${row.id}`)}
			hideArrowButton={type === RequestTableTypes.Pending}
			onAddSocketEventMessage='התקבלו קריאות חדשות'
			additionalColumns={additionalColumns}
			addButtonLabel='פתיחת קריאה'
			requestData={{ type }}
			selects={selects}
			addButtonYellow
			type={type}
		/>
	)
};

export default RequestTables;