import { useEffect, useState } from 'react';
import { FormInput, FormProvider, useAlert, useForm, useGetError, useUpdateOnSubmit } from '@hilma/forms';
import { useLogin, useLoginWithCode, useSendCode } from '@hilma/auth';
import { provide } from '@hilma/tools';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import makeStyles from '@material-ui/core/styles/makeStyles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import SchoolIcon from '@material-ui/icons/School';
import Button from '@material-ui/core/Button';

import { iconLogoPath, loginRules, smsLoginRules, useFormStyles } from '../constants';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Cookies from 'js-cookie';
import clsx from 'clsx';
import { Background } from '../componenets';

import Button2 from '../general/button/Button';
import AppTitle from '../componenets/AppTitle';


interface LoginProps {
    loginWithSms?: boolean
    setLoginWithSms?: (val: boolean) => void

}

const initialValues = {
    username: '',
    password: '',
}
const initialValuesLoginSms = {
    phone: '',
    smsCode: '',
}

const Login: React.FC<LoginProps> = ({ loginWithSms, setLoginWithSms }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [beforeCode, setBeforeCode] = useState(true);



    const form = useForm();
    const login = useLogin();
    const alert = useAlert();
    const classes = useLoginStyles();
    const formClasses = useFormStyles();
    const loginWithCode = useLoginWithCode();
    const sendAuthCode = useSendCode();
    const getError = useGetError();

    const navigate = useNavigate();


    async function checkIfAdmin(username: string) {
        try {
            const { data } = await axios.get<Promise<boolean>>(`/api/candidate/choose-login-type/${username}`)
            return data
        } catch (err) { console.log(err) }
    }
    useUpdateOnSubmit(async (values) => {
        Cookies.remove("isAdmin")
        sessionStorage.removeItem('loggedIn');

        if (loginWithSms) {
            const isAdmin = await checkIfAdmin(values.phone)
            if (beforeCode) {
                try {
                    if (isAdmin) {
                        const { success, msg } = await sendAuthCode(
                            '/api/admin/send-code', {
                            username: values.phone,
                        }
                        )
                        if (success) {
                            Cookies.set("isAdmin", "true", { expires: 700 })
                            setBeforeCode(false)
                        }else{
                            alert(msg.data.message)
                        }
                    } else {
                        const { success, msg } = await sendAuthCode(
                            '/api/candidate/send-code-from-admin', {
                            username: values.phone,
                        }
                        )
                        if(!success){
                            alert(msg.data.message)
                        }
                    }
                } catch (err) { console.error(err) }
            }

            else {
                try {
                    const lkoo = Cookies.get("lkoo")
                    if (isAdmin) {
                        const res = await axios.post('/api/admin/login-with-code', { adminPhone: values.phone, code: values.smsCode })
                        if (res.status === 201) window.location.reload();
                    }
                    else {
                        const res = await axios.post('/api/candidate/login-with-code-from-admin', { lkoo, code: values.smsCode })
                        if (res.status === 201) window.location.reload();
                    }
                } catch (err: any) {
                        alert(err.data.message ?? getError(err));
                }
            }
        } else {
            const { msg } = await login('/api/admin/login', values);
            if (msg && msg.data?.key) alert('אחד או יותר מהפרטים אינם נכונים');
        }
    });

    function handleEnter(event: any) {
        if (event.key === 'Enter') {
            form.handleSubmit()
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', handleEnter)
        return () => {
            document.removeEventListener('keydown', handleEnter)
        };

    }, [])
    return (
      <>
          <div className={classes.container}>

              {/* <img width="50%" alt="logo" src={iconLogoPath} /> */}
              <div className={classes.loginHome}><a href="https://subteacher.co.il/home" className={classes.loginHomeLink}>
                  <img src={'/images/home-icon.png'} className={classes.logo} alt="logo"/>
              </a></div>
              <AppTitle showLogo/>

              <button className={classes.loginSwitchButton}
                      onClick={() => setLoginWithSms!(!loginWithSms)}>{loginWithSms ? 'התחברות בשם משתמש וסיסמה' : 'התחברות בsms'}</button>

              {
                  loginWithSms ?

                    <>
                        {beforeCode ?
                          <FormInput
                            id="phone"
                            type="tel"
                            description="מספר טלפון"
                            classes={{
                                root: classes.inputRoot,
                                input: formClasses.input,
                                container: classes.inputContainer,
                                errorContainer: classes.error,
                            }}
                          />
                          :
                          <FormInput
                            id="smsCode"
                            type="tel"
                            description="קוד"
                            classes={{
                                root: classes.inputRoot,
                                input: formClasses.input,
                                container: classes.inputContainer,
                                errorContainer: classes.error,
                            }}
                          />}
                    </>
                    : <>
                        <FormInput
                          id="username"
                          type="username"
                          description="אימייל"
                          classes={{
                              root: classes.inputRoot,
                              input: formClasses.input,
                              container: classes.inputContainer,
                              errorContainer: classes.error,
                          }}
                        />
                        <FormInput
                          id="password"
                          description="סיסמה"
                          autoComplete="on"
                          classes={{
                              root: classes.inputRoot,
                              input: formClasses.input,
                              container: classes.inputContainer,
                              errorContainer: classes.error,
                          }}
                          type={showPassword ? 'text' : 'password'}
                          endAdornment={(
                            <IconButton onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                            </IconButton>
                          )}
                        />
                    </>
              }


              <Button2
                classes={{
                    root: classes.buttonRoot,
                    label: classes.buttonLabel,
                }}
                type="submit"
                onClick={form.handleSubmit as () => void}
                color="secondary"
              >
                  {loginWithSms && beforeCode ? 'שליחת קוד אימות' : 'כניסה'}
              </Button2>


              <div className={classes.linkContainer}>
                  <Link to="/reset-password" className={classes.link}>שכחתי סיסמה</Link>
                  <span>|</span>
                  <Link to="/first-login" className={classes.link}>התחברות ראשונית</Link>
              </div>
              <a className={classes.teacherLink} href={`https://subteacher.co.il/sign-in`}>למערכת המורים</a>

              <Link to="/school-sign-up" className={classes.schoolSignupLink}>
                  <Button color="primary" className={classes.schoolSignUp} startIcon={<SchoolIcon />}>רישום בית
                      ספר</Button>
              </Link>
              <Background />
          </div>
      </>
    );
}

const LoginComp: React.FC<LoginProps> = (props) => {
    const [loginWithSms, setLoginWithSms] = useState(true);
    // const [beforeCode, setBeforeCode] = useState(true);

    return (
        <FormProvider initialValues={loginWithSms ? initialValuesLoginSms : initialValues} rules={loginWithSms ? smsLoginRules : loginRules}>
            <Login {...props} /* setBeforeCode={setBeforeCode} beforeCode={beforeCode} */ loginWithSms={loginWithSms} setLoginWithSms={setLoginWithSms} />
        </FormProvider>
    )
};
export default LoginComp;

export const useLoginStyles = makeStyles(theme => ({
    teacherLink: {
        marginTop: "1.5vh",
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
        fontFamily: 'Assistant',
    },

    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        width: '30vw',
        justifyContent: 'center',
        margin: 'auto',
        [theme.breakpoints.down(800)]: {
            width: '72vw'
        }
    },

    loginHome:{
        position: 'absolute',
        top: '40px',
        left: '30px',
        [theme.breakpoints.down(600)]: {
            left: '20px',
        }
    },

    loginHomeLink: {
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
        fontFamily: 'Assistant',
        display: 'block',
    },
    logo: {
        display: 'block',
        width: 60,
        [theme.breakpoints.down(600)]: {
            width: 40
        }
    },

    buttonRoot: {
        width: '100%',
        marginTop: '3vh',
    },
    buttonLabel: {
        fontSize: '2.3vh',
        fontFamily: 'Assistant-SemiBold',
        color: '#FFF'
    },
    linkContainer: {
        margin: '3vh 0 0',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        color: theme.palette.primary.main,
    },
    link: {
        color: theme.palette.primary.main,
        fontSize: '1.4rem',
        fontFamily: 'Assistant',
        flex: "1",
    },
    titleContainer: {
        color: theme.palette.primary.main,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.3em',
        fontWeight: 700
    },
    inputContainer: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
            flexDirection: "column",
        },
        justifyContent: "center",
        alignItems: "flex-start",
        marginTop: "25px",
        position: "relative",
        fontSize: "1rem",
        width: '100%',
    },
    inputRoot: {
        height: "3rem",
        backgroundColor: "white",
        border: "0.5px solid #3E504C",
        borderRadius: "3px",
        color: theme.palette.primary.main,
        display: "flex",
        width: "100%",
        marginTop: '0px',

        '&:focus': {
            borderRadius: "3px !important",
        },
    },
    error: {
        position: "absolute",
        bottom: -15,
        left: 0,
    },
    loginSwitchButton: {
        width: "60%",
        marginTop: "3vh",
        color: "#fff",
        backgroundColor: "#35788C",
        fontSize: "1rem",
        fontFamily: 'Assistant-SemiBold',
        border: "none",
        borderRadius: "3px",
        padding: "5px 10px",
        "&:hover": { cursor: "pointer", },
        [theme.breakpoints.down(800)]: {
            width: 'fit-content'
        }
    },
    schoolSignupLink: {
        textDecoration: 'none',
    },
    schoolSignUp: {
        fontFamily: 'Assistant-SemiBold',
        fontSize: '1.18rem',
        marginTop: 15,
        padding: '3px 10px',
        boxShadow: '0 3px 6px #00000029'
    }
}));