import { useEffect, useMemo } from 'react';
import { PrivateRoute, useAuth, useGetAccessToken } from '@hilma/auth';
import { Route, Routes, Navigate } from 'react-router-dom';
import { SocketProvider } from "@hilma/socket.io-react";
import { useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { provide, wrap } from '@hilma/tools';
import { socketUri } from '@hilma/forms';
import axios from 'axios';

import { RowsPerPageProvider, TableFiltersProvider, TopBarProvider } from '../context';
import { Candidates, Schools, Requests, Admins, EditProfile, Statistics } from '.';
import { TopBar, TopBarMobile, SubTopBar } from '../componenets';
import { backgroundGray } from '../constants';
import { RequestTableTypes } from '../types';

import GenericNotFound from './GenericNotFound';
import ContactUsForm from '../componenets/ContactUs';

interface HomeProps {

}

const Home: React.FC<HomeProps> = ({ }) => {

    const isMobile = useMediaQuery('(max-width: 600px)');
    const classes = useStyles();
    const auth = useAuth();

    const getAccessToken = useGetAccessToken();
    const token = useMemo(getAccessToken, [getAccessToken]);

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem('loggedIn');
        // if (isLoggedIn) return;
        try {
            axios.post('/api/fact-logins/insertNewLogin');
        } catch (err) { console.log(err) }
        // sessionStorage.setItem('loggedIn', 'true');
    }, [])

    return (
        <SocketProvider uri={socketUri} options={{ transports: ['websocket'], auth, query: { token } }}>
            <div className={classes.bigContainer} >
                {isMobile ? <TopBarMobile /> : <TopBar />}
                <div className={classes.container}>
                    <SubTopBar />
                    <Routes>
                        <Route
                            path="/admins/*"
                            element={<PrivateRoute component={Admins} componentName="Admins" />}
                        />
                        <Route
                            path="/schools/*"
                            element={<PrivateRoute component={Schools} componentName="Schools" />}
                        />
                        <Route
                            path="/requests/*"
                            element={<PrivateRoute component={Requests} componentName="Requests" />}
                        />
                        <Route
                            path="/candidates/*"
                            element={<PrivateRoute component={Candidates} componentName="Candidates" />}
                        />
                        <Route
                            path="/statistics"
                            element={<PrivateRoute component={Statistics} componentName="Statistics" />}
                        />
                        <Route
                            path="/contactUs/*"
                            element={<PrivateRoute component={ContactUsForm} componentName="ContactUs" />}
                        />

                        <Route path="/edit-profile/:adminId" element={<PrivateRoute component={EditProfile} componentName='EditProfile' />} />
                        <Route path="/" element={<Navigate to={`/requests/${RequestTableTypes.Pending}`} replace />} />
                        <Route path="/*" element={<GenericNotFound />} />
                    </Routes>
                </div>
            </div>
        </SocketProvider>
    );
}

export default (
    <RowsPerPageProvider>
        <TableFiltersProvider>
            <TopBarProvider>
                <Home />
            </TopBarProvider>
        </TableFiltersProvider>
    </RowsPerPageProvider>
);

const useStyles = makeStyles(({
    bigContainer: {
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        overflow: "auto",
    },
    container: {
        marginTop: "3.8rem",
        background: backgroundGray,
        flexGrow: 1,

        display: "flex",
        flexDirection: "column"
        // overflow: "auto"
    },
    hi: {
        display: 'flex',
        flexDirection: 'column'
    }
}));