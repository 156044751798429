import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, Svg, useForm } from "@hilma/forms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";

import {
  iconLogoPath,
  schoolSignUpAdminInfoRules,
  schoolSignUpRules,
} from "../../constants";
import { SchoolSignUpScreens } from "../../types";
import { HilmaLogoSvg } from "../../svg";

import SchoolSignUpSuccess from "./SchoolSignUpSuccess";
import ManangerInfoForm from "./ManangerInfoForm";
import SchoolInfoForm from "./SchoolInfoForm";
import { Background } from "../../componenets";

const initialValues = {
  schoolName: "",
  image: "",
  imagePath: null,
  schoolSymbol: "",
  phone: "",
  sector: "",
  city: "",
  heAddress: "",
  schoolAdminName: "",
  schoolAdminPhone: "",
  schoolAdminEmail: "",
};

const SchoolSignUp: React.FC = () => {
  const [currentScreen, setCurrentScreen] = useState<SchoolSignUpScreens>(
    SchoolSignUpScreens.SchoolInfo
  );

  const classes = useStyles();
  const navigate = useNavigate();

  const CurrentComponent: React.FC = () => {
    switch (currentScreen) {
      case SchoolSignUpScreens.SchoolInfo:
        return <SchoolInfoForm setCurrentScreen={setCurrentScreen} />;
      case SchoolSignUpScreens.ManagerInfo:
        return <ManangerInfoForm setCurrentScreen={setCurrentScreen} />;
      case SchoolSignUpScreens.Success:
        return <SchoolSignUpSuccess />;
    }
  };

  const currentRules = useMemo(() => {
    switch (currentScreen) {
      case SchoolSignUpScreens.SchoolInfo:
        return schoolSignUpRules;
      case SchoolSignUpScreens.ManagerInfo:
        return schoolSignUpAdminInfoRules;
      case SchoolSignUpScreens.Success:
        return {};
    }
  }, [currentScreen]);

  return (
    <FormProvider initialValues={initialValues} rules={currentRules}>
      <Box className={classes.container}>
        <div className={classes.heading}>
          <img
            onClick={() => navigate("/")}
            width="60px"
            className={classes.logo}
            alt="logo"
            src={iconLogoPath}
          />
          {/* <Svg color='' className={classes.hilmaSvg} svg={HilmaLogoSvg} /> */}
        </div>

        <div className={classes.title}>
          <h1 className={classes.mainTitle}>מורה להחלפה</h1>
          <h3 className={classes.notBold}>
            המורה המתאים ביותר בזמן הקצר ביותר
          </h3>
          <h3>הרשמה כבית ספר</h3>
        </div>

        <CurrentComponent />
      </Box>

      <Background />
    </FormProvider>
  );
};

export default SchoolSignUp;

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "20px 20px 40px",
  },
  logo: {
    alignSelf: "center",
  },
  hilmaSvg: {
    width: 60,
    height: "unset",
  },
  heading: {
    display: "inline-flex",
    width: "100%",
    justifyContent: "flex-start",
    gap: 15,
  },
  title: {
    color: theme.palette.primary.main,
    lineHeight: "1rem",
  },
  mainTitle: {
    fontFamily: "Rubik-Black",
  },
  notBold: {
    fontFamily: "Assistant-Light",
  },
}));
