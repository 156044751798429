export const errors = {
	generalError: "אירעה שגיאה",
	phoneExists: "מספר הטלפון כבר קיים במערכת",
	emailExists: "מייל זה קיים כבר במערכת",
	getDataError: 'שגיאה בהבאת מידע',
	addSchoolError: 'שגיאה בהוספת בית ספר',
	deleteSchoolError: 'שגיאה במחיקת בית ספר',
	deleteSchoolSuccess: 'בית הספר נמחק בהצלחה',
	addAdminError: 'שגיאה בהוספת מנהל',
	deleteAdminError: 'שגיאה במחיקת מנהל',
	deleteAdminSuccess: 'מנהל נמחק בהצלחה',
	addRequestError: 'שגיאה בהוספת קריאה',
	updateDetailsError: 'שגיאה בעדכון פרטים',
	formError: "אחד או יותר מן הפרטים שהזנת אינם נכונים",
	clickFileError: "יש ללחוץ על הטפסים המצורפים על מנת להמשיך הלאה",
	downloadFile: 'לא ניתן להוריד את הקובץ',
	updateDetailsSuccess: 'פרטים עודכנו בהצלחה',
	addDetailsSuccess: 'פרטים נוספו בהצלחה',
	candidateSaveSuccess: "המחליף נשמר בהצלחה",
	assignCandidateError: 'שגיאה בשיבוץ מורה',
	deleteRequestError: "שגיאה במחיקת קריאה",

	googleMapsStation: {
		israelLoc: "הכתובת צריכה להיות בישראל",
		locationNotFound: "הכתובת לא נמצא",
		userExists4station: 'כבר קיימת תחנת חלוקה עבור משתמש זה',
	}
}