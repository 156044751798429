import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { downloadServerFile, FormFile, FormProvider, useAlert, useForm } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PublishIcon from '@material-ui/icons/Publish';
import Button from '@material-ui/core/Button';
import { provide } from '@hilma/tools';
import axios from 'axios';
import clsx from 'clsx';

import { SchoolTable, SchoolTableTypes, TableColumn } from '../../types';
import { teal, uploadSchoolInfoRules } from '../../constants';
import { getAlertError } from '../../functions';
import { ConfirmPopup } from '../../general';
import { Table } from '../../componenets';

import { useStyles } from '../../componenets/Table/headerButtons/AddButton';

interface SchoolTablesProps {
	type: SchoolTableTypes;
}

const initialValues = {
	schoolInfo: null,
	schoolInfoPath: ''
}

const SchoolTables: React.FC<SchoolTablesProps> = ({ type }) => {

	const [dataUrl, setdataUrl] = useState('/api/school/filtered-schools')
	const [counter, setCounter] = useState(0)
	const [loading, setLoading] = useState(false);
	const [openExcelUpload, setOpenExcelUpload] = useState(false);

	const lClasses = useLocalStyles();
	const navigate = useNavigate();
	const classes = useStyles();
	const form = useForm();
	const alert = useAlert();

	const columns: TableColumn<SchoolTable>[] = [
		{ field: 'imagePath', title: '', type: 'image' },
		{ field: 'name', title: 'שם בית הספר', type: 'boldSearch' },
		{ field: 'adminName', title: 'איש קשר', type: 'boldSearch' },
		{ field: 'adminPhone', title: 'טלפון', type: 'boldSearch' },
		{ field: 'adminEmail', title: 'מייל', type: 'boldSearch' },
		{ field: 'settlement', title: 'יישוב', type: 'boldSearch' }
	];

	const onSubmit = async () => {
		try {

			setLoading(true);

			form.handleSubmit();
			if (!form.isValid || !Object.values(form.touched).length && !form?.values?.schoolInfoPath) return;

			await axios.post('/api/school/upload-schools', {
				files: true,
				...form.values,
			});

			// setOpenUploadModal(false);
			alert('השינויים התעדכנו בהצלחה', 'success');
			setOpenExcelUpload(false);
		} catch (error: any) {

			alert(getAlertError(error));
		} finally {
			setLoading(false);
		}
	};

	const downloadExcel = async () => {
		try {
			await downloadServerFile('/file/school-excel.xlsx', 'דוגמה.xlsx', undefined, undefined, process.env.NODE_ENV === 'production');
		} catch (error) {
			console.error(error);
			alert('שגיאה בהורדת הקובץ');
		}

	}

	const onClose = () => {
		form.resetForm();
		setOpenExcelUpload(false);
	}

	const onAction = async (row: any, action: string) => {
		try {
			if (action === 'delete') {
				await axios.delete(`/api/school/delete/${row.id}`);
				setCounter(counter + 1)
			}
		} catch (error: any) {
			alert(error.data.message)
		}
	}

	return (
		<>
			<Table
				additionalHeaderComponents={[
					<Button
						variant="outlined"
						color="primary"
						className={clsx(classes.addButton, lClasses.addExcel)}
						startIcon={<PublishIcon />}
						onClick={() => setOpenExcelUpload(true)}
						children='העלאת בתי ספר מאקסל'
					/>
				]}
				addButtonOnClick={type === SchoolTableTypes.Approved ? () => navigate('/schools/add') : undefined}
				tableTitle={type === SchoolTableTypes.Approved ? 'בתי ספר' : 'בתי ספר ממתינים לאישור'}
				arrowOnClick={(row) => navigate(`/schools/${type}/${row.id}`)}
				showCount={type === SchoolTableTypes.Pending}
				to={(row) => `/schools/${type}/${row.id}`}
				dataUrl={`${dataUrl}?c=${counter}`}
				addButtonLabel='הוספת בית ספר'
				actionButtons={['delete']}
				onAction={onAction}
				requestData={{ type }}
				columns={columns}
				wholeRowClick
				search
				deleteButtonProps={{
					title: 'מחיקת בית ספר',
					text: <div>האם אתה בטוח שברצונך למחוק את בית הספר<strong> לצמיתות</strong>?</div>,
					confirmText: 'מחיקה',
					secondConfirmText: 'לבטל',
				}}
			/>
			<ConfirmPopup
				open={openExcelUpload}
				onConfirm={onSubmit}
				onClose={onClose}
				// classname={lClasses.modal}
				title =  'מחיקת מנהל'
				// text={<>על <span className={lClasses.link} onClick={downloadExcel}>האקסל</span> לכלול את העמודות הבאות: שם בית הספר, שלב חינוך,	סמל מוסד, מגזר, טלפון, שם מנהל\ת, כתובת בית הספר, סלולרי מנהל/ת אי-מייל מנהל/ת רשות</>}
				text = {<div>האם אתה בטוח שברצונך למחוק את המנהל<strong> לצמיתות</strong>?</div>}
				children={<FormFile helperText='על הקובץ להיות קובץ מסוג xlsx או xls' id='schoolInfo' description='אקסל בתי ספר' />}
			/>
		</>
	)
};

const SchoolTablesComp: React.FC<SchoolTablesProps> = (props) => (
	<FormProvider initialValues={initialValues} rules={uploadSchoolInfoRules}>
		<SchoolTables {...props} />
	</FormProvider>
);
export default SchoolTablesComp;

const useLocalStyles = makeStyles({
	modal: {
		minWidth: 350,
		minHeight: 400,
		justifyContent: 'space-between',
		display: 'flex',
		flexDirection: 'column',
		'&>div:nth-of-type(2)': {
			fontSize: '1.1rem'
		}
	},
	addExcel: {
		width: 'fit-content',
		color: teal
	},
	link: {
		textDecoration: 'underline',
		cursor: 'pointer'
	}
})