import { useLocation, useNavigate, useParams } from "react-router-dom";

import { TitleAndBackArrow } from "../../componenets";
import { RequestTableTypes } from "../../types";
import { RequestForSubstitute } from "..";

import RequestCandidatesTable from "./RequestCandidatesTable";

interface RequestProps {
  type?: RequestTableTypes;
}

const Request: React.FC<RequestProps> = ({ type }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { requestId } = useParams<{ requestId: string }>();

  return (
    <>
      <TitleAndBackArrow title="מורה להחלפה" onClick={() => navigate((location.state as { from: string })?.from ?? `/requests/${type || RequestTableTypes.Pending}`)} />
      <RequestForSubstitute type={type} />
      {type && type !== RequestTableTypes.Archive && <RequestCandidatesTable type={type} requestId={requestId} />}
    </>
  );
};

export default Request;