//mui
import TableCell from '@material-ui/core/TableCell';
import withStyles from '@material-ui/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
//styles
import { error } from '@hilma/forms';
import clsx from 'clsx';
//components
import { StyledTableCell } from './StyledTableCell';
//ts
import { TableRow } from '../../../types';
interface RedCellProps<T extends TableRow = TableRow> {
    isRed: boolean;
    rowKey: keyof T;
    row: T;
    index: number;
    checkbox?: boolean;
    onClick?(): void;
}

export function RedCell<T>({ isRed, rowKey, row, index, checkbox, onClick }: RedCellProps<any>) {
    const classes = useStyles()
    if (isRed) {
        return <StyledRedTableCell onClick={onClick} key={index} className={clsx(!checkbox && index === 0 ? classes.firstCell : {})}>{row[rowKey]}</StyledRedTableCell>
    }
    return <StyledTableCell onClick={onClick} key={index} className={clsx(!checkbox && index === 0 ? classes.firstCell : {})}>{row[rowKey]}</StyledTableCell>

}

//styling
const StyledRedTableCell = withStyles(() => ({
    root: {
        padding: "0px 16px",
        fontFamily: 'Assistant-Regular',
        color: error,
    }
}))(TableCell);

const useStyles = makeStyles({
    firstCell: {
        paddingLeft: '3vw !important'
    },
});