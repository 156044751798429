import { AdminTableTypes, CandidateTableTypes, SchoolTableTypes, RequestTableTypes, TabsDictionary, TabTuple } from '../types';
import { SOCKET_ADMIN_CANDIDATES, SOCKET_ADMIN_REQUESTS } from './socketEvents';
import { forestGreen, skyBlue, yellow } from './colors';

export const tabsDictionary: TabTuple[] = [
	['בתי ספר', 'schools'],
	['מנהלים', 'admins'],
	['סייעות', 'candidates'],
	['בקשות', 'requests']
];

export const headerTabs: Record<string, { title: string, hideSubBar?: boolean, hideSchoolAdmin?: boolean }> = {
	'requests': { title: 'מורה להחלפה' },
	'schools': { title: 'בתי ספר', hideSubBar: false, hideSchoolAdmin: true },
	'admins': { title: 'מנהלים', hideSubBar: true, hideSchoolAdmin: true },
	'candidates': { title: 'מחליפים', hideSchoolAdmin: true },
	'statistics': { title: 'סטטיסטיקות', hideSchoolAdmin: true },
	'contactUs': { title: 'צור קשר' }
}

export const headerTabsArray = (hideSchoolAdmin?: boolean): TabTuple[] => Object.keys(headerTabs).filter(key => !(hideSchoolAdmin && headerTabs[key].hideSchoolAdmin)).map(key => [headerTabs[key].title, key]);

export const subTabsDictionary: TabsDictionary<keyof typeof headerTabs> = {
	'admins': [
		['מנהלים', AdminTableTypes.Approved],
	],
	'schools': [
		['בתי ספר מאושרים', SchoolTableTypes.Approved],
		['ממתינים לאישור', SchoolTableTypes.Pending],// uncomment for school status
		['לא אושרו', SchoolTableTypes.Denied],// uncomment for school status
	],
	'candidates': [
		['מאגר מחליפים', CandidateTableTypes.Approved],
		['ממתינים לאישור', CandidateTableTypes.Pending, { url: `/api/candidate/all?type=${CandidateTableTypes.Pending}&shouldGetCount=true`, color: 'white', background: skyBlue, socketEvent: SOCKET_ADMIN_CANDIDATES }],
		['לא אושרו', CandidateTableTypes.Denied],
	],
	'requests': [
		['קריאות פתוחות', RequestTableTypes.Pending, { url: `/api/request/filtered-requests?type=${RequestTableTypes.Pending}&shouldGetCount=true`, color: forestGreen, background: yellow, socketEvent: SOCKET_ADMIN_REQUESTS }],
		['קריאות סגורות', RequestTableTypes.Closed],
		['ארכיון קריאות', RequestTableTypes.Archive],
	],
};