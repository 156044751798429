import makeStyles from '@material-ui/core/styles/makeStyles';
import { AdminTableTypes } from '../types';
import Admin from './admins/Admin';

const EditProfile: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Admin isProfileEdit type={AdminTableTypes.Approved} />
        </div>
    );
};

export default EditProfile;

const useStyles = makeStyles(({
    container: {
        margin: "1rem min(2.5rem , 5vw)",
        flexGrow: 1,
        marginTop: 0,

        display: "flex",
        flexDirection: "column"
    }
}));