

import { DateSelect, SelectDropdown } from '../../types';

export const dateSelect: SelectDropdown = {
	selectName: 'date',
	minWidth: 170,
	dateRange: true,
	defaultValue: DateSelect.All,
	selectMenu: [
		{ value: DateSelect.All, label: 'כל התאריכים' },
		{ value: DateSelect.Today, label: 'היום' },
		{ value: DateSelect.LastSeven, label: 'ב-7 הימים האחרונים' },
		{ value: DateSelect.LastThirty, label: 'ב-30 הימים האחרונים' },
		{ value: DateSelect.LastYear, label: 'בשנה האחרונה' },
	]
};