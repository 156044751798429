import { createRules } from "@hilma/forms";

export const resetPasswordPassRules = createRules({
	password: {
		name: "סיסמה",
		required: true,
		regex: /^(?=.*[a-z])(?=.*\d)[a-zA-Z\d]{8,}$/,
		activeRegex: /[a-zA-Z\d]*/,
		activeRegexErr: "אנא הכנס סיסמה תקינה המכילה 8 תויים באנגלית ומספרים"
	},
});