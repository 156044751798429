import { makeStyles } from "@material-ui/styles";
import { useNavigate } from "react-router";
import { teal } from "../constants";
import { Button } from "../general"

const GenericNotFound = () => {

    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.container} >
            <div id="text">
                <div className={classes.text}>אופס! העמוד המבוקש לא נמצא</div>
                <div id="home-btn-container">
                    <Button onClick={() => navigate('/requests')}>לעמוד הבקשות</Button>
                </div>
            </div>
        </div >
    )
}


export default GenericNotFound


const useStyles = makeStyles({
    container: {
        position: 'relative',
        top: '18vh'
    },
    text: {
        color: teal,
        fontSize: '3vw',
        paddingBottom: '7vh',
    }
});