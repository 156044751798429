import { useCallback, useEffect, useMemo, useState } from 'react';
import { dirStyleGenerator, FormHelperText, Option, OptionsRule, useDirection, useForm, useFormInputStyles, useFormRefs, useFormRules, useGetRuleName, useTextFieldError } from '@hilma/forms';
import clsx from 'clsx';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Select, { SelectProps } from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';
import StarIcon from '@material-ui/icons/Star';
import AddIcon from '@material-ui/icons/Add';

import { MultipleSelectOption } from '../types';
import { useFormStyles } from '../constants';
import { FormInputStyled } from '../general';

interface FormMultipleSelectProps<T extends MultipleSelectOption = MultipleSelectOption> extends SelectProps {
	id: string;
	description: string;
	inputText: string;
	options: T[];
	starId?: string;
	loading?: boolean;
}

export const FormMultipleSelectOld = <T extends MultipleSelectOption = MultipleSelectOption>({ id, dir, description, inputText, options, starId, loading, ...rest }: FormMultipleSelectProps<T>): React.ReactElement => {
	const [containerTarget, setContainerTarget] = useState<HTMLDivElement | null>(null);
	const [selectedIds, setSelectedIds] = useState<string[]>([]);

	const direction = useDirection();

	const classes = useStyles({ dir: dir || direction });
	const formInputClasses = useFormInputStyles();
	const formClasses = useFormStyles();

	const fieldError = useTextFieldError(id);
	const getRuleName = useGetRuleName();
	const formRules = useFormRules();
	const innerRef = useFormRefs();
	const form = useForm();

	const ruleName = useMemo(() => {
		return getRuleName(formRules[id] as OptionsRule);
	}, [formRules, getRuleName, id]);

	const option = useMemo(() => form.values[id] || [], [form.values[id]]);

	const onChange = useCallback((event: React.ChangeEvent<any>) => {

		const idsIncludingDoubles = event.target.value?.map((val: string | Option) => (typeof val === 'string' || typeof val === 'number') ? val : val.id);

		const idsOfSelected: string[] = [...new Set(idsIncludingDoubles)] as string[]//idsIncludingDoubles.filter((x: string) => idsIncludingDoubles.indexOf(x) === idsIncludingDoubles.lastIndexOf(x));

		setSelectedIds(idsOfSelected);

		const selectedOptions = idsOfSelected?.map(id => ({ id, value: options.find(option => option.id === id)?.value }))

		if (starId && selectedOptions.length === 1)
			form.setFieldValue(starId, selectedOptions[0].id);

		form.setFieldValue(id, selectedOptions)

	}, [id, options, form]);

	const onRemove = useCallback(async (value: number | string) => {

		const newSelectedIds = selectedIds.filter(id => id !== value);
		setSelectedIds(newSelectedIds);

		const selectedOptions = newSelectedIds?.map(id => ({ id, value: options.find(option => option.id === id)?.value }))
		await form.setFieldTouched(id)
		form.setFieldValue(id, selectedOptions);

	}, [selectedIds, form, id, options]);

	const changeStar = (value: number | string) => {
		starId && form.setFieldValue(starId, value);
	}

	useEffect(() => {
		const extractedIds = form.values[id]?.map((selected: Option) => selected.id);
		setSelectedIds(extractedIds);
	}, [form.values[id]])

	if (loading) return <FormInputStyled id={id} description={description} loading={true} />

	return (
		<div ref={setContainerTarget}>
			<div className={classes.cccc}>
				<div className={formInputClasses.containLables}>
					<InputLabel htmlFor={id} className={clsx(formInputClasses.label, formClasses.label)}>{description}</InputLabel>
				</div>
				<Select
					id={id}
					disableUnderline
					dir={dir || direction}
					className={classes.inputRoot}
					classes={{
						root: clsx(formInputClasses.input, classes.root, fieldError && formClasses.inputError),
						select: classes.select,
					}}
					inputRef={(el) => { if (innerRef) innerRef.current[id] = el }}
					value={option.length ? option : []}
					onChange={onChange}
					IconComponent={(props) => <ArrowDropDownIcon color="primary" className={classes.icon} />}
					renderValue={() => <div className={classes.inputText}><AddIcon className={classes.addIcon} />{inputText}</div>}
					displayEmpty
					multiple
					MenuProps={{
						container: containerTarget,
						classes: {
							list: classes.list
						}
					}}
					{...rest}
				>
					<MenuItem disabled value={-1} className={classes.menuItem}>
						{"בחר"} {ruleName}
					</MenuItem>
					{options?.map((option, index) => {
						const selected = selectedIds?.includes(option.id as string);
						if (selected) return;
						return (
							<MenuItem key={index} disabled={selected} value={option.id} className={clsx(classes.menuItem, selected && classes.selectedListItem)}>
								{option.value}
							</MenuItem>
						)
					})}
				</Select>
				<FormHelperText className={formClasses.error} text={fieldError} error />
			</div>
			{selectedIds?.map((id, index) => {
				const option = options && options.find(option => `${option?.id}` === `${id}`);
				const isStar = starId && (id === form.values[starId]);
				const canDelete = !isStar && !option?.hideDelete;
				const value = option?.value;
				return (
					<div key={index} className={classes.selectedOptionContainer}>
						<FormInputStyled
							id=''
							description=''
							onChange={() => { }}
							value={value || ' '}//keeps it controlled
							style={{ pointerEvents: 'none', marginTop: -15, width: 350 }}
							endAdornment={canDelete && <IconButton
								color="primary"
								onClick={() => onRemove(id)}
								className={classes.disableRipple}
								children={<AddCircleIcon className={classes.removeIcon} />} />}
						/>
						{starId && <IconButton onClick={() => changeStar(id)} color="primary" children={<Tooltip title={'מנהל ראשי'}>{isStar ? <StarIcon /> : <StarBorderIcon />}</Tooltip>} />}
					</div>
				)
			})}
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
	},

	inputRoot: {
		width: 'min(350px, 100%)',
	},

	root: {
		...dirStyleGenerator('padding', '24px !important'),
		backgroundColor: "white",
		border: "0.5px solid #3E504C",
		borderRadius: "3px",
		color: theme.palette.primary.main,
		display: "flex",
		'&:focus': {
			borderRadius: "3px !important",
		},
	},

	cccc: {
		[theme.breakpoints.up('sm')]: {
			display: "flex",
		},
		alignItems: "center",
		marginTop: "25px",
		position: "relative",
		fontSize: "1rem",
	},

	select: {
		alignItems: 'center',

		[theme.breakpoints.up(500)]: {
			padding: '4px 7px',
			fontSize: 17,
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 7px',
			fontSize: 22,
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 10px',
			fontSize: 23,
		},

		'&:focus': {
			borderRadius: '5px !important',
		}
	},

	icon: {
		position: 'absolute',

		[theme.breakpoints.up(500)]: {
			...dirStyleGenerator('', 4),
			width: 30,
			height: 30
		},
		[theme.breakpoints.down(500)]: {
			...dirStyleGenerator('', 4),
			width: 30,
			height: 30
		},
		[theme.breakpoints.up(1600)]: {
			...dirStyleGenerator('', 10),
			width: 40,
			height: 40
		},

		marginTop: 5
	},

	list: {
		[theme.breakpoints.up(500)]: {
			padding: '4px 0',
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 0',
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 0',
		}
	},

	menuItem: {
		fontFamily: 'Assistant-Regular',
		color: theme.palette.primary.main,
		// background: '##FFFFFF',

		[theme.breakpoints.up(500)]: {
			padding: '4px 15px',
			fontSize: 17,
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 15px',
			fontSize: 22,
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 15px',
			fontSize: 23,
		}
	},

	selectedListItem: {
		background: '#d8d8d8'
	},

	removeIcon: {
		transform: 'rotate(45deg)'
	},

	disableRipple: {
		pointerEvents: 'auto',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},

	inputText: {
		fontFamily: 'Assistant-Regular',
		display: 'flex',
		alignItems: 'center'
	},

	addIcon: {
		transform: 'scale(0.6)'
	},
	selectedOptionContainer: {
		display: 'flex'
	}
}));