import makeStyles from "@material-ui/styles/makeStyles";

import { iconLogoPath } from "../../constants";
import { Link, useLocation } from 'react-router-dom';

import UserDetails from "./UserDetails";


const TopBarMobile: React.FC = () => {

	const classes = useStyles();
	const location = useLocation();

	const isOnRequestsPage = location.pathname.includes('/requests');
	const isOnSchoolsPage = location.pathname.includes('/schools');
  
	  const renderLogoLink = () => {
	  if (isOnRequestsPage) {
		return <Link to="/requests"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
	  } else if (isOnSchoolsPage) {
		return <Link to="/schools"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
	  } else {
		// Default behavior, navigate to home
		return <Link to="/"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
	  }
	};

	return (
		<div className={classes.container}>

 		{renderLogoLink()}
			<UserDetails />

		</div>
	);
}

export default TopBarMobile;

const useStyles = makeStyles({
	container: {
		width: '100vw',
		height: '3.8rem',
		background: '#f2f2f2',
		boxShadow: `0px 3px 6px #00000029`,
		position: 'fixed',
		top: 0,
		zIndex: 10,
		padding: '0.5% 20px',
		boxSizing: 'border-box',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	}
})