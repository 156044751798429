import { useState } from "react";
import { Option } from "@hilma/forms";
import { useAsyncEffect } from "@hilma/tools";

import { createOptionsList } from "../functions";
import { Enum } from "../types";

function useGetOptions<E extends Enum<E>>(enm: E, obj: { [key in E as string]: string | number }, fieldId: string) {

	const [options, setOptions] = useState<Option[]>([]);

	useAsyncEffect(async () => {

		const options = createOptionsList(enm, obj);

		setOptions(options);

	}, []);

	return options;
}

export default useGetOptions;