import { createRules } from "@hilma/forms";

export const resetPasswordEmailRules = createRules({
  email: {
    name: "מייל",
    required: true,
    regex:
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  },
});
