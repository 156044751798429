import makeStyles from '@material-ui/core/styles/makeStyles';
import { Svg } from '@hilma/forms';
import clsx from 'clsx';

import { useTableIcon, useTableIconExpression, useTableRedExpression, useTableSearchValue, useTableHasCheckbox, useTableOvalConfiguration, useTableColumnTitles } from '../../../hooks';
import { TableRow, ColumnTypeConfig, TableColumnKey } from '../../../types';
import { MessageSvg } from '../../../svg';

import { StyledTableCell } from './StyledTableCell';
import { BoldSearchCell } from './BoldSearchCell';
import { StarRating } from './StarRating';
import { ImageCell } from './ImageCell';
import { IconCell } from './IconCell';
import { OvalCell } from './OvalCell';
import { RedCell } from './RedCell';
import { isNull } from 'lodash';
import { MdDelete } from 'react-icons/md';
import DeleteCell from './DeleteCell';

interface AllCellsProps<T extends TableRow = TableRow> {
    row: T;
    keyField: TableColumnKey<T>;
    config: ColumnTypeConfig<T>;
    onClick?(): void;
    index: number;
    addTitle?: boolean;
}


export const AllCells = <T extends TableRow = TableRow>({onClick, keyField, row, index, config, addTitle }: AllCellsProps<T>): React.ReactElement => {

    const columnTitles = useTableColumnTitles();

    const iconExpression = useTableIconExpression();
    const ovalConfig = useTableOvalConfiguration();
    const redExpression = useTableRedExpression();
    const searchValue = useTableSearchValue();
    const checkbox = useTableHasCheckbox();
    const icon = useTableIcon();

    const classes = useStyles();

    const { imageField, iconField, ovalField, redField, boldSearchField, componentField } = config;
    if (componentField && keyField && componentField?.includes(keyField as keyof T))
        return <StarRating onClick={onClick} numStars={row[keyField as keyof T] as number} index={index} />
    else if (iconField && keyField && iconField?.includes(keyField as keyof T) && keyField === 'ratingNote')
        return row[keyField as keyof T] ? <StyledTableCell onClick={onClick} key={index}> <Svg color='' svg={MessageSvg} /> </StyledTableCell> : <StyledTableCell onClick={onClick} key={index}></StyledTableCell>
    else if (boldSearchField && keyField && boldSearchField?.includes(keyField as keyof T) && (typeof row[keyField as keyof T] === 'string' || typeof row[keyField as keyof T] === 'number'))
        return <BoldSearchCell title={addTitle ? columnTitles[index] : ''} onClick={onClick} index={index} text={row[keyField as keyof T] as unknown as string} searchText={searchValue} />
    else if (iconField && iconExpression && icon && iconField?.includes(keyField as keyof T) && keyField && (typeof row[keyField as keyof T] !== 'undefined') && iconExpression)
        return <IconCell onClick={onClick} hasIcon={iconExpression(row[keyField as keyof T])} index={index} icon={icon} />;
    else if (imageField && imageField?.includes(keyField as keyof T) && keyField)
        return <ImageCell onClick={onClick} src={row[keyField as keyof T] as unknown as string} index={index} checkbox={checkbox} />;
    else if (ovalField && ovalConfig && ovalField?.includes(keyField as keyof T) && keyField && row[keyField as keyof T])
        return <OvalCell onClick={onClick} ovalConfig={ovalConfig || {}} status={row[keyField as keyof T] as unknown as string} index={index} />
    else if (redField && redExpression && redField?.includes(keyField as keyof T) && keyField && row[keyField as keyof T] && redExpression)
        return <RedCell onClick={onClick} isRed={redExpression(row[keyField as keyof T])} rowKey={keyField as keyof T} row={row} index={index} checkbox={checkbox} />
    else if (addTitle && typeof keyField === 'string' && isNull(row[keyField])) return <></>
    else
        return <StyledTableCell onClick={onClick} key={index} className={clsx({}, !checkbox && index === 0 && classes.firstCell, addTitle && classes.rowWithTtitle)}>{addTitle ? <span className={classes.title}>{columnTitles[index]} </span> : ''}{keyField && typeof keyField === 'string' && (['string', 'number'].includes(typeof row[keyField])) ? row[keyField] : typeof keyField === 'function' ? keyField(row, index) : ''}</StyledTableCell>
    

}

//styling
const useStyles = makeStyles({
    firstCell: {
        paddingLeft: '3vw !important',
    },
    title: {
        fontFamily: 'Assistant-Bold'
    },
    rowWithTtitle: {
        display: 'flex',
        flexDirection: 'row',
        gap: 2,
        '&:last-of-type': {
            margin: 'auto',
            padding: 9
        }
    }
});
