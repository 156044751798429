import React, { useState } from "react";
import {
  FormInput,
  GridifyChildren,
  FormImage,
  FormSelect,
  FormFile,
  useForm,
  useGoogleMaps,
  Option,
} from "@hilma/forms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";



import {
  candidateStatus,
  sectorsArray,
  daysOfWeekArray,
  candidateEducationsArray,
  useFormStyles,
  teal,
  gradesArray,
} from "../../constants";
import {
  CitiesAutocomplete,
  FormMultipleSelect,
  LocationAutocomplete,
} from "../../componenets";
import { BottomStyled, Chips, Button, FormCheckBoxStyled } from "../../general";
import axios from "axios";
import ConfirmPopup from "../../componenets/popup/ConfirmPopup";
import { useNavigate } from "react-router-dom";

interface CandidateDetailsProps {
  loading: boolean;
  noEdit?: boolean;
  subjects: Option[];
  handleSave(): any;
  userId: any;
}

const CandidateDetails: React.FC<CandidateDetailsProps> = ({
  loading,
  subjects,
  handleSave,
  noEdit,
  userId
}) => {
  const [deleteUserPopup, setDeleteUserPopup] = useState(false);
  const loadingMaps = useGoogleMaps();
  const formClasses = useFormStyles();
  const classes = useStyles();
  const form = useForm();
  const params = {
    candidateId: userId,
  };
  const navigate = useNavigate()
  const deleteCandidate = async () => {
    try {
      await axios.delete(`/api/candidate/delete-candidate-admin/${params.candidateId}`)
      setDeleteUserPopup(false)
      navigate("/candidates/approved")
      
    } catch(error) {
      console.log(error)
    }
    
  };
  return (
    <div>
      <div className={classes.form}>
        <Chips
          classes={{ container: noEdit ? classes.disabled : "" }}
          id="status"
          items={candidateStatus}
        />
        <br />
        <div className={clsx(noEdit && classes.disabled)}>
          <GridifyChildren>
            <FormImage id="image" size={2_000_000} />
            <div>
              <FormInput id="name" description={"שם מלא"} />
              <FormInput id="phone" description={"מספר טלפון"} />
              <FormInput id="email" description={"מייל"} />
              <FormInput id="gender" description={"מין"} />
              <FormInput id="hasExperience" description={"עבד/ה בעבר כמורה"} />
              <FormInput
                id="radius"
                type="number"
                description={"מרחק עבודה בק״מ"}
              />
            </div>
            <CitiesAutocomplete
              id="city"
              description="ישוב"
              loading={loading && !form.values.city}
            />
            <LocationAutocomplete
              description={"כתובת מלאה (עיר, רחוב, מספר בית)"}
              loading={loadingMaps}
              id="heAddress"
            />
            <FormMultipleSelect
              id="candidatePrefEducation"
              description="השכלה"
              options={candidateEducationsArray}
            />
            {/* <FormSelect id='education' description='השכלה' options={candidateEducationsArray} /> */}
            <FormMultipleSelect
              id="candidatePrefGrade"
              description="כיתות"
              options={gradesArray}
            />
            <FormMultipleSelect
              id="candidatePrefSector"
              description="מגזרים"
              options={sectorsArray}
            />
            <FormMultipleSelect
              id="candidateWorkDay"
              description="ימי עבודה"
              options={daysOfWeekArray}
            />
            <FormMultipleSelect
              id="candidatePrefSubject"
              description="מקצועות"
              options={subjects}
            />
          </GridifyChildren>
        </div>

        <div className={classes.label}>קבצים</div>
        <GridifyChildren>
          <FormFile
            id="cv"
            description="קורות חיים"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          <FormFile
            id="policeCheck"
            description="תעודת יושר מהמשטרה"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          <FormFile
            id="experience"
            description="טופס קליטה"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          <FormFile
            id="diploma"
            description="תעודות"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
          <FormFile
            id="recommendation"
            description="המלצות"
            noAxiosOnFileDownload={process.env.NODE_ENV === "production"}
          />
        </GridifyChildren>
        <div className={clsx(noEdit && classes.disabled)}>
          <FormCheckBoxStyled
            id="cvRead"
            description={"קראתי ועברתי על קורות החיים"}
          />
          <FormCheckBoxStyled
            id="policeCheckRead"
            description={"קראתי ועברתי על תעודת היושר מהמשטרה"}
          />
        </div>
      </div>

      {!noEdit && (
        <div style={{ display: "flex", justifyContent:"space-between" }}>
          <Button style={{backgroundColor:"red"}} loading={loading} onClick={() => setDeleteUserPopup(true)} children={"מחיקת משתמש"} />
          <Button loading={loading} onClick={handleSave} children={"שמירה"} />
        </div>
      )}
      <ConfirmPopup
        open={deleteUserPopup}
        text="האם את\ה בטוח שברצונך למחוק את המשתמש?"
        title="מחיקת משתמש"
        onClose={() => setDeleteUserPopup(false)}
        onConfirm={deleteCandidate}
      />
    </div>
  );
};

export default CandidateDetails;

const useStyles = makeStyles({
  label: {
    fontFamily: "Assistant-bold",
    color: teal,
    fontSize: "1.2rem",
    marginTop: 20,
    marginBottom: 15,
    textAlign: "left",
  },
  form: {
    paddingTop: 20,
  },
  disabled: {
    pointerEvents: "none",
  },
});
