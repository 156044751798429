import { CandidateTableTypes } from "../types";

export const candidateTableRoutes: CandidateTableTypes[] = [
	CandidateTableTypes.Approved,
	CandidateTableTypes.Pending,
	CandidateTableTypes.Denied,
];

export const candidatePageRoutes: CandidateTableTypes[] = [
	CandidateTableTypes.Approved,
	CandidateTableTypes.Pending,
	CandidateTableTypes.Denied,
];