import { useState } from 'react';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import makeStyles from '@material-ui/core/styles/makeStyles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';

import { boxShadowGray, lightGray, tooltipGray, green } from '../constants';

interface InformationTooltipProps {
	text: string;
};

const InformationTooltip: React.FC<InformationTooltipProps> = ({ text }) => {

	const [open, setOpen] = useState(false);

	const isMobile = useMediaQuery('(max-width: 800px)');
	const classes = useStyles();

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<Tooltip
				key={`${isMobile}`} //otherwise it doesn't rerender as isMobile and click doesn't work
				arrow
				open={isMobile ? open : undefined}
				classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
				title={<div className={classes.text}>{text}</div>}
			>
				<HelpOutlineIcon color="primary" fontSize="small" onClick={() => setOpen(prev => !prev)} className={classes.helpIcon} />
			</Tooltip>
		</ClickAwayListener >
	)
};

export default InformationTooltip;

const useStyles = makeStyles({
	text: {
		padding: 6,
		fontSize: '0.85rem',
		color: green,
		borderBottom: "none",
		fontFamily: 'Assistant-Regular',
		lineHeight: '1.2rem',
	},
	helpIcon: {
		verticalAlign: 'middle',
		color: tooltipGray,
	},
	tooltip: {
		backgroundColor: lightGray,
		wordBreak: 'break-word',
		boxShadow: `0px 3px 6px ${boxShadowGray}`,
		borderRadius: '2vh',
		marginTop: '2vh',
	},
	arrow: {
		"&::before": {
			backgroundColor: lightGray,
		}
	},
});