import { useEffect, useState } from 'react';
import InputAdornment from "@material-ui/core/InputAdornment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from '@material-ui/core/Typography';
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";


import FilterListIcon from '@material-ui/icons/FilterList';
import clsx from "clsx";

import { useSetTableSearchValue, useTableCheckedCells, useTableHasSearch, useTableSearchValue, useTableNumberOfRows, useTableShowCount, useTableHideTitle, useTableRows, useTableCheckboxButtons, useTableCheckedRowsData, useTableAdditionalHeaderComponents, useTableSelects } from "../../hooks";
import { useSuccessfulSubstituteStyles } from "../../general";
import { backgroundGray, teal } from "../../constants";

import { AddButton } from "./headerButtons";
import TableSelects from "./TableSelects";


interface HeaderProps {
  tableTitle?: string;
  requestId?: string;
}

const TableHeader: React.FC<HeaderProps> = ({ tableTitle, requestId }) => {

  const [drawerOpen, setDrawerOpen] = useState(false);

  const additionalHeaderComponents = useTableAdditionalHeaderComponents();
  const checkboxButtons = useTableCheckboxButtons();
  const checkedRowsData = useTableCheckedRowsData();
  const setSearchValue = useSetTableSearchValue();
  const numberOfRows = useTableNumberOfRows();
  const checkedCells = useTableCheckedCells();
  const searchValue = useTableSearchValue();
  const showCount = useTableShowCount();
  const hideTitle = useTableHideTitle();
  const search = useTableHasSearch();
  const selects = useTableSelects();
  const tableRows = useTableRows();

  const classes = useStyles();
  const classesPopup = useSuccessfulSubstituteStyles();

  const isMobile = useMediaQuery('(max-width: 800px)');

  useEffect(() => {
    setSearchValue("")
  }, [])
  return (
    <div className={classes.tableHeader}>

      <span className={classes.searchAndNumberFoundContainer}>
        {!hideTitle && tableTitle && (
          <div className={clsx(classes.title, !search && classes.alignBottom)}>
            {tableTitle}
          </div>
        )}

        {isMobile && <AddButton />}

        {search && checkedCells.length === 0 && (
          <TextField
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="חיפוש"
            className={classes.searchContainer}
            inputProps={{ maxLength: 100 }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.root, input: classes.searchInput },
              color: "primary",
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" color="primary" />
                </InputAdornment>
              ),
            }}
          />
        )}
        
      </span>

      <span className={classes.buttonsAndSelect}>
        <div className={classes.addButtonAndAdditionalHeaderComponents}>
          {additionalHeaderComponents}
          {!isMobile && <AddButton />}
        </div>

        {isMobile ?
          <>
            <ButtonBase className={classes.mobileFilter} onClick={() => setDrawerOpen(true)}>
              <FilterListIcon color="primary" />
            </ButtonBase>
            <Drawer anchor='left' classes={{ paper: classes.drawer, modal: classes.modalOfDrawer, root: classes.modalOfDrawer }} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
              <div className={classes.drawerHeader}>
                <Typography color="primary" className={classes.mobileFilterTitle}>סינון</Typography>
                <ButtonBase className={classes.closeButton} onClick={() => setDrawerOpen(false)}>
                  <CloseIcon color='primary' />
                </ButtonBase>
              </div>
              <TableSelects setDrawerOpen={setDrawerOpen} selects={selects} />
            </Drawer>
          </> :

          <TableSelects selects={selects} />
        }

        {showCount && (
          <div className={classes.tableRowNumber}>
            {checkedCells.length > 0
              ? `נבחרו ${checkedCells.length} ${tableTitle ? tableTitle : "תוצאות"
              }`
              : `נמצאו ${numberOfRows} ${tableTitle ? tableTitle : "תוצאות"}`}
          </div>
        )}
        {checkboxButtons?.length ? (
          checkedCells.length ?
            checkboxButtons.map(({ onClick, ...rest }) => (
              <Button color="primary" variant="outlined" onClick={() => onClick && onClick(checkedRowsData)} {...rest} />
            ))
            : <Button disabled children={<div className={classes.noButtons}>h</div>} />
        ) : null}
      </span>
    </div>
  );
};
export default TableHeader;
//styling
const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto",
  },
  searchInput: {
    width: "15vw",
    padding: "0",
    "&::placeholder": {
      opacity: 1,
    },
    color: teal,
    alignContent: "center",
    [theme.breakpoints.down(800)]: {
      width: 'auto',
      maxWidth: '35vw'
    }
  },
  searchAndNumberFoundContainer: {
    alignItems: "flex-start",
    flexDirection: "column",
    display: "flex",
    gap: 12,
  },
  searchContainer: {
    border: `1px solid ${teal}`,
    height: "1.8rem",
    borderRadius: "1rem",
    paddingLeft: 10,
  },
  tableHeader: {
    justifyContent: "space-between",
    alignItems: "flex-end",
    marginBottom: 16,
    display: "flex",
  },
  tableRowNumber: {
    fontFamily: "Assistant-Regular",
    color: teal,
  },
  title: {
    fontFamily: "Assistant-Bold",
    fontSize: "1.3rem",
    display: "flex",
    height: "2rem",
    color: teal,
    whiteSpace: 'nowrap'
  },
  mobileFilter: {
    background: '#B5E6ED',
    borderRadius: '50%',
    padding: '3px'
  },
  drawer: {
    background: backgroundGray,
    padding: '10px 17px'
  },
  modalOfDrawer: {
    backgroundColor: '#EAEAEA4D',
    backdropFilter: 'blur(4px)',
    '&>.MuiBackdrop-root': {
      backgroundColor: '#a3a3a34D',
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 12
  },
  mobileFilterTitle: {
    fontFamily: 'Assistant-Bold',
    fontSize: '1.4rem',
    margin: '0 auto'
  },
  closeButton: {
    borderRadius: '50%',
    marginRight: 6,
    position: 'absolute'
  },
  alignBottom: {
    alignItems: "flex-end",
  },
  buttonsAndSelect: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: 15,
  },
  noButtons: {
    color: 'transparent',
  },
  //dont delete yet
  tableCheckedButtons: {
    display: "flex",
    height: 40,
    alignItems: "flex-end",
    textAlign: "right",
    marginTop: 20,
  },
  addButtonAndAdditionalHeaderComponents: {
    display: 'flex',
    gap: 10,
  }
}));
