import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from 'clsx';
import useReusableStyles from "../hooks/useReusableStyles";

// import { useReusableStyles } from '../hooks';

interface AppTitleProps {
	showLogo?: boolean;
}

const AppTitle: React.FC<AppTitleProps> = ({ showLogo = false }) => {

	const classes = useStyles();
	const resetStyles = useReusableStyles();

	return (
		<div className={classes.container}>
			{/* {showLogo && <RegionLogo />} */}
			{showLogo &&
				<img className={classes.logo} src='/images/teacher-logo.png' alt='' />
			}
			<h1 className={clsx(resetStyles.reset, classes.title)}>מורה להחלפה</h1>
			<h2 className={resetStyles.reset}>המורה המתאים ביותר בזמן הקצר ביותר</h2>
		</div>
	);
}

export default AppTitle;

export const useStyles = makeStyles(theme => ({
	container: {
		width: '100%',
		marginTop: 30,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		'&>h2': {
			fontFamily: 'Assistant-Regular',
			fontSize: '1rem'
		}
	},
	title: {
		fontFamily: 'Rubik-Black',
		fontSize: '2.6em'
	},
	logo: {
		display: 'block',
		width: 'calc(8vw + 40px)',
		marginBottom: '10px',
		[theme.breakpoints.down(600)]: {
			width: 'calc(13vw + 50px)'
		}
	},
}));