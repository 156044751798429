import { SelectDropdown, RequestStatusFilter } from "../../types";

export const requestStatusSelect: SelectDropdown = {
	selectName: 'requestStatus',
	defaultValue: RequestStatusFilter.All,
	minWidth: 130,
	selectMenu: [
		{ value: RequestStatusFilter.All, label: 'כל הסטטוסים' },
		{ value: RequestStatusFilter.Assigned, label: 'עם שיבוץ' },
		{ value: RequestStatusFilter.Unassigned, label: 'ללא שיבוץ' },
		{ value: RequestStatusFilter.Deleted, label: 'נמחק' },
	]
};