import { activePhoneRegex, createRules, numbersRegex, Option } from "@hilma/forms";
import { isMobilePhone, isString, length } from "class-validator";

export const schoolSignUpRules = createRules({

	image: { required: false },
	schoolName: { required: true, greaterThan: 2, smallerThan: 50 },
	schoolSymbol: { required: true, activeRegex: numbersRegex, classValidator: (value: number) => value < 999999 && value > 0, length: 6, activeRegexErr: 'סמל המוסד חייב להיות מספר בעל עד 6 ספרות' },
	phone: { required: true, activeRegex: activePhoneRegex, classValidator: (value: string) => isMobilePhone(value, 'he-IL') && (new RegExp(/^\d+/)).test(value) && length(value, 9, 10) },
	sector: { required: true, classValidator: (sector: Option) => isString(sector?.value) },
	heAddress: {
		required: true, classValidator: (heAddress: { description: string } | string) => {
			if (typeof heAddress != 'string')
				heAddress = heAddress.description
			return !!heAddress;
		}
	},
	city: {
		required: true, classValidator: (name: { heCityName: string } | string) => {
			if (typeof name != 'string')
				name = name.heCityName
			return !!name;
		}
	},

	

})