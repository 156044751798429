import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import { darkYellow, forestGreen, teal, yellow } from '../../constants';
import { useNavigate } from 'react-router-dom';

const NoRequests: React.FC = () => {

	const navigate = useNavigate();
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.text}>לא נמצאו קריאות</div>
			<Button
				variant="contained"
				children="פתיחת קריאה"
				className={classes.button}
				onClick={() => navigate('/requests/add')}
			/>
		</div>
	)
};

export default NoRequests;

const useStyles = makeStyles(({
	container: {
		flexDirection: 'column',
		alignItems: 'center',
		display: 'flex',
		margin: 100,
		gap: 20,
	},
	text: {
		fontFamily: 'Assistant-Regular',
		fontSize: '2rem',
		color: teal,
	},
	button: {
		fontFamily: 'Assistant-Bold',
		backgroundColor: yellow,
		borderRadius: '1.2rem',
		color: forestGreen,
		fontSize: '1.3rem',
		height: '2.4rem',
		'&:hover': {
			backgroundColor: darkYellow,
		},
	}
}));