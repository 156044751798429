import { createRules, fullNameRegex, activePhoneRegex, activeEmailRegex } from "@hilma/forms"
import { isEmail, isMobilePhone, length } from 'class-validator';

export const adminRules = createRules({
    name: { required: true, regex: fullNameRegex, greaterThan: 2, smallerThan: 45 },
    adminPhone: { required: true, activeRegex: activePhoneRegex, classValidator: (value: string) => isMobilePhone(value, 'he-IL') && (new RegExp(/^\d+/)).test(value) && length(value, 10, 10) },
    email: { required: true, activeRegex: activeEmailRegex, classValidator: (value: string) => isEmail(value) },
    // school: { required: false, classValidator: (school: any) => school.id/* , classValidator: (schools) => Array.isArray(schools) && schools.length */ },
});

