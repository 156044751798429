import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { purple, red, teal, yellow } from '../../constants';
import { RequestCandidateStatus } from '../../types';
import { TableChip } from '../../componenets';

interface RequestStatusCellProps {
    status?: RequestCandidateStatus;
};

const RequestStatusCell: React.FC<RequestStatusCellProps> = ({ status }) => {

    const classes = useStyles();

    const component = useMemo(() => (() => {
        switch (status) {
            case RequestCandidateStatus.Pending:
                return (
                    <TableChip label='ממתין למענה' color={purple} />
                )
            case RequestCandidateStatus.CandidateApproved:
                return (
                    <TableChip label='מעוניין\ת' color={teal} />
                )
            case RequestCandidateStatus.CandidateDenied:
                return (
                    <TableChip label='לא זמין' color={red} />
                )
            case RequestCandidateStatus.CandidateDeniedFinal:
                return (
                    <TableChip label='לא זמין סופית' color={red} />
                )
            case RequestCandidateStatus.Unassigned:
                return (
                    <TableChip label='השיבוץ בוטל' color={red} />
                )
            case RequestCandidateStatus.AdminApproved:
                return (
                    <TableChip label='ממתין לאישור סופי' color={purple} />
                )
            case RequestCandidateStatus.CandidateApprovedFinal:
            case RequestCandidateStatus.AdminAssigned:
                return (
                    <TableChip label='משובץ' color={teal} />
                )

            default: <></>;
        }
    })() || null, [status]);

    return component;
};

export default RequestStatusCell;

const useStyles = makeStyles(({

}));