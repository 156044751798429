import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { darkGray, teal } from '../../constants';

interface CandidateDetailsTopBarProps {
    tab: number;
    setTab: React.Dispatch<React.SetStateAction<number>>;
    endComponent?: React.ReactElement;
}

const tabs = [{ id: 0, label: "פרטים אישיים" }, { id: 1, label: "ארכיון קריאות" }];

const CandidateDetailsTopBar: React.FC<CandidateDetailsTopBarProps> = ({ setTab, tab, endComponent }) => {
    const classes = useStyles()

    const changeTab = (newTab: number) => {
        setTab(newTab);
    }

    return (
        <>
            {endComponent && <div style={{ marginBottom: -40, display: 'flex', justifyContent: 'flex-end', marginLeft: 20 }}>{endComponent}</div>}
            <Tabs className={classes.root} TabIndicatorProps={{ style: { background: teal } }} value={tab} >
                {tabs.map((tab, index) => (
                    <Tab
                        key={index}
                        onClick={() => changeTab(index)}
                        className={classes.label}
                        classes={{ selected: classes.selected }}
                        label={<div className={classes.label}>{tab.label}</div>}
                    />
                ))}
            </Tabs>
        </>
    );
}

export default CandidateDetailsTopBar;

const useStyles = makeStyles((theme) => ({
    root: {
        height: 'fit-content',
        bottom: 0,
        fontWeight: 'bold',
        borderBottom: `1px solid ${darkGray}60`,
        marginBottom: 20
    },
    label: {
        color: theme.palette.primary.main,
        fontFamily: "Assistant-SemiBold",
        paddingBottom: 0,
        display: 'flex',
        gap: 10,
    },
    selected: {
        fontFamily: 'Assistant-Bold'
    },
}));