import React, { FC } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface StatisticsAutocompleteProps {
  options: { title: string, id: string }[]
  setValue: (val: string | undefined) => void

}

const StatisticsAutocomplete: FC<StatisticsAutocompleteProps> = ({ options, setValue }) => {
  return (
    <Autocomplete
      id="combo-box-demo"
      options={options}
      getOptionLabel={(option) => option.title}
      style={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="בחר ישוב" margin="none" style={{ margin: "20px 0 0 0 " }} />}
      onChange={(event, value) => setValue(value?.title)}
    />


  );
}
export default StatisticsAutocomplete
