import clsx from 'clsx';

import { ClassNameMap } from '@material-ui/styles/withStyles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { InputClassKey } from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { useFormInputStyles } from '@hilma/forms';
import { InformationTooltip } from '.';

interface FormInputLabelProps {
	id: string;
	description: string;
	tooltip?: string;
	labelAdornment?: React.ReactNode;
	maxLength?: number;
	maxLengthContent?: string;
	classes?: Partial<ClassNameMap<InputClassKey | 'labelContainer' | 'label' | 'maxLength'>>;
};

const FormInputLabel: React.FC<FormInputLabelProps> = ({ id, description, tooltip, labelAdornment, maxLength, maxLengthContent, classes: { labelContainer, label, maxLength: maxLengthClass } = {} }) => {

	const classes = useStyles();
	const formInputClasses = useFormInputStyles();

	return (
		<div className={clsx(formInputClasses.containLables, labelContainer)}>
			<div className={classes.labelAndTooltip}>
				<InputLabel htmlFor={id} className={clsx(formInputClasses.label, label)}>{description}</InputLabel>
				{tooltip && <InformationTooltip text={tooltip} />}
			</div>
			{maxLength ? <div className={maxLengthClass}>{maxLengthContent}</div> : null}
			{labelAdornment && labelAdornment}
		</div>
	)
};

export default FormInputLabel;

const useStyles = makeStyles(({
	labelAndTooltip: {
		display: 'flex',
		alignItems: 'center',
		gap: 4,
	},
}));