import { createI18nText } from "./createTextFunctions";

const errorText = createI18nText({
	he: {
		generalShort: 'אירעה שגיאה',
		generalSystem: 'הייתה תקלה במערכת',
		fileNameTooLong: 'אחד משמות הקבצים ארוך מדי',

		name: 'השם המלא אינו תקין',
		phone: 'מספר הטלפון אינו תקין',
		email: 'המייל אינו תקין',
		phoneWoOther: 'אנא הכנס מספר טלפון ללא רווחים או תווים אחרים',
		associationExists: 'כבר קיימת עמותה למשתמש זה',
		associationNameExists: 'שם העמותה תפוס',
		selectValue: 'יש לבחור סכום',
		numbersOnly: 'יש להזין ספרות בלבד',
		enterValue: 'יש להזין סכום',
		plsLogin: 'מספר טלפון זה כבר קיים במערכת, נא להתחבר',
		phoneExists: 'מספר טלפון זה כבר קיים במערכת',
		invalidInfo: 'אחד או יותר מהפרטים שהוזנו לא תקינים',
		tooHeavy: 'הקבצים שנבחרו גדולים מדי',
		hebrew: 'נא לכתוב בעברית בלבד',
		twoDrivers: 'יש להוסיף ולשמור לפחות שני נהגים',
		sucksaved: 'הפרטים נשמרו בהצלחה',
		suckCopied: 'הקישור הועתק בהצלחה',
		downloadFile: 'הייתה תקלה בהורדת הקובץ',
		suckDeleteAsso: 'העמותה נמחקה בהצלחה',
		search: 'חיפוש',
		choose: 'בחר',

		chooseTime: 'בחר זמן',
		form: {
			chooseDate: 'בחר תאריך',
			uploadFile: 'העלה קובץ',

			fileType: 'על הקובץ להיות קובץ מסוג',

			formImage: {
				drag: <>גרור תמונה לכאן<br />או</>,
				addImage: 'הוסף תמונה',
				type: 'על התמונה להיות קובץ מסוג',
			},

			choose: 'בחר',

			chooseTimeRange: 'בחר טווח שעות',

			chooseTime: 'בחר זמן',
		},
		greaterThan: '{?} צריך להכיל לפחות {?} תווים',
		smallerThan: '{?} יכול להכיל עד {?} תווים',
		length: '{?} צריך להכיל {?} תווים בדיוק',
		required: 'שדה זה הינו חובה',
		confirm: 'השדות אינם זהים',
		image: 'סוג הקובץ אינו תקין',
		dateBefore: 'התאריך שהוזן צריך להיות לפני {?}',
		dateAfter: 'התאריך שהוזן צריך להיות אחרי {?}',
		timeBefore: 'הזמן שהוזן צריך להיות לפני {?}',
		timeAfter: 'הזמן שהוזן צריך להיות אחרי {?}',
		fileNameLength: 'שם הקובץ ארוך מדי',
		regex: '{?} {?}'
	},

	en: {
		generalShort: 'An error occurred',
		generalSystem: 'An error occurred',
		fileNameTooLong: 'One of the files\' name is too long',
		name: 'The name is not valid',
		phone: 'The phone number is not valid',
		email: 'The email is not valid',
		phoneWoOther: 'Please enter a mobile number without any spaces or dashes',
		associationExists: 'An association already exists under this user',
		associationNameExists: 'The association name already exists',
		selectValue: 'Please select a sum',
		numbersOnly: 'Please enter only digets',
		enterValue: 'Please enter a value',
		plsLogin: 'The phone number already exists in the system, please log in',
		phoneExists: 'The phone number already exists in the system',
		invalidInfo: 'One or more of the details entered are invalid',
		tooHeavy: 'The files selected are too heavy',
		hebrew: 'Please use Hebrew text only',
		twoDrivers: 'Please add at least two drivers',
		sucksaved: 'The details were saved successfully',
		suckCopied: 'The link has been copied successfully',
		downloadFile: 'An error occurred while trying to download the file',
		suckDeleteAsso: 'The association was deleted successfully',
		search: 'Search',

		form: {
			chooseDate: 'Choose a date',
			uploadFile: 'Upload file',

			fileType: 'The file must be a file of type',

			formImage: {
				drag: <>Drag an image here<br />or</>,
				addImage: 'Add image',
				type: 'The image must be a file of type',
			},

			choose: 'Choose',

			chooseTimeRange: 'Choose a time span',

			chooseTime: 'Choose a time'
		},
		greaterThan: 'The {?} must be at least {?} characters long',
		smallerThan: 'The {?} only be up to {?} characters long',
		length: 'The {?} must be {?} characters long',
		required: 'This field is required',
		confirm: 'The fields do not match',
		image: 'Invalid file type',
		dateBefore: 'The entered date must be before {?}',
		dateAfter: 'The entered date must be after {?}',
		timeBefore: 'The entered time must be before {?}',
		timeAfter: 'The entered time must be after {?}',
		fileNameLength: 'The name of the file is too long',
		regex: '{?} {?}'
	}
});

export default errorText;