import TablePagination from '@material-ui/core/TablePagination';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Table from '@material-ui/core/Table';
import clsx from 'clsx';

import { useTableCustomNoRows, useTableCustomTableWidth, useTableHandleChangePage, useTableHandleChangeRowsPerPage, useTableIsSmallTable, useTableLoading, useTableNumberOfRows, useTablePage, useTableRowsPerPage } from '../../hooks';
import { TableRow, TableColumnKey, ColumnTypeConfig } from '../../types';
import { teal } from '../../constants';

import HeadTable from './HeadTable';
import BodyTable from './BodyTable';
import Loading from './Loading';
import {ReactElement} from "react";
import {DeleteButtonProps} from "../../types/interfaces/delete-button-props.interface";

export interface TableBodyMainProps<T extends TableRow = TableRow> {
    rows: Array<T>;
    columnKeys: Array<TableColumnKey<T>>;
    arrowOnClick?(row: {}, index: number): void;
    onAction(row: {}, action: string): void;
    actionButtons: string[];
    to?(row: {}, index: number): string;
    config: ColumnTypeConfig<T>;
    deleteButtonProps?: DeleteButtonProps;
}

const TableBodyMain = <T extends TableRow = TableRow>({ rows, columnKeys, arrowOnClick, to, config, onAction, actionButtons, deleteButtonProps}: TableBodyMainProps<T>): React.ReactElement => {

    const handleChangeRowsPerPage = useTableHandleChangeRowsPerPage();
    const customTableWidth = useTableCustomTableWidth();
    const handleChangePage = useTableHandleChangePage();
    const numberOfRows = useTableNumberOfRows();
    const customNoRows = useTableCustomNoRows();
    const smallTable = useTableIsSmallTable();
    const rowsPerPage = useTableRowsPerPage();
    const loading = useTableLoading();
    const page = useTablePage();


    const isMobile = useMediaQuery('(max-width: 800px)');

    const classes = useStyles({ customTableWidth });

    return (
        <>
            <div style={{ flexGrow: "1" }} >
                {loading && <Loading width={'100%'} />}
                <Table className={clsx(smallTable ? classes.smallTable : classes.bigTable, classes.table)} >
                    {!isMobile && <HeadTable />}
                    <BodyTable
                        to={to}
                        rows={rows}
                        config={config}
                        columnKeys={columnKeys}
                        onAction={onAction}
                        actionButtons={actionButtons}
                        arrowOnClick={arrowOnClick}
                        deleteButtonProps={deleteButtonProps}
                    />
                </Table >
                {(!loading && numberOfRows === 0) && (customNoRows ? customNoRows : <div className={classes.noResult}>לא נמצא מידע</div>)}
            </div>
            {(!loading && numberOfRows === 0) ? null : <TablePagination
                page={page}
                count={numberOfRows}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="שורות בעמוד:"
                onPageChange={handleChangePage}
                component="div"//don't take off
                labelDisplayedRows={() => `עמוד ${page + 1}`/* labelDisplayedRows */}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15, 20, 30, 40, 50]}
                SelectProps={{
                    disabled: loading,
                    renderValue: val => <div>{val as string}</div>,
                    MenuProps: { classes: { paper: classes.selectDropdown } }
                }}
                nextIconButtonProps={loading ? { disabled: loading } : undefined}
                backIconButtonProps={loading ? { disabled: loading } : undefined}
                className={clsx(classes.movePages, loading && !numberOfRows && classes.movePagesLoading)}
            />}
            <p className={classes.movePages}>{`מציג ${rowsPerPage > numberOfRows ? numberOfRows : rowsPerPage} מתוך ${numberOfRows}`}</p>
        </>
    );
}
export default TableBodyMain;


//styling
const useStyles = makeStyles({
    table: {
        width: ({ customTableWidth }: { customTableWidth: string | undefined }) => customTableWidth,
    },
    smallTable: {
        width: '70vw',
    },
    bigTable: {
        width: '100%',
    },
    movePages: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'right',
        color: teal
    },
    movePagesLoading: {
        marginTop: '100px',
    },
    selectDropdown: {
        color: teal
    },
    noResult: {
        marginTop: 25,
        fontFamily: "Assistant-SemiBold",
        color: teal,
    }
});