import makeStyles from "@material-ui/core/styles/makeStyles";

import { SelectDropdown } from "../../types";

import TableSelectDropdown from "./TableSelectDropdown";

interface TableSelectsProps {
	selects: SelectDropdown[];
	setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableSelects: React.FC<TableSelectsProps> = ({ selects, setDrawerOpen }) => {

	const classes = useStyles();

	if (!selects.length) return null;

	return (
		<div className={classes.selects}>
			{selects.map((s, i) => (
				<TableSelectDropdown select={s} key={i} setDrawerOpen={setDrawerOpen} />
			))}
		</div>
	);
}

export default TableSelects;

const useStyles = makeStyles(theme => ({
	selects: {
		gap: 10,
		display: "flex",
		[theme.breakpoints.down(800)]: {
			flexDirection: 'column',
			gap: 20,
		}
	},
}));