import { Navigate, Route, Routes } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";

import { requestTableRoutes } from "../../constants";
import { RequestTableTypes } from "../../types";

import ReassignRequestPopup from "./ReassignRequestPopup";
import RequestTables from "./RequestTables";
import Request from "./Request";


const Requests: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Routes>
                <Route key="add" path="/add" element={<Request />} />
                <Route key="reassign" path="/reassign/:requestId" element={<ReassignRequestPopup />} />
                {requestTableRoutes.map(type => (
                    <Route
                        key={`${type}-page`}
                        path={`/${type}/:requestId`}
                        element={<Request key={`${type}-comp-page`} type={type} />}
                    />
                ))}
                {requestTableRoutes.map(type => (
                    <Route
                        key={`${type}-table`}
                        path={`/${type}`}
                        element={<RequestTables key={`${type}-comp-table`} type={type} />}
                    />
                ))}
                <Route path="/*" element={<Navigate to={`/requests/${RequestTableTypes.Pending}`} replace />} />
            </Routes>
        </div>
    );
}

export default Requests;


const useStyles = makeStyles({
    container: {
        margin: "1rem min(2.5rem , 5vw)",
        flexGrow: 1,
        marginTop: 0,

        display: "flex",
        flexDirection: "column"
    }
});