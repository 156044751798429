import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { iconLogoPath, resetPasswordEmailRules, resetPasswordPassRules, firstLoginEmailRules } from '../constants';
import ResetPasswordForm from '../componenets/Login/ResetPasswordForm';
import { useLoginStyles } from './Login';
import { Background } from '../componenets';

const initialValuesEmail = { email: '', terms: false };

const initialValuesPassword = { password: '' };

const ResetPassword: React.FC = () => {
    const classes = useLoginStyles();

    const location = useLocation();
    const isResetPassword = useMemo<boolean>(() => location.pathname.includes('reset'), [location]);
    const emailEntry = useMemo<any>(() => location.search.includes('?'), [location, location.search]);

    return (
        <div className={classes.container}>
            <img width="50%" alt="logo" src={iconLogoPath} />
            {emailEntry ?
                <ResetPasswordForm
                    title="בחר סיסמה חדשה"
                    id="password"
                    emailEntry={emailEntry}
                    description="סיסמה"
                    isResetPassword={isResetPassword}
                    initialValues={initialValuesPassword}
                    rules={resetPasswordPassRules}
                /> :
                <ResetPasswordForm
                    title="הכנס/י כתובת מייל"
                    id="email"
                    emailEntry={emailEntry}
                    description="מייל"
                    isResetPassword={isResetPassword}
                    initialValues={initialValuesEmail}
                    rules={isResetPassword ? resetPasswordEmailRules : firstLoginEmailRules}
                />
            }

            <Background />
        </div>
    )
}

export default ResetPassword;