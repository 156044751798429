import { useState } from 'react';
import { FormProvider, useForm, useAlert, GoogleMapsProvider, useGoogleMaps, Option, useBaseI18n, useLanguage } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAsyncEffect } from '@hilma/tools';
import { useParams, useNavigate } from 'react-router';
import Collapse from '@material-ui/core/Collapse';
import { FormikValues } from 'formik';
import axios from 'axios';

import { errors, requestForSubstituteRules, gradeLabels, sectorsArray, gradesArray, sectorsObject, useTranslate, useDirection, } from '../constants';
import { RequestStatus, RequestTableTypes, ServerResponseForRequest } from '../types'
import { RequestForm, SimpleRequest } from '../componenets';
import { FormStyled } from '../general';


const initialValues = {
    schoolName: '',
    name: '',
    phone: '',
    date: '',
    time: [new Date("2000-01-01 08:00:01.123"), new Date("2000-01-01 13:00:01.123")],
    sector: [],
    grade: '',
    subject: '',
    note: '',
};

interface RequestForSubstituteProps {
    type?: RequestTableTypes;
}

const RequestForSubstitute: React.FC<RequestForSubstituteProps> = ({ type }) => {
    const navigate = useNavigate();

    const [data, setData] = useState<ServerResponseForRequest>({} as ServerResponseForRequest);

    const [loading, setLoading] = useState(false);

    const [disabled, setDisabled] = useState(true);

    const [collapse, setCollapse] = useState(false);

    const loadingMaps = useGoogleMaps();

    const { requestId } = useParams<{ requestId: string }>();

    const classes = useStyles();
    const form = useForm();
    const alert = useAlert();

    const setFormValues = (values: Record<string, any>) => {
        form.setValues((prev: FormikValues) => ({
            ...prev,
            ...values,
            heAddress: {
                description: values.heAddress
            }
        }))
    }

    useAsyncEffect(async () => {

        setFormValues(initialValues);

        if (requestId) {
            try {
                setLoading(true);
                const { data } = await axios.get<ServerResponseForRequest>(`/api/request/request-for-substitute/${requestId}`)
                if ((data.status === RequestStatus.Pending && type !== RequestTableTypes.Archive && type !== RequestTableTypes.Pending) || (data.status === RequestStatus.Closed && type !== RequestTableTypes.Closed && type !== RequestTableTypes.Archive))
                    navigate("/requests/");

                setData(data)

                const formValuesFromServer = {
                    ...data,
                    sector: { id: data.sector, value: sectorsObject[data.sector] },
                    time: [new Date(data?.startTime), new Date(data?.endTime)],
                    date: new Date(data?.date),
                    grade: { id: data.grade, value: gradeLabels[data.grade] }
                }

                form.setValues({ ...initialValues, ...formValuesFromServer });

            } catch (err) {
                console.error(err);
                alert('ארעה שגיאה');
            } finally {
                setLoading(false);
            }
        }
    }, [requestId]);

    const onSubmit = async () => {
        if (disabled && !!type) { // ! not sure why this is here
            setDisabled(false)
            return
        }
        try {
            setLoading(true);
            form.handleSubmit();

            const { values } = form;

            if (!form.isValid) return;
            const orderedFormValues = {
                reqId: requestId ? requestId : null,
                name: values.name,
                phone: values.phone,
                date: values.date,
                startTime: values.time[0],
                endTime: values.time[1],
                sector: values.sector,
                note: values.note,
                schoolName: values.schoolName,
                grade: values.grade.id,
                subject: values.subject,
            }

            const { data } = await axios.post(`/api/request/new-request-for-substitute`, orderedFormValues)
            navigate(`/requests/${RequestTableTypes.Pending}/${data.id}`);
        } catch (err: any) {
            console.error(typeof err?.data?.message);
            if (err?.data?.message == 'Place not found') {
                console.error(err?.data?.message)
                alert(errors.googleMapsStation.locationNotFound)
            } else if (err?.data?.message == "Location must be in Israel") {
                console.error(err?.data?.message)
                alert(errors.googleMapsStation.israelLoc)
            } else {
                console.error(err)
                alert(errors.addRequestError);
            }
        } finally {
            setLoading(false);
        }
    }
    return (
        type ?
            <Collapse in={collapse} className={classes.collapse} collapsedSize={100}>
                <div onClick={() => setCollapse(p => !p)}>
                    <SimpleRequest open={collapse} data={data} />
                </div>
                <div className={classes.details}>
                    <RequestForm
                        disabled={disabled}
                        requestId={requestId}
                        loadingMaps={loadingMaps}
                        gradesArray={gradesArray}
                        sectorsArray={sectorsArray}
                        onSubmit={onSubmit}
                        type={type}
                        loading={loading}
                    />
                </div>
            </Collapse>
            :
            <FormStyled>
                <RequestForm
                    disabled={false}
                    loading={loading}
                    loadingMaps={loadingMaps}
                    gradesArray={gradesArray}
                    sectorsArray={sectorsArray}
                    onSubmit={onSubmit}
                    type={type}
                />
            </FormStyled>
    )
}

const RequestForSubstituteComp: React.FC<RequestForSubstituteProps> = (props) => (
    <FormProvider initialValues={initialValues} rules={requestForSubstituteRules} customI18n={{ usei18n: useBaseI18n, useTranslate, useDirection, useLanguage }}>
        <GoogleMapsProvider>
            <RequestForSubstitute {...props} />
        </GoogleMapsProvider>
    </FormProvider>
);
export default RequestForSubstituteComp;

export const useStyles = makeStyles(theme => ({
    collapse: {
        width: '100%',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: '0 40px',
        marginBottom: 40,
    },
    details: {
        flexDirection: "column",
        [theme.breakpoints.down(800)]: {
            paddingTop: 20
        }
    }
}));