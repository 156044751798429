import { TableSelectOrderBy, SelectDropdown } from "../../types";

export const orderBySelect: SelectDropdown = {
	selectName: 'order',
	defaultValue: TableSelectOrderBy.Id,
	// minWidth: 180,
	selectMenu: [
		{ value: TableSelectOrderBy.Id, label: 'מיון לפי תאריך הוספה' },
		{ value: TableSelectOrderBy.Date, label: 'מיון לפי תאריך בקשה' },
	]
};