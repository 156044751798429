import { useContext } from "react";
import { TableSearchValueContext, SetTableSearchValueContext, TableHasSearchContext, TableCheckedCellsContext, SetTableCheckedCellsContext, TableLeavingRowsContext, TableColumnTitlesContext, TableHasCheckboxContext, TableLoadingContext, TableHasWholeRowClickContext, TableIconContext, TableIconExpressionContext, TableRedExpressionContext, TableIsSmallTableContext, TableCustomTableWidthContext, TableOvalConfigurationContext, TableHandleChangePageContext, TableHandleChangeRowsPerPageContext, TableRowsPerPageContext, TablePageContext, TableNumberOfRowsContext, TableAddButtonOnClickContext, TableAddButtonLabelContext, TableHasArrowOnClickContext, TableSelectsContext, TableArrowOnClickLabelContext, TableAddButtonYellowContext, TableCustomNoRowsContext, TableShowCountContext, TableHideTitleContext, TableEnteringRowsContext, TableHideArrowButtonContext, TableRowsContext, TableCheckboxButtonsContext, TableCheckedRowsDataContext, TableAdditionalHeaderComponentsContext } from "../context";

export const useTableAdditionalHeaderComponents = () => useContext(TableAdditionalHeaderComponentsContext)!;
export const useTableHandleChangeRowsPerPage = () => useContext(TableHandleChangeRowsPerPageContext)!;
export const useTableOvalConfiguration = () => useContext(TableOvalConfigurationContext)!;
export const useTableArrowOnClickLabel = () => useContext(TableArrowOnClickLabelContext);
export const useTableHasWholeRowClick = () => useContext(TableHasWholeRowClickContext)!;
export const useTableHandleChangePage = () => useContext(TableHandleChangePageContext)!;
export const useTableCustomTableWidth = () => useContext(TableCustomTableWidthContext)!;
export const useTableAddButtonOnClick = () => useContext(TableAddButtonOnClickContext);
export const useSetTableCheckedCells = () => useContext(SetTableCheckedCellsContext)!;
export const useTableHasArrowOnClick = () => useContext(TableHasArrowOnClickContext)!;
export const useTableCheckboxButtons = () => useContext(TableCheckboxButtonsContext)!;
export const useTableAddButtonYellow = () => useContext(TableAddButtonYellowContext)!;
export const useTableHideArrowButton = () => useContext(TableHideArrowButtonContext)!;
export const useTableCheckedRowsData = () => useContext(TableCheckedRowsDataContext)!;
export const useSetTableSearchValue = () => useContext(SetTableSearchValueContext)!;
export const useTableAddButtonLabel = () => useContext(TableAddButtonLabelContext);
export const useTableIconExpression = () => useContext(TableIconExpressionContext);
export const useTableRedExpression = () => useContext(TableRedExpressionContext);
export const useTableEnteringRows = () => useContext(TableEnteringRowsContext)!;
export const useTableCheckedCells = () => useContext(TableCheckedCellsContext)!;
export const useTableColumnTitles = () => useContext(TableColumnTitlesContext)!;
export const useTableIsSmallTable = () => useContext(TableIsSmallTableContext)!;
export const useTableNumberOfRows = () => useContext(TableNumberOfRowsContext)!;
export const useTableCustomNoRows = () => useContext(TableCustomNoRowsContext)!;
export const useTableSearchValue = () => useContext(TableSearchValueContext)!;
export const useTableLeavingRows = () => useContext(TableLeavingRowsContext)!;
export const useTableHasCheckbox = () => useContext(TableHasCheckboxContext)!;
export const useTableRowsPerPage = () => useContext(TableRowsPerPageContext)!;
export const useTableShowCount = () => useContext(TableShowCountContext)!;
export const useTableHasSearch = () => useContext(TableHasSearchContext)!;
export const useTableHideTitle = () => useContext(TableHideTitleContext)!;
export const useTableLoading = () => useContext(TableLoadingContext)!;
export const useTableSelects = () => useContext(TableSelectsContext)!;
export const useTableIcon = () => useContext(TableIconContext)!;
export const useTablePage = () => useContext(TablePageContext)!;
export const useTableRows = () => useContext(TableRowsContext);
