import { useState, useMemo } from "react";
import clsx from "clsx";
import List from "@material-ui/core/List";
import { useNavigate } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Popover from "@material-ui/core/Popover";
import ListItem from "@material-ui/core/ListItem";
import CreateIcon from "@material-ui/icons/Create";
import PersonIcon from "@material-ui/icons/Person";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { useLogout, useUser } from "@hilma/auth";
import Cookies from "js-cookie";

import { darkGray } from "../../constants";
import { Admin } from "../../types";

const UserDetails: React.FC = () => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  const user = useUser<Admin | null>();
  const navigate = useNavigate();
  const classes = useStyles();
  const logout = useLogout();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
  const firstName = useMemo(() => user?.name, [user]);
  const [openDeleteAlert, setOpenDeleteAlert] = useState<boolean>(false);

  const handleClickOpenAlert = () => {
    setOpenDeleteAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenDeleteAlert(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const toEditProfile = () => {
    navigate(`/edit-profile/${user?.id}`);
    setAnchorEl(null);
  };

  const handleDeleteUser = () => {
    console.log("going to delete user id:", user?.id);
    console.log("delete");
    handleCloseAlert();
  };

  return (
    <>
      {isMobile ? (
        <div onClick={(e) => handleClick(e as any)}>
          <PersonIcon color="primary" style={{ fontSize: "2.5rem" }} />
        </div>
      ) : (
        <Button
          endIcon={<KeyboardArrowDownIcon />}
          onClick={handleClick}
          className={clsx(classes.btn, open && classes.btnClicked)}
          children={<div className={classes.btnLabel}>שלום {firstName}</div>}
        />
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        className={clsx(classes.popover)}
        classes={{ paper: classes.popover }}
      >
        <Typography className={classes.typography}>
          <List className={classes.list}>
            <ListItem className={clsx(classes.listItem, classes.title)}>
              {user?.name}
            </ListItem>
            {!isMobile && (
              <>
                <ListItem className={classes.listItem}>
                  {/* {user?.schoolName} */}
                </ListItem>
                <Divider />
                <ListItem button onClick={toEditProfile}>
                  <ListItemIcon className={classes.icon}>
                    <CreateIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary="עריכת פרופיל"
                    classes={{ primary: classes.p }}
                  />
                </ListItem>
              </>
            )}
            <ListItem button onClick={handleClickOpenAlert}>
              <ListItemIcon className={classes.icon}>
                <DeleteIcon color="primary" />
              </ListItemIcon>
              <ListItemText
                primary="מחיקת משתמש"
                classes={{ primary: classes.p }}
              />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                logout();
                Cookies.remove("isAdmin");
              }}
            >
              <ListItemIcon className={classes.icon}>
                <ExitToAppIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary="יציאה" classes={{ primary: classes.p }} />
            </ListItem>
          </List>
        </Typography>
      </Popover>
      <Dialog
        open={openDeleteAlert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ direction: "rtl" }}
      >
        <DialogTitle id="alert-dialog-title">
          {"מחיקת משתמש לצמיתות"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            פעולה זו תוביל למחיקת המשתמש שלך לצמיתות. שים לב במידה וקיימות בקשות
            שמשוייכות אלייך לא נוכל למחוק את המשתמש
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAlert}>ביטול</Button>
          <Button onClick={handleDeleteUser} autoFocus>
            אישור
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserDetails;

const useStyles = makeStyles((theme) => ({
  typography: {
    background: "#F2F2F2 0% 0% no-repeat padding-box",
    fontFamily: "Assistant-Regular",
    color: theme.palette.primary.main,
    minWidth: "40px",
  },
  popover: {
    fontFamily: "Assistant-Regular",
    borderRadius: "5px",
  },
  list: {
    padding: "0",
  },
  listItem: {
    margin: "0",
    color: theme.palette.primary.main,
    justifyContent: "center",
    paddingTop: "0.4em",
    paddingBottom: "0.6em",
    fontFamily: "Assistant-Regular",
  },
  title: {
    fontWeight: "bold",
    fontSize: "1.5em",
    padding: "0 1rem",
  },
  text: {
    color: "black",
    borderBottom: "none",
    textDecoration: "none",
  },
  icon: {
    minWidth: "0",
    paddingInlineEnd: "1rem",
    // padding: '0 1.5rem 0 0.5rem',
  },
  btn: {
    color: darkGray,
    width: "fit-content",
    textTransform: "none",
    fontSize: "1.3rem",
    fontFamily: "Assistant-Regular",
    marginLeft: "1rem",
  },
  btnLabel: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(70px + 10vw)",
    width: "fit-content",
  },
  btnClicked: {
    color: theme.palette.primary.main,
  },
  p: {
    margin: "0",
    padding: "0",
    fontFamily: "Assistant-Regular",
  },
}));
