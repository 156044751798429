import makeStyles from '@material-ui/core/styles/makeStyles';
import { useNavigate } from 'react-router-dom';

import { CandidateTableTypes, RequestCandidateStatus, RequestTableTypes } from '../../types';
import { ArrowCellButton } from '../../componenets';

interface ButtonAndArrowCellProps {
    openPopup(rowId: number): any;
    type: RequestTableTypes;
    requestId?: string;
    row: any;
};

const ButtonAndArrowCell: React.FC<ButtonAndArrowCellProps> = ({ row, openPopup, type, requestId }) => {

    const navigate = useNavigate();
    const classes = useStyles();

    const assigned = type === RequestTableTypes.Closed;

    if (row.status !== RequestCandidateStatus.CandidateApproved && row.status !== RequestCandidateStatus.AdminAssigned)
        return null;

    if (Number(row.hideAssign)) return null;

    return (
        <div className={classes.assignButtons}>
            <ArrowCellButton label={assigned ? "להסרה" : "לשיבוץ"} onClick={() => openPopup(row.id)} />
            <ArrowCellButton onClick={() => navigate(`/candidates/${CandidateTableTypes.Approved}/${row.candidateId}`, { state: { from: `/requests/${type}/${requestId}` } })} />
        </div>
    )
};

export default ButtonAndArrowCell;

const useStyles = makeStyles(({
    assignButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}));