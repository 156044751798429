import { createRules } from "@hilma/forms";
import { isEmail } from 'class-validator';

export const loginRules = createRules({
    username: { required: true, classValidator: (value: string) => isEmail(value), greaterThan: 2, smallerThan: 45, },
    password: { required: true, },
});

export const smsLoginRules = createRules({
    phone: { required: true },
    smsCode: { required: false, },
});