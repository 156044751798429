import React, { createContext, useState, useEffect, useMemo, useCallback, useRef, } from "react";
import { useAsyncEffect, wrap } from "@hilma/tools";
import { useOn } from "@hilma/socket.io-react";
import { isNumber } from "class-validator";
import { useAlert } from "@hilma/forms";
import axios from "axios";

import { useAllTableFilters, useAreTableFiltersDifferentFromDefault, useRowsPerPage, useScrollToTop, useSetAllTableFilters, useSetRowsPerPage, useSetTableFilter, } from "../hooks";
import { SelectDropdown, TableColumn, TableGetResponse, TableOvalConfig, TableProps, TableRow, } from "../types";
import { tableSearchFetch } from "../functions";
import { errors } from "../constants";

export const TableHandleChangeRowsPerPageContext = createContext<((event: React.ChangeEvent<{ value: unknown }>) => void) | null>(null);
export const SetTableCheckedCellsContext = createContext<React.Dispatch<React.SetStateAction<number[]>> | null>(null);
export const TableAdditionalHeaderComponentsContext = createContext<TableProps["additionalHeaderComponents"]>([]);
export const TableHandleChangePageContext = createContext<((_: any, newPage: number) => void) | null>(null);
export const TableIconExpressionContext = createContext<((value: any) => boolean) | undefined>(undefined);
export const TableRedExpressionContext = createContext<((value: any) => boolean) | undefined>(undefined);
export const TableArrowOnClickLabelContext = createContext<TableProps["arrowOnClickLabel"]>(undefined);
export const TableIconContext = createContext<[string, string] | JSX.Element | undefined>(undefined);
export const SetTableSearchValueContext = createContext<((newValues: string) => void) | null>(null);
export const TableOvalConfigurationContext = createContext<TableOvalConfig | undefined>(undefined);
export const TableAddButtonYellowContext = createContext<TableProps["addButtonYellow"]>(false);
export const TableAddButtonOnClickContext = createContext<(() => void) | undefined>(undefined);
export const TableHideArrowButtonContext = createContext<TableProps["hideArrowButton"]>(false);
export const TableCustomNoRowsContext = createContext<TableProps["customNoRows"]>(undefined);
export const TableCheckboxButtonsContext = createContext<TableProps["checkboxButtons"]>([]);
export const TableCustomTableWidthContext = createContext<string | undefined>(undefined);
export const TableColumnTitlesContext = createContext<Array<string | JSX.Element>>([]);
export const TableAddButtonLabelContext = createContext<string | undefined>(undefined);
export const TableShowCountContext = createContext<TableProps["showCount"]>(false);
export const TableHideTitleContext = createContext<TableProps["hideTitle"]>(false);
export const TableRowsPerPageContext = createContext<number | null>(null);
export const TableHasWholeRowClickContext = createContext<boolean>(false);
export const TableHasArrowOnClickContext = createContext<boolean>(false);
export const TableSelectsContext = createContext<SelectDropdown[]>([]);
export const TableIsSmallTableContext = createContext<boolean>(false);
export const TableHasCheckboxContext = createContext<boolean>(false);
export const TableCheckedCellsContext = createContext<number[]>([]);
export const TableEnteringRowsContext = createContext<number[]>([]);
export const TableCheckedRowsDataContext = createContext<any[]>([]);
export const TableHasSearchContext = createContext<boolean>(false);
export const TableLeavingRowsContext = createContext<number[]>([]);
export const TableSearchValueContext = createContext<string>("");
export const TableNumberOfRowsContext = createContext<number>(0);
export const TableLoadingContext = createContext<boolean>(false);
export const TablePageContext = createContext<number>(0);
export const TableRowsContext = createContext<any[]>([]);

interface TableProviderProps<T extends TableRow = TableRow>
  extends TableProps<T> {
  rows: Array<T>;
  setRows: React.Dispatch<React.SetStateAction<Array<T>>>;
  children: React.ReactNode;
}

export const TableProvider = <T extends TableRow = TableRow>({
  rows: tableRows, setRows, ovalConfig, icon, iconExpression, smallTable, customTableWidth, redExpression, wholeRowClick, checkbox, columns, children, arrowOnClick, to, search, selects = [], dataUrl, requestData, addButtonOnClick, addButtonLabel, arrowOnClickLabel, addButtonYellow, customNoRows, showCount, hideTitle, onAddSocketEventMessage, addSocketEvent, shouldRemoveRowOnDeleteSocketEvent, deleteSocketEvent, hideArrowButton, changeRowsSocketEvent, checkboxButtons, additionalHeaderComponents }: TableProviderProps<T>): React.ReactElement => {
  const [checkedCells, setCheckedCells] = useState<number[]>([]);
  const [numberOfRows, setNumberOfRows] = useState<number>(0);
  const [entering, setEntering] = useState<number[]>([]);
  const [leaving, setLeaving] = useState<number[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState<number>(0);
  const skip = useRef<number>(0);

  const areTableFiltersDifferentFromDefault = useAreTableFiltersDifferentFromDefault();
  const setAllTableFilters = useSetAllTableFilters();
  const allTableFilters = useAllTableFilters();
  const setTableFilter = useSetTableFilter();
  const rowsPerPageContext = useRowsPerPage();
  const setRowsPerPage = useSetRowsPerPage();
  const scrollToTop = useScrollToTop();
  const alert = useAlert();

  const rowsPerPage = useMemo(() => Number(rowsPerPageContext) || 30, [rowsPerPageContext]);
  const searchValue = useMemo(() => allTableFilters?.search?.toString() || "", [allTableFilters]);

  const setSearchValue = useCallback((search: string) => setTableFilter("search", search), [setTableFilter]);

  const columnTitles: (string | JSX.Element)[] = useMemo(() => {
    let cols = columns.map((column: TableColumn<T>) => column.title || "");
    !hideArrowButton && (arrowOnClick || to) && cols.push("");
    if (checkbox) cols = ['', ...cols];
    return cols;
  }, [columns, arrowOnClick, to, hideArrowButton]);

  const checkedRowsData = useMemo(() =>
    tableRows.filter((_, index) => checkedCells.includes(index))
    , [tableRows, checkedCells]);

  const handleChangePage = useCallback(
    async (_: any, newPage: number) => {
      setPage(newPage);
      getMoreData(newPage);
      // scrollToTop();
    },
    [scrollToTop, setPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      skip.current = 0;
      setRows([]);
      setRowsPerPage && setRowsPerPage(event.target.value as number);
    },
    [setRowsPerPage, setRows]
  );

  const onAddSocketEvent = useCallback(
    ({ rows: newRows }: { rows: T[] }) => {
      if (!newRows?.length) return;

      alert(onAddSocketEventMessage || "", "info");

      if (
        searchValue ||
        (selects.length && areTableFiltersDifferentFromDefault(selects))
      )
        return;

      setRows((prev) => [...newRows, ...prev]);
      skip.current += newRows.length;
      setNumberOfRows((prev) => prev + newRows.length);
      setEntering([...Array(newRows.length)].map((_, i) => i));

      setTimeout(() => {
        setEntering([]);
      }, 1600);
    },
    [searchValue, allTableFilters, areTableFiltersDifferentFromDefault]
  );

  const onChangeRowsSocketEvent = useCallback(
    ({ rows: newRows }: { rows: T[] }) => {
      if (!newRows?.length) return;

      if (
        searchValue ||
        (selects.length && areTableFiltersDifferentFromDefault(selects))
      )
        return;

      setRows(newRows);
      skip.current = newRows.length;
      setNumberOfRows(newRows.length);
      setEntering([...Array(newRows.length)].map((_, i) => i));

      setTimeout(() => {
        setEntering([]);
      }, 1600);
    },
    [searchValue, allTableFilters, areTableFiltersDifferentFromDefault]
  );

  const socketRows = useRef<T[]>([]);
  useEffect(() => {
    socketRows.current = [...tableRows];
  }, [tableRows]);

  const onDeleteSocketEvent = ({ rows: deleteRows }: { rows: any[] }) => {
    if (!deleteRows.length) return;

    if (!shouldRemoveRowOnDeleteSocketEvent) return;

    const rowArray = [...socketRows.current];
    const indexesToRemove: number[] = rowArray
      .map((row, index) => {
        if (shouldRemoveRowOnDeleteSocketEvent(row, deleteRows)) return index;
      })
      .filter((x) => isNumber(x)) as number[];

    setLeaving(indexesToRemove);

    setTimeout(() => {
      setLeaving([]);
      setRows((prev) => prev?.filter((_, i) => !indexesToRemove.includes(i)));
      skip.current -= indexesToRemove.length;
      setNumberOfRows((prev) => prev - indexesToRemove.length);
      getMoreData(0, indexesToRemove.length);
    }, 500);
  };

  const getMoreData = useCallback(
    async (newPage: number, limit?: number) => {
      if (
        !limit &&
        (skip.current === numberOfRows ||
          skip.current >= (newPage + 1) * rowsPerPage)
      )
        return;

      let params: Record<string, any> = {
        ...requestData,
        skip: skip.current,
        limit: limit || rowsPerPage - (skip.current % rowsPerPage),
        shouldGetCount: false,
      };
      if (selects || search)
        params = { ...params, ...allTableFilters, search: searchValue };

      try {
        setLoading(true);
        const {
          data: { data },
        } = await axios.get<TableGetResponse<T>>(dataUrl, { params });
        skip.current += data.length;
        setRows((prev) => [...prev, ...data]);
      } catch (error) {
        console.error("error, getMoreData: ", error);
        alert(errors.getDataError);
      } finally {
        setLoading(false);
      }
    },
    [numberOfRows, rowsPerPage, searchValue, allTableFilters, requestData]
  );

  useAsyncEffect(async () => {
    //fetch all table data on page load
    if (search || selects) return;

    let active = true;
    const params = {
      ...requestData,
      skip: 0,
      limit: rowsPerPage,
      shouldGetCount: true,
    };

    try {
      setLoading(true);
      const {
        data: { data, count },
      } = await axios.get<TableGetResponse<T>>(dataUrl, { params });
      if (typeof data !== "object")
        throw new Error("data received from sever wrong");

      if (active) {
        skip.current = data.length;
        setRows(data);
        typeof count === "number" && setNumberOfRows(count);
      }
    } catch (error) {
      console.error("error, useEffect: ", error);
      alert(errors.getDataError);
    } finally {
      if (active) setLoading(false);
    }
    return () => {
      active = false;
    };
  }, [rowsPerPage, requestData, dataUrl]);

  useEffect(() => {
    //useEffect for search and filters
    if (!search && !selects) return;

    let active = true;
    setRows([]);

    tableSearchFetch(searchValue, async (searchValue: string) => {
      try {
        setLoading(true);
        const { data: { data, count } } = await axios.get<TableGetResponse<T>>(dataUrl, {
          params: { ...allTableFilters, search: searchValue, ...requestData, skip: 0, limit: rowsPerPage, shouldGetCount: true },
        });

        if (typeof data !== "object") throw new Error("data received from sever wrong");

        if (active) {
          setPage(0);
          skip.current = data.length;
          setRows(data);
          typeof count === "number" && setNumberOfRows(count);
        }
      } catch (error) {
        console.error("error, search: ", error);
        alert(errors.getDataError);
      } finally {
        if (active) setLoading(false);
      }
    });

    return () => {
      active = false;
    };
  }, [searchValue, allTableFilters, tableSearchFetch, rowsPerPage, requestData]);

  useEffect(() => {
    //when page loads selects are initialized
    if (!selects.length) return;
    setAllTableFilters(selects);
  }, [selects]);

  useOn(deleteSocketEvent || "", onDeleteSocketEvent);
  useOn(addSocketEvent || "", onAddSocketEvent);
  useOn(changeRowsSocketEvent || "", onChangeRowsSocketEvent);

  return (
    <TableHandleChangeRowsPerPageContext.Provider value={handleChangeRowsPerPage}>
      <TableArrowOnClickLabelContext.Provider value={arrowOnClickLabel}>
        <TableCustomTableWidthContext.Provider value={customTableWidth}>
          <TableAddButtonOnClickContext.Provider value={addButtonOnClick}>
            <TableHandleChangePageContext.Provider value={handleChangePage}>
              <TableHasWholeRowClickContext.Provider value={!!wholeRowClick}>
                <TableCheckedRowsDataContext.Provider value={checkedRowsData}>
                  <TableCheckboxButtonsContext.Provider value={checkboxButtons}>
                    <TableHideArrowButtonContext.Provider value={hideArrowButton}>
                      <SetTableCheckedCellsContext.Provider value={setCheckedCells}>
                        <TableAddButtonYellowContext.Provider value={addButtonYellow}>
                          <TableHasArrowOnClickContext.Provider value={!!arrowOnClick}>
                            <SetTableSearchValueContext.Provider value={setSearchValue}>
                              <TableAddButtonLabelContext.Provider value={addButtonLabel}>
                                <TableIconExpressionContext.Provider value={iconExpression}>
                                  <TableOvalConfigurationContext.Provider value={ovalConfig}>
                                    <TableRedExpressionContext.Provider value={redExpression}>
                                      <TableColumnTitlesContext.Provider value={columnTitles}>
                                        <TableCustomNoRowsContext.Provider value={customNoRows}>
                                          <TableCheckedCellsContext.Provider value={checkedCells}>
                                            <TableIsSmallTableContext.Provider value={!!smallTable}>
                                              <TableNumberOfRowsContext.Provider value={numberOfRows}>
                                                <TableSearchValueContext.Provider value={searchValue}>
                                                  <TableRowsPerPageContext.Provider value={rowsPerPage}>
                                                    <TableHasCheckboxContext.Provider value={!!checkbox}>
                                                      <TableEnteringRowsContext.Provider value={entering}>
                                                        <TableShowCountContext.Provider value={showCount}>
                                                          <TableHideTitleContext.Provider value={hideTitle}>
                                                            <TableLeavingRowsContext.Provider value={leaving}>
                                                              <TableHasSearchContext.Provider value={!!search}>
                                                                <TableSelectsContext.Provider value={selects}>
                                                                  <TableLoadingContext.Provider value={loading}>
                                                                    <TableRowsContext.Provider value={tableRows}>
                                                                      <TableIconContext.Provider value={icon}>
                                                                        <TablePageContext.Provider value={page}>
                                                                          <TableAdditionalHeaderComponentsContext.Provider value={additionalHeaderComponents}>
                                                                            {children}
                                                                          </TableAdditionalHeaderComponentsContext.Provider>
                                                                        </TablePageContext.Provider>
                                                                      </TableIconContext.Provider>
                                                                    </TableRowsContext.Provider>
                                                                  </TableLoadingContext.Provider>
                                                                </TableSelectsContext.Provider>
                                                              </TableHasSearchContext.Provider>
                                                            </TableLeavingRowsContext.Provider>
                                                          </TableHideTitleContext.Provider>
                                                        </TableShowCountContext.Provider>
                                                      </TableEnteringRowsContext.Provider>
                                                    </TableHasCheckboxContext.Provider>
                                                  </TableRowsPerPageContext.Provider>
                                                </TableSearchValueContext.Provider>
                                              </TableNumberOfRowsContext.Provider>
                                            </TableIsSmallTableContext.Provider>
                                          </TableCheckedCellsContext.Provider>
                                        </TableCustomNoRowsContext.Provider>
                                      </TableColumnTitlesContext.Provider>
                                    </TableRedExpressionContext.Provider>
                                  </TableOvalConfigurationContext.Provider>
                                </TableIconExpressionContext.Provider>
                              </TableAddButtonLabelContext.Provider>
                            </SetTableSearchValueContext.Provider>
                          </TableHasArrowOnClickContext.Provider>
                        </TableAddButtonYellowContext.Provider>
                      </SetTableCheckedCellsContext.Provider>
                    </TableHideArrowButtonContext.Provider>
                  </TableCheckboxButtonsContext.Provider>
                </TableCheckedRowsDataContext.Provider>
              </TableHasWholeRowClickContext.Provider>
            </TableHandleChangePageContext.Provider>
          </TableAddButtonOnClickContext.Provider>
        </TableCustomTableWidthContext.Provider>
      </TableArrowOnClickLabelContext.Provider>
    </TableHandleChangeRowsPerPageContext.Provider>
  );
};
