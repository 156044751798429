import { useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '@hilma/forms';
import axios from 'axios';

import { ConfirmPopup, ConfirmPopupProps } from '../../general';
import { GenericCandidateTable } from '../../componenets';
import { RequestTableTypes } from '../../types';
import { errors } from '../../constants';

import ButtonAndArrowCell from './ButtonAndArrowCell';
import RequestStatusCell from './RequestStatusCell';

interface RequestCandidatesTableProps {
    type: RequestTableTypes;
    requestId?: string;
};

const assignButtonTexts: Partial<ConfirmPopupProps> = {
    title: "שים לב!",
    confirmText: "שיבוץ",
    text: "האם את/ה בטוח שברצונך לשבץ מורה לשיעור זה?",
}

const switchOrDeleteButtonTexts: Partial<ConfirmPopupProps> = {
    title: "ביטול שיבוץ",
    confirmText: "שיבוץ מחדש",
    secondConfirmText: "מחיקת קריאה",
    text: "לאחר ביטול השיבוץ מה תרצה לעשות עם הקריאה?",
}

const RequestCandidatesTable: React.FC<RequestCandidatesTableProps> = ({ type, requestId }) => {

    const popupProps = useRef<Partial<ConfirmPopupProps> & { rowId: number }>();
    const [popup, setPopup] = useState<boolean>(false);
    const navigate = useNavigate();
    const classes = useStyles();
    const alert = useAlert();

    const assign = async () => {
        try {
            await axios.post(`/api/request-candidate/admin-assigned/${popupProps.current?.rowId}`);
            alert('הודעה נשלחה למורה', 'success');
            setPopup(false);
            navigate(`/requests/${RequestTableTypes.Closed}/${requestId}`);
        } catch (e) {
            console.error('e: ', e);
            alert(errors.assignCandidateError);
        }
    }

    const deleteRequest = async () => {
        try {
            await axios.post(`/api/request/delete-a-request/${requestId}`)
            alert('הקריאה נמחקה בהצלחה', 'success');
            navigate(`/requests/${RequestTableTypes.Closed}`);
        } catch (e: any) {
            console.error('e: ', e);
            alert(e?.status === 422 ? "לא ניתן למחוק בקשות מהעבר" : errors.deleteRequestError, 'error')
        }
    }

    const reassignRequest = async () => {
        navigate(`/requests/reassign/${requestId}`);
    }

    const openPopup = (rowId: number) => {
        if (type === RequestTableTypes.Closed) {
            popupProps.current = { ...switchOrDeleteButtonTexts, onConfirm: reassignRequest, onSecondConfirm: deleteRequest, rowId };
            setPopup(true);
        } else {
            popupProps.current = { ...assignButtonTexts, onConfirm: assign, rowId };
            setPopup(true);
        }
    }

    return (
        <>
            <div className={classes.tableContainer}>
                <GenericCandidateTable
                    additionalColumns={[
                        { customRender: (row: any) => <RequestStatusCell status={row.status} /> },
                        { customRender: (row: any) => <ButtonAndArrowCell row={row} type={type} openPopup={openPopup} requestId={requestId} /> },
                    ]}
                    dataUrl={`/api/request-candidate/get-requests-candidates/${requestId}`}
                    showCount={type !== RequestTableTypes.Closed}
                    tableTitle="מחליפים מתאימים"
                    requestData={{ type }}
                    requestId={requestId}
                    hideTitle
                />
            </div>
            <ConfirmPopup
                open={!!popup}
                onClose={() => setPopup(false)}
                {...popupProps.current}
            />
        </>
    )
};

export default RequestCandidatesTable;

const useStyles = makeStyles(({
    tableContainer: {
        flexGrow: 1,
        top: "5.5vh",
    },
    assignButtons: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    }
}));