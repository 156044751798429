import { createContext, useState, useEffect } from 'react';
import { wrap } from '@hilma/tools';

export const RowsPerPageContext = createContext<number | null>(null)
export const SetRowsPerPageContext = createContext<((num: number) => void) | null>(null)
export const GetRowsPerPageContext = createContext<(() => number) | null>(null)

export const RowsPerPageProvider: React.FC = ({ children }) => {

	const [rowsPerPage, setRowsPerPage] = useState<number | null>(null);

	const getRows = () => {
		if (rowsPerPage) return rowsPerPage;

		const rowsNumber = localStorage.getItem('rowsPerPage') || 30;
		setRowsPerPage(Number(rowsNumber) || 30);

		return Number(rowsNumber) || 30;
	}

	const setRows = (number: number) => {
		localStorage.setItem('rowsPerPage', number.toString());
		setRowsPerPage(number);
	}

	useEffect(() => {
		getRows();
	}, []);

	return (
		<RowsPerPageContext.Provider value={rowsPerPage}>
			<SetRowsPerPageContext.Provider value={setRows}>
				<GetRowsPerPageContext.Provider value={getRows}>
					{children}
				</GetRowsPerPageContext.Provider>
			</SetRowsPerPageContext.Provider>
		</RowsPerPageContext.Provider>
	) as React.ReactElement;
}