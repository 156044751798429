
import { GridifyChildren, FormInput, FormImage, GoogleMapsProvider, useGoogleMaps, useForm, FormSelect } from '@hilma/forms'
import makeStyles from '@material-ui/styles/makeStyles';
import { provide } from '@hilma/tools';

import { LocationAutocomplete, CitiesAutocomplete, MultipleSelectAutoComplete } from '../../componenets';
import { schoolStatusOptions, sectorsArray, teal } from '../../constants';
import { FormStyled, SchoolChips } from '../../general';
import { AdminTable } from '../../types';

const EditSchool: React.FC<{ loading?: boolean, schoolId?: string }> = ({ loading, schoolId }) => {
	const loadingMaps = useGoogleMaps();
	const classes = useStyles();
	const form = useForm();
	return (
		<FormStyled>
			{/* <div className={classes.topDetails}> // uncomment for school status
				<FormChips id="status" items={schoolStatusOptions} classes={{ chip: classes.chip }} />
				<DateDetails title='בית הספר' created={form.values?.created} approved={form.values?.approvedDate} />
			</div> */}

			{schoolId && <SchoolChips schoolId={schoolId} classes={{ container: classes.approveButton }} id="status" items={schoolStatusOptions} />}
			<GridifyChildren>
				<div className={classes.imageContainer}><FormImage id='image' size={2_000_000} /></div>
				<div>
					<FormInput id='name' description='שם בית הספר' />
					<FormInput id='schoolSymbol' type='number' description='סמל מוסד' />
					<FormInput id='phone' description='טלפון' />
					<FormSelect id='sector' description='מגזר' options={sectorsArray} />
				</div>
			</GridifyChildren>

			<GridifyChildren>
				<CitiesAutocomplete id='city' description='ישוב' loading={loading && !form.values.city} />
				<LocationAutocomplete id='heAddress' description='כתובת' address={form.values.heAddress} loading={loadingMaps} />
			</GridifyChildren>

			<div className={classes.label}>מנהלי בית הספר</div>
			<MultipleSelectAutoComplete
				schoolId={schoolId}
				showEdit
				showStar
				id='admins'
				placeHolder='הוסף מנהלי בית ספר'
				fetchUrl={`/api/admin/get-school-admins/${schoolId}`}
				isLoading={loading && !form.values.admins?.length}
				renderOptionProps={(a: AdminTable) => {
					const isStar = a.id === form.values.mainId;
					return { isStar: a.id === form.values.mainId, canDelete: !isStar };
				}}
			/>

		</FormStyled>
	)
};

export default provide(GoogleMapsProvider)(EditSchool);

const useStyles = makeStyles(({
	imageContainer: {
		height: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: 10,
	},
	chip: {
		borderWidth: 1.3,
		fontSize: '1.3em',
	},
	topDetails: {
		marginBottom: 20,
		display: 'flex',
		flexDirection: 'row'
	},
	label: {
		fontFamily: 'Assistant-bold',
		color: teal,
		fontSize: '1.2rem',
		marginTop: 20,
		marginBottom: 15,
		textAlign: 'left',
	},
	approveButton: {
		marginInlineStart: "6vh"

	}
}));