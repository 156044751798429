import React, {useMemo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAsyncEffect} from '@hilma/tools';

import {AdminTable, AdminTableTypes, SelectDropdown, SelectDropdownMenu, TableColumn} from '../../types';
import {useGetDropdownData} from '../../hooks';
import {Table} from '../../componenets';
import {downloadServerFile, FormFile, useAlert, useForm} from "@hilma/forms";
import {ConfirmPopup} from "../../general";
import axios from "axios";
import {getAlertError} from "../../functions";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {teal} from "../../constants";

interface AdminTablesProps {
    type: AdminTableTypes;
}

const columns: TableColumn<AdminTable>[] = [
    {field: 'name', title: 'שם', type: 'boldSearch'},
    {field: 'phone', title: 'טלפון', type: 'boldSearch'},
    {field: 'email', title: 'מייל', type: 'boldSearch'},
    {field: 'schools', title: 'בתי ספר', type: 'boldSearch'},
];

const AdminTables: React.FC<AdminTablesProps> = ({type}) => {

    // const [schoolOptions, setSchoolOptions] = useState<SelectDropdownMenu[]>([{ value: 'all', label: 'כל בתי הספר' }]);
    // const getDropdownData = useGetDropdownData();
    const [openExcelUpload, setOpenExcelUpload] = useState(false);
    const [loading, setLoading] = useState(false);
    const [counter, setCounter] = useState(0)
    const lClasses = useLocalStyles();
    const navigate = useNavigate();
    const form = useForm();
    const alert = useAlert();
    const onSubmit = async () => {
        try {

            setLoading(true);

            form.handleSubmit();
            if (!form.isValid || !Object.values(form.touched).length && !form?.values?.schoolInfoPath) return;

            await axios.post('/api/school/upload-schools', {
                files: true,
                ...form.values,
            });

            // setOpenUploadModal(false);
            alert('השינויים התעדכנו בהצלחה', 'success');
            setOpenExcelUpload(false);
        } catch (error: any) {

            alert(getAlertError(error));
        } finally {
            setLoading(false);
        }
    };
    const onClose = () => {
        form.resetForm();
        setOpenExcelUpload(false);
    }
    const onAction = async (row: any, action: string) => {
        try {
            if (action === 'delete') {
                // await axios.delete(`/api/admin/delete-admin-user/${row.id}`);
                await axios.delete(`/api/admin/delete/${row.id}`);
                setCounter(counter + 1)
            }
        } catch (error: any) {
            console.log(error);
            alert(error.data.message)
        }
    }
    const downloadExcel = async () => {
        try {
            await downloadServerFile('/file/school-excel.xlsx', 'דוגמה.xlsx', undefined, undefined, process.env.NODE_ENV === 'production');
        } catch (error) {
            console.error(error);
            alert('שגיאה בהורדת הקובץ');
        }

    }
    // const selects: SelectDropdown[] = useMemo(() => [
    // 	{
    // 		selectName: "school",
    // 		defaultValue: 'all',
    // 		selectMenu: schoolOptions
    // 	}
    // ], [schoolOptions]);

    // useAsyncEffect(async () => {
    // 	setSchoolOptions(await getDropdownData('/api/school/get-school-select-options'));
    // }, []);
    return (
        <>
            <Table
                arrowOnClick={(row) => navigate(`/admins/${type}/${row.id}`)}
                addButtonOnClick={() => navigate('/admins/add')}
                dataUrl='/api/admin/filtered-school-admins'
                to={(row) => `/admins/${type}/${row.id}`}
                addButtonLabel='הוספת מנהל'
                tableTitle='מנהלי בתי ספר'
                actionButtons={['delete']}
                onAction={onAction}
                requestData={{type}}
                columns={columns}
                // selects={selects}
                wholeRowClick
                search
                deleteButtonProps={{
                    title: 'מחק מנהל מערכת',
                    text: <div>האם אתה בטוח שברצונך למחוק את
                        המנהל<strong> לצמיתות</strong>?</div>,
                    confirmText: 'מחיקה',
                    secondConfirmText: 'לבטל',
                }}
            />
            <ConfirmPopup
                open={openExcelUpload}
                onConfirm={onSubmit}
                onClose={onClose}
                classname={lClasses.modal}
                title='העלאת בתי ספר באמצעות קובץ אקסל'
                text={<>על <span className={lClasses.link} onClick={downloadExcel}>האקסל</span> לכלול את העמודות הבאות:
                    שם בית הספר, שלב חינוך, סמל מוסד, מגזר, טלפון, שם מנהל\ת, כתובת בית הספר, סלולרי מנהל/ת אי-מייל
                    מנהל/ת רשות</>}
                children={<FormFile helperText='על הקובץ להיות קובץ מסוג xlsx או xls' id='schoolInfo'
                                    description='אקסל בתי ספר'/>}
            />
        </>
    )
};

export default AdminTables;
const useLocalStyles = makeStyles({
    modal: {
        minWidth: 350,
        minHeight: 400,
        justifyContent: 'space-between',
        display: 'flex',
        flexDirection: 'column',
        '&>div:nth-of-type(2)': {
            fontSize: '1.1rem'
        }
    },
    addExcel: {
        width: 'fit-content',
        color: teal
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer'
    }
})