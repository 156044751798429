import makeStyles from "@material-ui/styles/makeStyles";
import { Svg } from "@hilma/forms";

import { boxShadow, iconLogoPath } from "../../constants";
import { HilmaLogoSvg } from "../../svg";
import { Link, useLocation } from 'react-router-dom';

import UserDetails from "./UserDetails";
import TopBarTabs from "./TopBarTabs";
import { FC } from "react";
interface TopBarProps {
  accessibility?: boolean;
}
const TopBar: FC<TopBarProps> = ({ accessibility = false }) => {
  const classes = useStyles();
  const location = useLocation();

  const isOnRequestsPage = location.pathname.includes('/requests');
  const isOnSchoolsPage = location.pathname.includes('/schools');

    const renderLogoLink = () => {
    if (isOnRequestsPage) {
      return <Link to="/requests"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
    } else if (isOnSchoolsPage) {
      return <Link to="/schools"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
    } else {
      // Default behavior, navigate to home
      return <Link to="/"><img width="45px" src={iconLogoPath} alt="logo" /></Link>;
    }
  }; 

  return (
    <div className={classes.topBarContainer}>
      <div className={classes.logoAndTabs}>
        <div className={classes.logoContainer}>
           {renderLogoLink()}
        </div>
        {!accessibility && <TopBarTabs />}
      </div>
      <div className={classes.profileButton}>
        <UserDetails />
      </div>
    </div>
  );
};

export default TopBar;

const useStyles = makeStyles({
  topBarContainer: {
    boxShadow: `0 3px 6px ${boxShadow}`,
    display: "flex",
    justifyContent: "space-between",
    paddingBottom: 10,
    paddingRight: 20,
    paddingLeft: 20,
    paddingTop: 10,
    position: "fixed",
    backgroundColor: "white",
    width: "100vw",
    zIndex: 2,
    height: "3.8rem",
    boxSizing: "border-box",
  },
  logoAndTabs: {
    display: "flex",
    gap: 50,
  },
  profileButton: {
    alignItems: "center",
    display: "flex",
  },
  hilmaSvg: {
    height: 45,
    width: 45,
  },
  logoContainer: {
    display: "flex",
    gap: 10,
  },
});
