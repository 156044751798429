//components
import { StyledTableCell } from './StyledTableCell';
import { TableChip } from './TableChip';
//ts
import { TableOvalConfig } from '../../../types';

interface OvalCellProps {
    index: number;
    ovalConfig: TableOvalConfig;
    status: string;
    onClick?(): void;
}

export const OvalCell: React.FC<OvalCellProps> = ({ status, onClick, index, ovalConfig }) => {
    return (
        <StyledTableCell onClick={onClick} key={index}>
            <TableChip
                color={ovalConfig ? ovalConfig[status] && ovalConfig[status]![1] : undefined}
                label={ovalConfig ? ovalConfig[status] && ovalConfig[status]![0] : ''}
            />
        </StyledTableCell>
    )
}