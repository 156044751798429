import { Grade } from "../types";

export const gradeLabels: Record<Grade, string> = {
	[Grade.First]: "א-ב",
	[Grade.Second]: "ג-ד",
	[Grade.Third]: "ה-ו",
	[Grade.Fourth]: "ז-ח",
	[Grade.Fifth]: "ט-י",
	[Grade.Sixth]: 'י"א-י"ב',
	// [Grade.Seventh]: "ז",
	// [Grade.Eighth]: "ח",
	// [Grade.Ninth]: "ט",
	// [Grade.Tenth]: "י",
	// [Grade.Eleventh]: 'י"א',
	// [Grade.Twelfth]: 'י"ב',
}

export const gradesArray: { id: number; value: string }[] =
	Object.entries(gradeLabels).map(([id, value]) => ({ id: Number(id), value }));