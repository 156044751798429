import makeStyles from "@material-ui/core/styles/makeStyles";
import { Button } from "../../general";


const SchoolSignUpSuccess: React.FC = () => {

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<h1>בית הספר נרשם בהצלחה!</h1>
			<h2>בקשתך ממתינה לאישור</h2>

			<Button to='/'>חזרה לדף הבית</Button>
		</div>
	);
}

export default SchoolSignUpSuccess;

const useStyles = makeStyles(theme => ({
	container: {
		color: theme.palette.primary.main,
		alignItems: 'center',
		width: 'fit-content',
		display: 'flex',
		padding: '20px 10vw 40px',
		flexDirection: 'column',
		border: '2px solid',
		margin: '50px auto',
		boxShadow: '0 0 2px teal'
	},
}));