import { ComponentType } from 'react';
import withStyles from '@material-ui/styles/withStyles';
import TableCell from '@material-ui/core/TableCell';

import { forestGreen, teal } from '../../../constants';

export const StyledTableCell: ComponentType<any> = withStyles(theme => ({
    root: {
        fontFamily: 'Assistant-Regular',
        borderBottom: 'none',
        color: forestGreen,
        [theme.breakpoints.down(800)]: {
            color: teal,
        }
    }
}))(TableCell);