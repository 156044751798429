import { createContext, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { wrap } from "@hilma/tools";
import produce from "immer";

import { SelectDropdown } from "../types";

const ARRAY_JOIN_SEPARATOR = '=array=';
export const AllTableFiltersContext = createContext<Record<string, string>>({});
export const GetTableFilterContext = createContext<((key: string) => string | null) | null>(null);
export const GetSelectFilterContext = createContext<((select: SelectDropdown) => string | string[] | undefined) | null>(null);
export const SetTableFilterContext = createContext<((key: string, value?: string | number) => void) | null>(null);
export const SetAllTableFiltersContext = createContext<((selects: SelectDropdown[]) => void) | null>(null);
export const AreFiltersDifferentFromDefaultContext = createContext<((selects: SelectDropdown[]) => boolean) | null>(null);

/*
The session storage value for the table filters and search is as following:
sessionStorage : Record<key = string, value = string>
	key = location.pathname/(selectName | search);
	value = selected option from dropdown or search value
meaning,
each table has all of it's selects and search value in the local storage
*/
export const TableFiltersProvider: React.FC = ({ children }) => {

	const location = useLocation();
	const pathname = useMemo(() => location.pathname, [location]);

	//state that has the whole session storage build as an object : { pathname : { search:string, selectName: string } }.
	const [allCurrentStorageItems, setAllCurrentStorageItems] = useState<Record<string, Record<string, string>>>({});
	const currentTableState = useMemo(() => allCurrentStorageItems[pathname], [pathname, allCurrentStorageItems])

	//sets a storage item's value
	const setStorageItem = (key: string, value?: (string | number | Array<any>), dontUpdateState?: boolean) => {
		const valueToString = Array.isArray(value) ? value.join(ARRAY_JOIN_SEPARATOR) : value?.toString() || '';

		if (!dontUpdateState) {
			const prevState = JSON.parse(JSON.stringify(allCurrentStorageItems));
			const currentState = { ...prevState[pathname] };
			//setting the sessionStoage object state at the pathname at the key to new value
			setAllCurrentStorageItems({ ...prevState, [pathname]: { ...currentState, [key]: Array.isArray(value) ? value : value?.toString() || '' } });
		}

		//updating session storage item at pathname/key to new value
		sessionStorage.setItem(`${pathname}/${key}`, valueToString);
	}

	const setAllStorageItems = async (selects: SelectDropdown[]) => {
		//when a table is mounted this function is called
		//the function gets an array of all of the selects that were given for this table and updates the session storage state that way all of the selects have a value in the session storage
		//the value that is saved is either the value that existed in the session storage and if no value is found there then the selects default value is used
		//in addition the tables search is initialized to either what is found in session storage or to empty string

		//setting search
		const hasSearchValue = getStorageItem('search');
		const newState: Record<string, any> = { search: hasSearchValue || '' }

		for (const select of selects) {
			const hasValue = getStorageItem(select.selectName);
			const index = select.selectMenu.findIndex(option => option.value.toString() === hasValue?.toString());
			if (!hasValue) { //if there is no value in session storage for this select its initialized to selects default value 
				newState[select.selectName] = select.defaultValue?.toString();
				setStorageItem(select.selectName, select.defaultValue, true);
			} else if (select.dateRange && hasValue.includes(ARRAY_JOIN_SEPARATOR))
				newState[select.selectName] = hasValue.split(ARRAY_JOIN_SEPARATOR);
			else if (index < 0) //if the sessionStorage has a value for select that isn't one of the selects options then the value is set to selects default value
				newState[select.selectName] = select.defaultValue?.toString();
			else //otherwise the value is set to what is found in the sessionStorage
				newState[select.selectName] = hasValue.toString();
		}

		//sessionStorage state is updated
		setAllCurrentStorageItems(produce(draft => { draft[pathname] = newState }));
	}

	//gets storage item based on key
	//sessionStoage item of current pathname/key is returned
	const getStorageItem = (key: string): (string | null) => {
		return sessionStorage.getItem(`${pathname}/${key}`);
	}

	//gets storage item based on select
	//sessionStoage item of select = pathname/selectName is returned, if the value in the session storage is not one of the options in selectMenu then undefined is returned
	const getSelectStorageItem = (select: SelectDropdown): (string | string[] | undefined) => {
		const value = getStorageItem(select.selectName);
		const index = select.selectMenu.findIndex(option => option.value.toString() === value?.toString());
		if (select.dateRange && value?.includes(ARRAY_JOIN_SEPARATOR)) return value.split(ARRAY_JOIN_SEPARATOR);
		if (!value || index < 0) return;
		return value;
	}

	const areFiltersDifferentFromDefault = (selects: SelectDropdown[]): boolean => {
		if (!currentTableState) return false;
		const currentSelectKeys: string[] = Object.keys(currentTableState);
		return currentSelectKeys.some(key => {
			if (key === 'search') return false;
			return selects.find(s => s.selectName === key)?.defaultValue !== currentTableState[key];
		});
	}

	return (
		<AllTableFiltersContext.Provider value={currentTableState}>
			<GetTableFilterContext.Provider value={getStorageItem}>
				<GetSelectFilterContext.Provider value={getSelectStorageItem}>
					<SetTableFilterContext.Provider value={setStorageItem}>
						<SetAllTableFiltersContext.Provider value={setAllStorageItems}>
							<AreFiltersDifferentFromDefaultContext.Provider value={areFiltersDifferentFromDefault}>
								{children}
							</AreFiltersDifferentFromDefaultContext.Provider>
						</SetAllTableFiltersContext.Provider>
					</SetTableFilterContext.Provider>
				</GetSelectFilterContext.Provider>
			</GetTableFilterContext.Provider>
		</AllTableFiltersContext.Provider>
	);

}