import makeStyles from "@material-ui/core/styles/makeStyles";
import { boxShadow, error, lightGray } from ".";

export const useFormStyles = makeStyles(theme => ({

    form: {
        backgroundColor: "#fdfdfd",
        padding: "2em 11vw",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "5px",
        marginBottom: "1em"
    },

    container: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
        },
        alignItems: "center",
        marginTop: "25px",
        position: "relative",
        fontSize: "1rem",
    },

    autocompleteContainer: {
        marginTop: "25px",
    },

    autocompleteInnerContainer: {
        [theme.breakpoints.up('sm')]: {
            display: "flex",
        },
        fontSize: "1rem",
    },

    autocompletePopper: {
        [theme.breakpoints.up('sm')]: {
            maxWidth: "min(350px , 100%)",
        },
    },

    autocompleteInputContainer: {
        width: "min(350px , 100%)",
    },

    labelContainer: {
        marginTop: '0px',
    },

    label: {
        width: "calc(100px + 15vw)",
    },

    selectInputRoot: {
        width: "min(350px , 100%) !important",
        '&:focus': {
            borderRadius: "3px !important",
        },
    },

    selectRoot: {
        backgroundColor: "white",
        border: "0.5px solid #3E504C",
        borderRadius: "3px",
        color: theme.palette.primary.main,
        display: "flex",
        width: "min(350px , 100%)",
        marginTop: '0px',
    },

    selectContainer: {
        boxSizing: 'border-box',
        height: '2rem !important',
        '&:focus': {
            borderRadius: "3px !important",
        },
    },

    selectIcon: {
        marginTop: '0 !important',
        width: '1em !important',
        height: '1em !important'
    },

    root: {
        height: "2rem",
        backgroundColor: "white",
        border: "0.5px solid #3E504C",
        borderRadius: "3px",
        color: theme.palette.primary.main,
        display: "flex",
        width: "min(350px , 100%)",
        marginTop: '0px',

        '&:focus': {
            borderRadius: "3px !important",
        },
    },

    file: {
        padding: "0px 7px",
        display: "flex",
        alignItems: "center",
    },

    fileOpened: {
        color: "-webkit-link"
    },

    iconButton: {
        padding: "0"
    },

    fileIcon: {
        fontSize: "1em",
        marginInlineEnd: "0.3em"
    },

    input: {
        '&:disabled': {
            backgroundColor: lightGray,
            // color: theme.palette.primary.main
        }
    },

    extendedError: {
        color: error,
        fontSize: "11px",
        height: "15px",
        width: "fit-content"
    },

    error: {
        position: "absolute",
        bottom: -15,
        left: "calc(100px + 15vw)",
    },

    inputError: {
        border: `0.5px solid ${error} !important`,
    },

    bottom: {
        marginInlineStart: "auto",
        width: "fit-content",
        display: "flex",
        alignItems: "center",
        justifyContent: "end",

        [theme.breakpoints.down('sm')]: {
            width: "100%",
            // justifyContent: "space-between",
        },
    },

    delete: {
        color: theme.palette.primary.main,
        borderRadius: "100px",
        borderColor: theme.palette.primary.main,
        boxShadow: `0px 3px 6px ${boxShadow}`,
        marginInlineEnd: "10px",
    },

    checkboxContainer: {
        width: "fit-content",
        alignItems: "center"
    },

    checkboxButton: {
        padding: "8px",
        margin: "2px 0"
    },

    checkboxLabel: {
        fontSize: "1rem",
        padding: "0"
    },

    selectThing: {
        minWidth: "350px",
        '&>input[aria-hidden]': {
            display: 'none',
            visibility: 'hidden'
        }
    },

    autocomplete: {
        '&>div>div:nth-child(2)': {
            marginTop: 0,
            width: 'min(350px, 100%)',
            '&>div': {
                marginTop: 0,
                '&>:nth-child(2)>div>div[class=MuiAutocomplete-endAdornment]': {
                    paddingRight: '6px',
                    '& svg': {
                        color: theme.palette.primary.main
                    }
                }
            },
            '&>div>:nth-child(1)': {
                display: 'none',
                visibility: 'hidden',
            }
        }
    }
}));