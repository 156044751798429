import { errors } from "../constants";

export const getAlertError = (error: any, defaultError?: string): string => {
    switch (error?.status) {
        case 409:
            return errors.phoneExists;
        case 422:
            return errors.googleMapsStation.israelLoc;
        default:
            return defaultError || errors.generalError;
    }
};