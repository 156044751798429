// InstallPopup.js
import React from 'react';
import './InstallPopup.css'

interface InstallPopupProps {
  installPrompt: Event | null;
  onInstallClick: () => void;
  onCloseClick: () => void;
}

const InstallPopup: React.FC<InstallPopupProps> = ({ installPrompt, onInstallClick, onCloseClick }) => {
  return (
    installPrompt && (
      <div className="install-popup-overlay">
        <div className="install-popup">
          <button className="close-button" onClick={onCloseClick}>
            <span aria-hidden="true">&times;</span>
          </button>
          <p>התקן את האפליקציה הזו על מסך הבית שלך לחוויה טובה יותר!</p>
          <button onClick={onInstallClick}>הוסף למסך הבית</button>
        </div>
      </div>
    )
  );
};

export default InstallPopup;
