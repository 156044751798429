import { green } from '@hilma/forms';
import clsx from 'clsx';

import IndeterminateCheckboxIcon from '@material-ui/icons/IndeterminateCheckBox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';

interface CheckboxHeadCellProps {
    smallTable?: boolean;
    numberOfRows: number;
    checkedCells: number[];
    handleHeadCheckboxClick(): void;
}

export const CheckboxHeadCell: React.FC<CheckboxHeadCellProps> = ({ smallTable, checkedCells, numberOfRows, handleHeadCheckboxClick }) => {
    const classes = useStyles()
    return (
        <TableCell padding="checkbox" className={clsx(smallTable ? classes.smallTableRowHead : classes.bigTableRowHead, classes.tableRowHead)}>
            <Checkbox
                checkedIcon={<IndeterminateCheckboxIcon />}
                checked={checkedCells.length === numberOfRows}
                onChange={handleHeadCheckboxClick}
                color='primary'
            />
        </TableCell>
    )
};

const useStyles = makeStyles({
    tableRowHead: {
        borderBottom: "none",
        fontSize: '15px',
    },
    bigTableRowHead: {
        color: green,
        fontFamily: 'Assistant-SemiBold',
    },
    smallTableRowHead: {
        color: green,
        fontFamily: 'Assistant-Regular',
    },
});