import { ChartData, ChartOptions } from "chart.js";

import { statisticsData } from "../types";
import { daysOfWeekArray } from "./daysOfWeek";
import { gradesArray } from "./grades";

export const statisticsComponentsGeneral = ({ requestsBarByDay = [], requestsBar = [], entriesPie = [], requestsByGradesBar = [[], [], []], requestsBySubjectsBar = [[], [], []], subjects = [], lineChartDates = [] }: statisticsData): Array<{
    title: string, components: ({
        component: 'bar',
        props: { data: ChartData<"bar", number[], unknown>, options?: ChartOptions<"bar"> }
    } |
    {
        component: 'pie',
        props: { data: ChartData<"pie", number[], unknown>, options?: ChartOptions<"pie"> }
    }
        |
    {
        component: 'line',
        props: { data: ChartData<"line", number[], unknown>, options?: ChartOptions<"line"> }
    }
    )[]
}> => [
        {
            title: 'איסוף דאטא כללי',

            components: [{
                component: 'bar',
                props: {
                    data: {
                        labels: ['בקשות החלפה שפורסמו', 'תגובות חיוביות שהתקבלו', 'אישורים סופיים'],
                        datasets: [
                            {
                                data: requestsBar,
                                backgroundColor: [
                                    "#35788C",
                                    "#B7D7DE",
                                    "#FFB71A",
                                ],

                            },
                        ]
                    },
                    options: {

                        plugins: {
                            legend: {
                                display: false,
                            }
                        }
                    }
                }
            },
            {
                component: 'pie',
                props: {
                    data: {
                        labels: ['כניסות מנהלים', 'כניסות מורים מחליפים'],
                        datasets: [
                            {
                                data: entriesPie,
                                hoverOffset: 3,
                                backgroundColor: [
                                    "#35788C",
                                    "#6EC0C1",
                                ],

                            },
                        ]
                    },
                    options: {
                        aspectRatio: 2,
                        plugins: {
                            title: {
                                position: "top",
                                display: true,
                                font: { size: 16 },
                                text: `${entriesPie?.reduce((a, b) => a + b, 0)} כניסות`,
                            },
                            legend: {
                                labels: {
                                    font: {
                                        size: 14
                                    }
                                },
                                position: "bottom",
                            }
                        }
                    }
                }
            }]
        },
        // {
        //     title: 'צמיחת משתמשים',
        //     components: [{
        //         component: 'line',
        //         props: {
        //             data: {
        //                 labels: lineChartDates,
        //                 datasets: [
        //                     {
        //                         data: requestsBar,
        //                         backgroundColor: [
        //                             "#35788C",
        //                             "#B7D7DE",
        //                             "#FFB71A",
        //                         ]
        //                     },
        //                 ]
        //             },
        //             options: {
        //                 plugins: {
        //                     legend: {
        //                         display: false,
        //                     }
        //                 }
        //             }
        //         }

        //     }],
        // },

        // {
        //     title: "משתמשים",
        //     components: [{

        //         component: 'bar',
        //         props: {
        //             data: {
        //                 labels: ['סה"כ משתמשים פעילים', 'סה"כ משתמשים'],
        //                 datasets: [
        //                     {
        //                         data: entriesPie,
        //                         backgroundColor: [
        //                             "#35788C",
        //                             "#6EC0C1",
        //                         ],

        //                     },
        //                 ]
        //             },
        //             options: {
        //                 aspectRatio: 2,
        //                 plugins: {
        //                     title: {
        //                         display: false,
        //                         text: `${entriesPie?.reduce((a, b) => a + b, 0)} כניסות`,
        //                     },
        //                     legend: {
        //                         display: false,
        //                         position: 'left'
        //                     }
        //                 }
        //             }
        //         }
        //     }]
        // },

        {
            title: 'פניות לפי שכבות גיל',
            components: [{
                component: 'bar',
                props: {
                    options: {
                        plugins: {
                            legend: {
                                labels: { font: { size: 18 } }
                            }
                        },
                    },
                    data: {
                        labels: gradesArray.map(g => g.value),
                        datasets: [
                            { label: 'בקשות החלפה שפורסמו', data: requestsByGradesBar[0], backgroundColor: "#35788C", },
                            { label: 'תגובות חיוביות שהתקבלו', data: requestsByGradesBar[1], backgroundColor: "#B7D7DE", },
                            { label: 'אישורים סופיים', data: requestsByGradesBar[2], backgroundColor: "#FFB71A" }
                        ]
                    },
                }
            }]
        },


        {
            title: 'פניות לפי מקצוע לימוד',
            components: [{

                component: 'line',
                props: {
                    options: {
                        plugins: {
                            legend: {
                                labels: { font: { size: 18 } }
                            }
                        },
                    },
                    data: {
                        labels: subjects,
                        datasets: [
                            { label: 'בקשות החלפה שפורסמו', data: requestsBySubjectsBar[0], backgroundColor: "#35788C", borderColor: "#35788C" },
                            { label: 'תגובות חיוביות שהתקבלו', data: requestsBySubjectsBar[1], backgroundColor: "#B7D7DE", borderColor: "#B7D7DE" },
                            { label: 'אישורים סופיים', data: requestsBySubjectsBar[2], backgroundColor: "#FFB71A", borderColor: "#FFB71A" }
                        ]
                    },
                }
            }]
        },

        {
            title: 'פניות לפי ימים',
            components: [{
                component: 'bar',
                props: {

                    data: {
                        labels: daysOfWeekArray.map(d => d.value),
                        datasets: [
                            {
                                data: requestsBarByDay,
                                backgroundColor: [
                                    "#35788C",
                                    "#B7D7DE",
                                    "#FFB71A",
                                ],
                                borderColor: [
                                    "#35788C",
                                    "#B7D7DE",
                                    "#FFB71A",
                                ]
                            },
                        ]
                    },
                    options: {
                        plugins: {
                            legend: {
                                labels: { font: { size: 18 } },
                                display: false,
                            }
                        }
                    }
                }
            }]
        }
    ]