import { FormInput, FormInputProps, FormCheckBox, /* FormFieldWrapped, */ FormSelect, FormDate, FormTimeRange } from "@hilma/forms";
// import { FormCheckBoxProps } from "@hilma/lulav/dist/components/FormCheckBox";
// import { FormDateProps } from "@hilma/lulav/dist/components/FormDate";
// import { FormFieldWrapperProps } from "@hilma/lulav/dist/components/FormFieldWrapped";
// import { FormSelectProps } from "@hilma/lulav/dist/components/FormSelect";
import clsx from "clsx";
import { LocationAutocomplete } from "../componenets";
import { LocationAutocompleteProps } from "../componenets/LocationAutocomplete";

import { useFormStyles } from "../constants";
import FormFieldWrapper from "./FormFieldWrapped";

export const FormStyled: React.FC = (props: any) => {
    const classes = useFormStyles()
    return <div {...props} className={classes.form}>{props.children}</div>
}

export const BottomStyled: React.FC = (props) => {
    const classes = useFormStyles()
    return <div {...props} className={classes.bottom}>{props.children}</div>
}

export const FormInputStyled: React.FC<FormInputProps> = (props) => {
    const classes = useFormStyles()
    const inputClasses = { root: classes.root, /* inputError: classes.inputError, */ input: classes.input, label: classes.label, container: classes.container, labelContainer: classes.labelContainer, errorContainer: classes.error }
    return <FormInput {...props} classes={inputClasses} />
}

export const FormSelectStyled: React.FC</* FormSelectProps */ any> = (props) => {
    const classes = useFormStyles()
    const inputClasses = { root: clsx(classes.root, classes.selectThing), label: classes.label, container: classes.container, labelContainer: classes.labelContainer, errorContainer: classes.error }
    return <FormSelect {...props} classes={inputClasses} />
}

export const LocationAutocompleteStyled: React.FC<LocationAutocompleteProps> = (props: any) => {
    const classes = useFormStyles()
    const inputClasses = { root: classes.root, input: classes.input, label: classes.label, autocompleteContainer: classes.autocompleteContainer, container: classes.autocompleteInnerContainer, inputContainer: classes.autocompleteInputContainer, labelContainer: classes.labelContainer, errorContainer: classes.error }
    const autocompleteClasses = { popper: classes.autocompletePopper };
    return <LocationAutocomplete disabled={props?.disabled} hideTitle {...props} classes={inputClasses} autocompleteClasses={autocompleteClasses} />
}

export const FormDateStyled: React.FC</* FormDateProps */ any> = (props: any) => {
    const classes = useFormStyles()
    const inputClasses = { root: classes.root, input: classes.input, label: classes.label, container: classes.container, labelContainer: classes.labelContainer, errorContainer: classes.error }
    return <FormDate {...props} classes={inputClasses} />
}

export const FormTimeRangeStyled: React.FC<any> = (props) => {
    const classes = useFormStyles()
    const inputClasses = { root: classes.root, input: classes.input, label: classes.label, container: classes.container, labelContainer: classes.labelContainer, errorContainer: classes.error }
    return <FormTimeRange {...props} classes={inputClasses} />
}

export const FormCheckBoxStyled: React.FC</* FormCheckBoxProps */ any> = (props) => {
    const classes = useFormStyles()
    const checkboxClasses = { helperText: classes.checkboxLabel, container: classes.checkboxContainer, iconButton: classes.checkboxButton }
    return <FormCheckBox {...props} classes={checkboxClasses} />
}

export const FormFieldWrappedStyled: React.FC</* FormFieldWrapperProps */ any> = (props) => {
    const classes = useFormStyles()
    const inputClasses = { root: classes.root, input: classes.input, label: classes.label, container: classes.container, labelContainer: classes.labelContainer, errorContainer: classes.error }
    return <FormFieldWrapper {...props} classes={inputClasses}>{props.children}</FormFieldWrapper>
}
