import { useCallback } from "react";
import clsx from "clsx";

import makeStyles from "@material-ui/core/styles/makeStyles";
import TableRowMui from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";

import { useSetTableCheckedCells, useTableCheckedCells, useTableColumnTitles, useTableHasCheckbox, useTableIsSmallTable, useTableLoading, useTableNumberOfRows } from "../../hooks";
import { skyBlue40, teal } from "../../constants";

import { CheckboxHeadCell } from "./tableCells";

const HeadTable: React.FC = () => {
  const setCheckedCells = useSetTableCheckedCells();
  const numberOfRows = useTableNumberOfRows();
  const checkedCells = useTableCheckedCells();
  const columnTitles = useTableColumnTitles();
  const smallTable = useTableIsSmallTable();
  const checkbox = useTableHasCheckbox();
  const loading = useTableLoading();

  const classes = useStyles();

  const handleHeadCheckboxClick = useCallback(() => {
    if (loading) return;
    checkedCells.length === numberOfRows
      ? setCheckedCells([])
      : setCheckedCells(
        Array.apply(null, Array(numberOfRows)).map((_, i) => i)
      );
  }, [checkedCells, numberOfRows, setCheckedCells, loading]);

  return (
    <TableHead className={classes.header}>
      <TableRowMui
        className={clsx(
          smallTable ? classes.smallTableRowHead : classes.bigTableRowHead,
          classes.tableRowHead
        )}
      >
        {/*  {checkbox && (
          <CheckboxHeadCell
            smallTable={smallTable}
            checkedCells={checkedCells}
            numberOfRows={numberOfRows}
            handleHeadCheckboxClick={handleHeadCheckboxClick}
          />
        )} */} {/* no check all option since we are doing pagination */}
        {columnTitles.map((title, index) => (
          <TableCell
            key={index}
            children={title}
            className={clsx(
              smallTable ? classes.smallTableRowHead : classes.bigTableRowHead,
              classes.tableRowHead
            )}
          />
        ))}
      </TableRowMui>
    </TableHead>
  );
};

export default HeadTable;

const useStyles = makeStyles({
  header: {
    backgroundColor: skyBlue40,
  },
  tableRowHead: {
    borderBottom: "none",
    fontSize: "16px",
  },
  bigTableRowHead: {
    color: teal,
    fontFamily: "Assistant-SemiBold",
    padding: "5px 10px",
  },
  smallTableRowHead: {
    color: teal,
    fontFamily: "Assistant-Bold",
  },
  firstCell: {
    paddingLeft: "3vw !important",
  },
});
