//mui
import Avatar from '@material-ui/core/Avatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ImageIcon from '@material-ui/icons/Image';
//style
import clsx from 'clsx'
//components
import { StyledTableCell } from './StyledTableCell';

interface ImageCellProps {
    src: string;
    index: number;
    checkbox?: boolean;
    onClick?(): void;
}
//image !! need to center the icons
export const ImageCell: React.FC<ImageCellProps> = ({ src, index, checkbox, onClick }) => {
    const classes = useStyles()
    return (
        <StyledTableCell onClick={onClick} key={index} className={clsx(!checkbox && index === 0 ? classes.firstCell : {}, {})}>
            <Avatar classes={{ root: classes.avatar }} src={src}>
                <ImageIcon className={classes.imageIcon} />
            </Avatar>
        </StyledTableCell>
    )
}

const useStyles = makeStyles({
    firstCell: {
        paddingLeft: '3vw !important'
    },

    avatar: {
        borderRadius: 0,
    },

    imageIcon: {
        width: '35px',
        height: '35px',
    }
});