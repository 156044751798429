import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { FormattedDate, Table, FormattedTime, StarRating } from '../../componenets';
import { TableColumn, RequestTable } from '../../types';
import RequestNoteIcon from './RequestNoteIcon';


const CandidateArchivesRequests: React.FC<{}> = () => {
    const paramId = useParams<{ id: string }>();

    const columns: TableColumn<RequestTable>[] = useMemo(() => {
        const cols: TableColumn<RequestTable>[] = [
            { field: 'schoolName', title: 'שם בית הספר' },
            { field: 'subject', title: 'מקצוע' },
            { title: 'תאריך', customRender: (row) => <FormattedDate date={row.date} /> },
            { title: 'שעות עבודה', customRender: (row) => <FormattedTime startTime={row.startTime} endTime={row.endTime} /> },
            // { field: 'rating', customRender: (row) => <StarRating numStars={Number(row.rating)} /> }, //uncomment for reviews
            // { field: 'ratingNote', customRender: (row, i) => <RequestNoteIcon key={i} row={row} /> },
        ]
        return cols;
    }, [paramId?.id]);

    return (
        <div> {/* keep div for styling */}
            <Table
                dataUrl={`/api/request/get-candidate-archives-requests/${paramId.id}`}
                columns={columns}
            />
        </div>
    )
};

export default CandidateArchivesRequests;
