import { useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Option, useAlert, useForm, useGetError } from "@hilma/forms";
import clsx from 'clsx';

import { ChipItem } from "..";
import { SchoolStatus } from "../../types";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export interface ChipsProps {
    id: string;
    items: Option[];
    disabled?: boolean;
    multiple?: boolean;
    classes?: { container?: string, chip?: string };
    schoolId: string
}

const SchoolChips: React.FC<ChipsProps> = ({ schoolId, id, items, multiple, disabled, classes: chipClasses }) => {
    const navigate = useNavigate();

    const classes = useStyles();
    const form = useForm()
    const selectedItems = useMemo(() => multiple ? form.values[id]?.map((val: any) => val.toString()) : form.values[id], [form.values[id]]);
    const alert = useAlert();
    const getError = useGetError();

    const onClick = async (id: number | string) => {
        try {
            if (!multiple) {
                if (id === SchoolStatus.Approved) {
                    await axios.post("/api/school/approve-new-school", { id: parseInt(schoolId) })
                }
                else if (id === SchoolStatus.Denied) {
                    await axios.post("/api/school/deny-new-school", { id: parseInt(schoolId) })
                }
                navigate(`/schools/${form.values.status}`);
            }
        } catch (err: any) {
            alert(getError(err));
            console.error(err);
        }
    }

    const itemsFiltered = selectedItems === SchoolStatus.Pending ? items : items.filter(item => item.id !== SchoolStatus.Pending);


    return (
        <div className={clsx(classes.container, chipClasses?.container)} >
            {itemsFiltered.map((item, i) => {
                return <ChipItem key={i} value={item.value} onClick={() => onClick(item.id)} selected={multiple ? selectedItems.includes(item.id) : selectedItems === item.id} disabled={disabled} className={chipClasses?.chip} />
            }
            )} {/* hide pending if status !== to pending currently */}
        </div>
    );
}

export default SchoolChips
export const useStyles = makeStyles(theme => ({

    container: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    }
}));