import React, { useState, useMemo, useEffect } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";

import { ColumnTypeConfig, TableColumn, TableColumnKey, TableProps, TableRow } from "../../types";
import { TableProvider } from "../../context";

import TableBodyMain from "./TableBodyMain";
import TableHeader from "./TableHeader";

export const Table = <T extends TableRow = TableRow>({ dataUrl, columns, arrowOnClick, to, onAction = ({...e}) => {}, actionButtons = [], tableTitle, smallTable, customTableWidth, updateRow, requestId, deleteButtonProps, ...rest }: TableProps<T>): React.ReactElement => {
  const [rows, setRows] = useState<Array<T>>([]);

  console.log("row",rows);
  const classes = useStyles();

  const columnKeys: Array<TableColumnKey<T>> = useMemo(
    () =>
      columns.map(
        (column: TableColumn<T>) => column.customRender || column.field || ""
      ),
    [columns]
  );
  const config = useMemo<ColumnTypeConfig<T>>(
    () =>
      columns.reduce((newConfig, column: TableColumn<T>) => {
        if (!column.type || !column.field) return newConfig;
        const currentArr = newConfig[`${column.type}Field`];
        const newArr = currentArr
          ? [...currentArr, column.field]
          : [column.field];
        return {
          ...newConfig,
          [`${column.type}Field`]: newArr,
        };
      }, {} as ColumnTypeConfig<T>),
    [columns]
  );

  useEffect(() => {
    if (updateRow?.id && updateRow?.newData) {
      let prevRows = [...rows];
      prevRows = prevRows.map((prevRow) => {
        if (prevRow.id === updateRow?.id) {
          for (let a in updateRow.newData) {
            prevRow = { ...prevRow, [a]: updateRow.newData[a] };
          }
        }
        return prevRow;
      });
      setRows(prevRows);
    }
  }, [updateRow]);

  return (
    <TableProvider
      rows={rows}
      setRows={setRows}
      {...rest}
      {...{ dataUrl, columns, arrowOnClick, to, smallTable, customTableWidth, deleteButtonProps }}
    >
      <div
        className={clsx(classes.container, smallTable && classes.small)}
        style={customTableWidth ? { width: customTableWidth } : {}}
      >
        <TableHeader tableTitle={tableTitle} requestId={requestId} />
        <TableBodyMain
          rows={rows}
          config={config}
          columnKeys={columnKeys}
          onAction={onAction}
          actionButtons={actionButtons}
          arrowOnClick={arrowOnClick}
          to={to}
          deleteButtonProps={deleteButtonProps}
        />
      </div>
    </TableProvider>
  );
};

const useStyles = makeStyles({
  container: {
    width: "100%",
    margin: "auto",
    textAlign: "center",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  small: {
    width: "70vw",
  },
});
