import { Navigate, Route, Routes } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";

import { schoolPageRoutes, schoolTableRoutes } from "../../constants";
import { SchoolTableTypes } from "../../types";

import SchoolTables from "./SchoolTables";
import School from "./School";


const Schools: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Routes>
                <Route key="add" path="/add" element={<School />} />
                {schoolPageRoutes.map(type => (
                    <Route
                        key={`${type}-page`}
                        path={`/${type}/:schoolId`}
                        element={<School key={`${type}-comp-page`} type={type} />}
                    />
                ))}
                {schoolTableRoutes.map(type => (
                    <Route
                        key={`${type}-table`}
                        path={`/${type}`}
                        element={<SchoolTables key={`${type}-comp-page`} type={type} />}
                    />
                ))}
                <Route path="/*" element={<Navigate to={`/schools/${SchoolTableTypes.Approved}`} replace />} />
            </Routes>
        </div>
    );
}

export default Schools;


const useStyles = makeStyles({
    container: {
        margin: "1rem min(2.5rem , 5vw)",
        flexGrow: 1,
        marginTop: 0,

        display: "flex",
        flexDirection: "column"
    }
});