import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import Button, { ButtonProps } from '@material-ui/core/Button';
import { Svg } from '@hilma/forms';

import { ArrowBackSvg } from '../../../svg';
import { teal } from '../../../constants';

interface ArrowCellButtonProps extends ButtonProps {
	label?: string;
	onClick?(): any;
};

export const ArrowCellButton: React.FC<ArrowCellButtonProps> = ({ label, onClick, ...rest }) => {

	const classes = useStyles();

	if (label) return (
		<Button
			variant="contained"
			color="secondary"
			className={classes.blueButton}
			onClick={onClick}
			children={label}
			{...rest}
		/>
	)

	return (
		<IconButton onClick={onClick} className={classes.arrow}>
			<span className={classes.iconWrapper}><span className={classes.details}>לפרטים</span><Svg svg={ArrowBackSvg} color={teal} className={classes.icon} /></span>
		</IconButton>
	)
};

const useStyles = makeStyles(({
	blueButton: {
		height: '1.8rem',
		borderRadius: '1rem',
		fontFamily: 'Assistant-Bold',
		color: 'white',

	},
	iconWrapper: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
	},
	arrow: {
		backgroundColor: 'transparent',
		border: 'none',
		cursor: 'pointer',
	},
	icon: {
		height: 13,
		width: 13,
	},
	details: {
		fontSize: 15,
		color: "#35788C"
	}
}));