import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import { createTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import clsx from 'clsx';
import { useAsyncState, wrap } from '@hilma/tools';
import { useLanguage } from '@hilma/forms';

import { buttonScale, labelScale, skyBlue, white } from '../../constants';
import { ButtonLoading } from '..';

interface ButtonProps extends MuiButtonProps {
    submit?: boolean;
    to?: string;
    skyBlue?: boolean;
    blueLoading?: boolean;
    loading?: boolean;
    dontSetLoadigTrue?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ submit, to, onClick, children, dontSetLoadigTrue, classes: clss = {}, style = {}, skyBlue: lightGreen = false, blueLoading: greenLoading = false, loading: propsLoading, ...rest }, ref) => {
    const [loading, setLoading] = useState(false);
    const [size, setSize] = useAsyncState<Record<'height' | 'width', number> | null>(null);

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const mountedRef = useRef(true);
    const hoveredRef = useRef(false);
    const focusedRef = useRef(false);

    const classes = useStyles();

    const language = useLanguage();

    const navigate = useNavigate();

    const originalTheme = useTheme();

    const theme = useMemo(() => {
        if (!lightGreen) return originalTheme;

        return createTheme({
            ...originalTheme,
            palette: {
                primary: {
                    main: skyBlue,
                    dark: skyBlue
                }
            }
        })
    }, [lightGreen, originalTheme]);

    const setButtonSize = useCallback(() => {
        if (!buttonRef.current) return;
        const { height, width } = buttonRef.current.getBoundingClientRect();
        return setSize((hoveredRef.current || focusedRef.current) ? { height: height * labelScale, width: width * labelScale } : { height, width });
    }, [buttonRef, hoveredRef, focusedRef]);

    const handleClick = useCallback(async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (to) return navigate(`/${language}${to}`);
        event.preventDefault();
        if (!onClick || propsLoading || loading) return;
        await setButtonSize();
        !dontSetLoadigTrue && setLoading(true);
        await onClick(event);
        if (mountedRef.current) {
            setLoading(false);
            await setSize(null);
        }
    }, [onClick, loading, to, propsLoading, language, dontSetLoadigTrue]);


    useEffect(() => {
        return () => {
            mountedRef.current = false;
        }
    }, []);

    const refFunction = (node: HTMLButtonElement | null) => {
        buttonRef.current = node;

        if (!ref) return;

        try {
            (ref as ((instance: HTMLButtonElement | null) => void))(node);
        } catch (error) {
            try {
                (ref as React.MutableRefObject<HTMLButtonElement | null>).current = node;
            } catch (error) { }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <MuiButton
                ref={refFunction}
                color="primary"
                variant="contained"
                onClick={handleClick}
                onMouseEnter={() => { hoveredRef.current = true; }}
                onMouseLeave={() => { hoveredRef.current = false; }}
                onFocus={() => { focusedRef.current = true; }}
                onBlur={() => { focusedRef.current = false; }}
                classes={{
                    ...clss,
                    root: clsx(classes.root, 'custom-mui-button', clss.root, submit && classes.submitRoot, (propsLoading ?? loading) && classes.loadingButton),
                    label: clsx(classes.label, clss.label, submit && classes.submitLabel, lightGreen && classes.lightGreenLabel)
                }}
                style={size ? { ...style, ...size, cursor: 'auto' } : style}
                {...rest}
            >
                {(propsLoading ?? loading) ? <ButtonLoading greenLoading={greenLoading} /> : children}
            </MuiButton>
        </ThemeProvider>
    );
});

export default Button;

const useStyles = makeStyles(theme => ({
    root: {
        transition: '0.5s',
        cursor: 'pointer',
        borderRadius: 100,
        padding: '2px 20px',
        textDecoration: 'none !important',
        border: 'none',
        outline: 'none',
        boxShadow: "0px 3px 6px #00000029",
        height: '2.3rem',

        '&:hover, &:focus': {
            transform: `scale(${buttonScale})`,
            '-webkit-transform': `scale(${buttonScale})`,
            '-moz-transform': `scale(${buttonScale})`,
            '-ms-transform': `scale(${buttonScale})`,
            '-o-transform': `scale(${buttonScale})`,

            '& span': {
                transform: `scale(${labelScale})`,
                '-webkit-transform': `scale(${labelScale})`,
                '-moz-transform': `scale(${labelScale})`,
                '-ms-transform': `scale(${labelScale})`,
                '-o-transform': `scale(${labelScale})`,
            } as CSSProperties
        } as CSSProperties
    },

    submitRoot: {
        minWidth: 130,
        margin: 'auto',
        marginTop: 30,

        [theme.breakpoints.up(1600)]: {
            padding: '6px 22px',
            minWidth: 150
        } as CSSProperties
    },

    loadingButton: {
        pointerEvents: 'none',
        '&>span': {
            height: '1rem !important',
        },
    },

    label: {
        textTransform: 'none',
        transition: '0.5s',
        fontFamily: 'Assistant-SemiBold',
        fontSize: 18,
        [theme.breakpoints.up(1600)]: {
            fontSize: 20
        } as CSSProperties,
        [theme.breakpoints.down(500)]: {
            fontSize: 18
        } as CSSProperties
    },

    submitLabel: {
        [theme.breakpoints.up(1600)]: {
            fontSize: 20
        } as CSSProperties
    },

    lightGreenLabel: {
        color: white
    }
}));