import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingProps {
    width?: string;
    styles?: React.CSSProperties;
}

const Loading: React.FC<LoadingProps> = ({ width, styles }) => {
    return (
        <div style={{ position: "absolute", marginTop: '75px', width: width, zIndex: 1, ...styles, left: 0, right: 0 }} >
            <CircularProgress value={75} disableShrink />
        </div>
    )
}
export default Loading;
