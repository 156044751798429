import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import makeStyles from '@material-ui/core/styles/makeStyles';
import IconButton from '@material-ui/core/IconButton';
import { teal } from '../constants';

interface TitleAndBackArrowProps {
	title: string | JSX.Element;
	onClick?(): any;
	endComponent?: JSX.Element;
	loading?: boolean;
	hideBack?: boolean;
};

export const TitleAndBackArrow: React.FC<TitleAndBackArrowProps> = ({ title, onClick, endComponent, loading, hideBack }) => {

	const classes = useStyles();

	return (
		<div className={classes.container}>
			<div className={classes.back}>
				{!hideBack && <IconButton onClick={loading ? undefined : onClick}>
					<ArrowForwardIcon color='primary' />
				</IconButton>}
				<div>{title}</div>
			</div>
			<div>{endComponent}</div>
		</div>
	)
};

const useStyles = makeStyles(({
	container: {
		justifyContent: 'space-between',
		fontFamily: 'Assistant-Bold',
		alignItems: 'center',
		marginBottom: '1rem',
		fontSize: '1.5rem',
		display: 'flex',
		color: teal,
	},
	back: {
		alignItems: 'center',
		display: 'flex',
	}
}));