import { useMemo } from 'react';

import { RequestTableTypes, TableColumn, RequestTable, CandidateJob, TableProps } from '../../types';
import { purple, teal, jobs, gradeLabels } from '../../constants';
import { FormattedDate, Table } from '../../componenets';

interface RequestTableGenericProps extends Omit<TableProps, 'columns' | 'dataUrl'> {
	additionalColumns?: TableColumn<RequestTable>[];
	type?: RequestTableTypes;
}

const RequestTableGeneric: React.FC<RequestTableGenericProps> = ({ type, additionalColumns = [], ...rest }) => {

	const columns: TableColumn<RequestTable>[] = useMemo(() => [
		{ field: 'schoolName', title: 'בית הספר', type: 'boldSearch' }, // ! // todo make sure this works
		{ field: 'subject', title: 'מקצוע' },
		{ field: 'grade', title: 'כיתות', customRender: (row) => gradeLabels[row.grade] },
		{ title: 'תאריך', customRender: (row) => <FormattedDate date={row.date} /> },
		...additionalColumns,
	], [type]);

	return (
		<>
			<Table
				ovalConfig={{ [CandidateJob.Teacher]: [jobs[CandidateJob.Teacher], purple], [CandidateJob.Assistant]: [jobs[CandidateJob.Assistant], teal] }}
				dataUrl='/api/request/filtered-requests'
				columns={columns}
				wholeRowClick
				search
				{...rest}
			/>
		</>
	)
};

export default RequestTableGeneric;