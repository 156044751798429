import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Bar, Line, Pie, PolarArea } from 'react-chartjs-2';
import Card from '@material-ui/core/Card';
import { FormProvider, Option, useAlert, useGetError } from '@hilma/forms';
import axios from 'axios';

import { statisticsComponentsGeneral, statisticsComponentsAuthority, teal } from '../constants';
import { genericStatistics, statisticsData } from '../types';

import PublishIcon from '@material-ui/icons/Publish';


import React from 'react';


import {
    Chart,
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
} from 'chart.js';
import Selects from '../componenets/Select';
import { SelectDropdown } from '../types/interfaces/selectDropDown.interface';
import { useAsyncEffect } from '@hilma/tools';
import StatisticsAutocomplete from '../componenets/AutocompleteStatistics';
import { Button } from '@material-ui/core';
import { MdOutlineDownload } from 'react-icons/md';

Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    BubbleController,
    DoughnutController,
    LineController,
    PieController,
    PolarAreaController,
    RadarController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    TimeScale,
    TimeSeriesScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip,
    SubTitle
);

interface StatisticsProps { };





interface TopBarProps {
    onChange: Dispatch<SetStateAction<string>>;
}

const TopBar: React.FC<TopBarProps> = ({ onChange }) => {
    const classes = useStyles();

    const dropDown: SelectDropdown = {
        selectName: '',
        selectMenu: [
            { value: 'today', label: 'היום' },
            { value: 'lastSeven', label: 'ב-7 הימים האחרונים' },
            { value: 'lastThirty', label: 'ב-30 הימים האחרונים' },
            { value: 'lastYear', label: 'בשנה האחרונה' },
            { value: 'all', label: 'הכל' },
        ],
        minWidth: 200,
        defaultValue: 'lastYear',
        dateRange: true,
    }
    return (
        <div className={classes.container}>
            <div className={classes.btnsContainer}>
                <div className={classes.SelectDrop}>
                    <Selects select={dropDown} onSelect={(value) => { onChange(value) }} />
                </div>
            </div>
        </div>
    )
}

function monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}


function getDatesInRange(startDate: Date, endDate: Date) { //todo fix
    const date = new Date(startDate.getTime());

    const dates: string[] = [];
    var secondBetweenTwoDate = Math.abs(startDate.getTime() - endDate.getTime()) / 1000;
    if (secondBetweenTwoDate > 2680000) {
        while (date <= endDate) {
            let newDate = new Date(date).getMonth().toString()
            if (!dates.includes(newDate)) dates.push(newDate);

            date.setDate(date.getDate() + 1);
        }
    } else {
        while (date <= endDate) {
            dates.push(`${new Date(date).getDate()}/${new Date(date).getMonth()}`);
            date.setDate(date.getDate() + 1);
        }
    }

    return dates;
}




const Statistics: React.FC<StatisticsProps> = ({ }) => {


    const [timeFilter, setTimeFilter] = useState<string>('lastYear');
    const [subjects, setSubjects] = useState<Option[]>()
    const [cities, setCities] = useState<{ title: string, id: string }[]>([])
    const [city, setCity] = useState<string | undefined>("")
    const classes = useStyles();
    const alert = useAlert();
    const getError = useGetError();

    const [generalData, setGeneralData] = useState<statisticsData>({});
    const [authorityData, setAuthorityData] = useState<statisticsData>({});

    const components = useMemo(() => statisticsComponentsGeneral(generalData), [generalData]);
    const componentsAuthority = useMemo(() => statisticsComponentsAuthority(authorityData), [authorityData]);


    async function getData() {
        try {
            const { data } = await axios.get<Option[]>('/api/subject/get-subjects-as-options');
            const { data: city } = await axios.get('/api/city/get-all-cities');

            setSubjects(data)
            setCities(city)
        } catch (err: any) {
            alert(getError(err));
            console.error(err);
        }
    }
    useEffect(() => {
        getData()
    }, [city])

    useEffect(() => {
        if (!subjects) return
        const fetchGeneralDataPrices = async () => {
            try {
                const { data: dates } = await axios.post<string[]>('/api/fact-placement/get-date-range', { dateRange: timeFilter });
                const { data: pieNumbers } = await axios.post<[number]>('/api/fact-logins/getAllLoginsByDate', { dateRange: timeFilter });
                const { data: statisticsData } = await axios.post<genericStatistics>(`/api/fact-placement/get-all-requests`, { dateRange: timeFilter });
                setGeneralData({
                    entriesPie: pieNumbers,
                    requestsBar: statisticsData.generalData,
                    requestsByGradesBar: statisticsData.requestsByAge,
                    subjects: subjects.map(s => s.value as string),
                    lineChartDates: getDatesInRange(new Date(dates[0]), new Date(dates[1])),
                    requestsBySubjectsBar: [
                        statisticsData.requestsBySubject[0].map(g => g),
                        statisticsData.requestsBySubject[1].map(g => g),
                        statisticsData.requestsBySubject[2].map(g => g),
                    ],
                    requestsBarByDay: statisticsData.requestsByDay
                });
            } catch (err: any) {
                alert(getError(err));
                console.error(err);
            }
        }
        fetchGeneralDataPrices();
    }, [timeFilter, subjects]);

    useEffect(() => {
        if (!subjects || !cities) return
        const fetchAuthorityDataPrices = async () => {
            try {
                const { data: schoolCountBar } = await axios.post<[number]>('/api/fact-placement/get-all-active-schools-by-authority-and-time', { authority: city || "", dateRange: timeFilter });
                const { data: statisticsData } = await axios.post<genericStatistics>(`/api/fact-placement/get-all-requests-by-authority`, { authority: city || "", dateRange: timeFilter });
                setAuthorityData({
                    schoolCountBar: schoolCountBar,
                    requestsBar: statisticsData.generalData,
                    requestsByGradesBar: statisticsData.requestsByAge,
                    subjects: subjects.map(s => s.value as string),
                    requestsBySubjectsBar: [
                        statisticsData.requestsBySubject[0].map(g => g),
                        statisticsData.requestsBySubject[1].map(g => g),
                        statisticsData.requestsBySubject[2].map(g => g),
                    ],
                    requestsBarByDay: statisticsData.requestsByDay
                });
            } catch (err: any) {
                alert(getError(err));
                console.error(err);
            }
        }
        fetchAuthorityDataPrices();
    }, [cities, subjects, timeFilter]);


    useAsyncEffect(async () => {
        let active = true;
        return () => {
            active = false;
        }
    }, [timeFilter]);

    async function getXlsxData() {
        const data = await axios({
            url: `/api/fact-placement/get-data-for-xlsx`,
            method: 'get',
            responseType: 'blob',
        })
        const url = window.URL.createObjectURL(new Blob([data.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statistics.xls');
        document.body.appendChild(link);
        link.click();
    }


    return (
        <FormProvider initialValues={{}} rules={{}}>

            <div className={classes.page}>
                <div className={classes.downloadDiv}>

                    <Button
                        variant="outlined"
                        color="primary"
                        className={classes.addExcel}
                        startIcon={<MdOutlineDownload />}
                        onClick={getXlsxData}
                        children='יצוא לאקסל'
                    />
                </div>
                {/* <button onClick={getXlsxData}>xlsx</button> */}

                <div className={classes.selectDiv}>
                    <TopBar onChange={(value) => { setTimeFilter(value) }} />
                </div>
                {
                    components?.map(({ title, components }, index) => (
                        <div key={index} className={classes.row}>
                            <div className={classes.subTitle}>{title}</div>
                            <div className={classes.cards}>
                                {components.map(({ component, props }, index) => {
                                    if (component === 'bar') return (
                                        <Card key={index} className={classes.bar}>
                                            <Bar {...props} />
                                        </Card>
                                    )
                                    else if (component === 'pie') return (
                                        <Card key={index} className={classes.pie}>
                                            <Pie {...props} />
                                        </Card>
                                    )
                                    else if (component === 'line') return (
                                        <Card key={index} className={classes.pie}>
                                            <Line {...props} />
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    ))
                }
                <div className={classes.autocomplete}>
                    <StatisticsAutocomplete setValue={setCity} options={cities} />

                </div>

                {
                    componentsAuthority?.map(({ title, components }, index) => (
                        <div key={index} className={classes.row}>
                            <div className={classes.subTitle}>{title}</div>
                            <div className={classes.cards}>
                                {components.map(({ component, props }, index) => {
                                    if (component === 'bar') return (
                                        <Card key={index} className={classes.bar}>
                                            <Bar {...props} />
                                        </Card>
                                    )
                                    return (
                                        <Card key={index} className={classes.pie}>
                                            <Line {...props} />
                                        </Card>
                                    )
                                })}
                            </div>
                        </div>
                    ))
                }
            </div >
        </FormProvider >
    )
};

export default Statistics;

const useStyles = makeStyles(({
    page: {
        display: 'flex',
        flexDirection: 'column',
        paddingBlockEnd: 20,



    },
    downloadDiv: {
        width: "80%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "end"



    },
    addExcel: {
        width: 'fit-content',
        color: teal,
        borderRadius: "200px",





    },
    subTitle: {
        fontFamily: 'Assistant-Regular',
        fontSize: '1.5rem',
        color: '#3E504C',
        textAlign: 'left',
        padding: 10,

    },
    bar: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        padding: 20,

    },
    pie: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
    },
    row: {
        width: '80%',
        alignSelf: 'center',
        marginBottom: 60,
    },
    cards: {
        display: 'flex',
        gap: 40,

    },
    selectDiv: {
        width: "90%",
        alignSelf: "center",
        height: "10px",

    },
    autocomplete: {
        width: "80%",
        alignSelf: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        marginTop: 30,
    },
    formControl: {
        minWidth: 120,
        float: "right",

    },
    selectEmpty: {
    },
    container: {
        width: '100%',
        margin: '1% 0',
    },
    btnsContainer: {
        display: 'flex',
        margin: '0 1%',
        flexDirection: 'row-reverse',
    },
    SelectDrop: {
        textAlign: 'start',
        margin: '0 5% 0  1%',
    },
    btn: {
        borderRadius: '7px',
        border: '1px solid #006B3B',
        margin: ' 0 0.5rem'
    }
}));






