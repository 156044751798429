import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';
import clsx from 'clsx';

interface FormattedTimeProps {
	startTime?: string | Date;
	endTime?: string | Date;
	classes?: { time?: string, container?: string };
}

export const FormattedTime: React.FC<FormattedTimeProps> = ({ startTime, endTime, classes }) => {

	const styleClasses = useStyles();

	if (!startTime && !endTime) return null;

	return (
		<div className={clsx(styleClasses.container, classes?.container)}>
			<span className={classes?.time} >{`${moment(endTime).format('HH:mm')} - ${moment(startTime).format('HH:mm')}`}</span>
		</div >
	)
};

const useStyles = makeStyles(({
	container: {
		display: 'flex'
	},
}))