import React, { JSXElementConstructor, ReactElement, useMemo, cloneElement } from 'react';
import { InputProps, InputClassKey } from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import clsx from 'clsx';

import { FormHelperText, FormInputLabel } from '.';
import { confirmRegex, useDirection, useFieldProps, useFormRefs, useTextFieldError } from '@hilma/forms';

export interface FormFieldWrapperProps extends InputProps {
	id: string;
	description: string;
	tooltip?: string;
	loading?: boolean;
	helperText?: string;
	labelAdornment?: React.ReactNode;
	classes?: Partial<ClassNameMap<InputClassKey | 'container' | 'labelContainer' | 'label' | 'maxLength' | 'helperText' | 'errorContainer'>>;
	children: ReactElement<any, string | JSXElementConstructor<any>>;
}

const FormFieldWrapper: React.FC<FormFieldWrapperProps> = ({ id, type, dir, description, loading, helperText, tooltip, autoComplete: ac, labelAdornment, classes: { container, labelContainer, label, maxLength: maxLengthClass, helperText: helperTextClassName, errorContainer, ...inputClasses } = {}, children, ...rest }) => {

	const innerRef = useFormRefs();
	const fieldProps = useFieldProps(id);
	const fieldError = useTextFieldError(id);
	const direction = useDirection();

	const classes = useFormFieldWrapperStyles();

	const maxLength = useMemo<number | undefined>(() => rest.inputProps?.maxLength, [rest.inputProps?.maxLength]);

	const autoComplete = useMemo(() => {
		return ac ?? confirmRegex.test(id) ? 'off' : undefined;
	}, [id, ac]);

	return (
		<div className={container}>
			<FormInputLabel
				id={id}
				tooltip={tooltip}
				description={description}
				maxLength={maxLength}
				maxLengthContent={(innerRef.current[id]?.value ? innerRef.current[id].value.length : 0) + '/' + maxLength}
				labelAdornment={labelAdornment}
				classes={{ labelContainer, label, maxLength: maxLengthClass }}
			/>
			{loading ? (
				<Skeleton
					width="100%"
					variant="rect"
					className={clsx(classes.input, classes.skeleton, fieldError && classes.border)}
				/>
			) : (
				cloneElement(children, { ...rest, direction, fieldProps, inputClasses, autoComplete, dir, id })
			)}
			{helperText && <FormHelperText className={helperTextClassName} text={helperText} />}
			<FormHelperText className={errorContainer} text={fieldError} error />
		</div>
	);
}

export const useFormFieldWrapperStyles = makeStyles(theme => ({
	label: {
		color: theme.palette.primary.main,
		textAlign: 'left',
		fontWeight: 500,
		fontFamily: 'Assistant-SemiBold',
		[theme.breakpoints.up(500)]: {
			fontSize: 13,
		},
		[theme.breakpoints.down(500)]: {
			fontSize: 15,
		},
		[theme.breakpoints.up(1600)]: {
			fontSize: 17,
		}
	},

	containLables: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		fontSize: 13,
		[theme.breakpoints.up(1600)]: {
			fontSize: 17,
		},
		color: '#a5a5a5',
		marginTop: '5px',
	},

	skeleton: {
		borderRadius: 5,
		[theme.breakpoints.up(500)]: {
			height: 28,
		},
		[theme.breakpoints.down(500)]: {
			height: 37
		},
		[theme.breakpoints.up(1600)]: {
			height: 45
		},
	},

	input: {
		color: theme.palette.primary.main,
		display: 'flex',
		marginTop: 5,
		backgroundColor: '#F5F5F5',
		fontFamily: 'Assistant-Regular',
		borderRadius: 5,
		padding: 'unset',
		border: '1px solid white',
		transition: 'background-color 0.5s ease',

		'&:hover, &:focus-within': {
			backgroundColor: '#ECECEC',
		},

		'& input , & textarea': {
			borderRadius: 5,
			[theme.breakpoints.up(500)]: {
				padding: '4px 7px',
				fontSize: 17,
			},
			[theme.breakpoints.down(500)]: {
				padding: '6px 7px',
				fontSize: 22,
			},
			[theme.breakpoints.up(1600)]: {
				padding: '9px 10px',
				fontSize: 23,
			},
		},

		'& textarea': {
			lineHeight: 1.3
		}
	},

	border: {
		borderColor: theme.palette.error.main,
		borderStyle: 'solid',
		[theme.breakpoints.up(500)]: {
			borderWidth: 1
		},
		[theme.breakpoints.down(500)]: {
			borderWidth: 1.5
		}
	}
}))

export default FormFieldWrapper;