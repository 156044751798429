import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';

interface CheckboxCellProps {
    isChecked: boolean;
    index: number;
    handleCheckboxClick(checked: boolean, index: number): void;
};

export const CheckboxCell: React.FC<CheckboxCellProps> = ({ isChecked, index, handleCheckboxClick }) => {
    return (
        <TableCell padding="checkbox">
            <Checkbox
                checked={isChecked}
                onChange={(_, checked: boolean) => {
                    handleCheckboxClick(checked, index)
                }}
                color={'primary'}
            />
        </TableCell>
    )
};