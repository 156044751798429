import { Dispatch, SetStateAction } from "react"
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Svg } from "@hilma/forms";
import clsx from "clsx";

import { StyledTableCell } from './StyledTableCell';
import { StarSvg } from '../../../svg';


interface StarRatingProps {
    numStars: number;
    index?: number;
    onClick?(): void;
    starClass?: string;
    containerClass?: string;
    changeStarsRating?: Dispatch<SetStateAction<number>>;
}

export const StarRating: React.FC<StarRatingProps> = ({ numStars, index = 1, onClick, changeStarsRating, starClass, containerClass }) => {
    const classes = useStyles();

    const onClickStar = (star: React.MouseEvent) => {
        const starIndex = parseInt(star?.currentTarget.id)
        if (changeStarsRating) changeStarsRating(starIndex + 1);
    }

    return (
        <div className={clsx(classes.starsRating, containerClass)}>
            {[...Array(5)].map((_, index) =>
                <Svg key={index} color='' className={clsx(index < numStars ? classes.starSvg : classes.opaqueStarSvg, starClass)} svg={StarSvg} />
            )}
        </div>
    )

}

const useStyles = makeStyles(({
    starsRating: {
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        gap: 4
    },
    starSvg: {
        width: "2vw"
    },
    opaqueStarSvg: {
        width: "2vw",
        opacity: 0.2
    },
}));