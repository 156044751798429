import { useMemo, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useAsyncEffect } from '@hilma/tools';
import Tabs from '@material-ui/core/Tabs';
import axios from 'axios';

import { headerTabsArray, teal } from '../../constants';
import { useTopBarPathname } from '../../hooks';
import { TabTuple } from '../../types';

import TabLink from './TabLink';

const TopBarTabs: React.FC = () => {

    const [userTabs, setUserTabs] = useState<TabTuple[]>([]);

    const classes = useStyles();

    const pathName = useTopBarPathname();

    const index = useMemo(() => userTabs.findIndex(([_, path]: TabTuple) => path === pathName), [userTabs, pathName]);

    useAsyncEffect(async () => {
        let active = true;
        try {
            const { data } = await axios.get('/api/admin/hide-tabs');
            if (active) {
                setUserTabs(headerTabsArray(!data));
            }
        } catch (error: any) {
            console.error('error: ', error);
        }
        return () => { active = false };
    }, [])

    if (!userTabs.length) return null;

    return (
        <div>
            <Tabs className={classes.root} TabIndicatorProps={{ style: { backgroundColor: teal } }} value={index !== -1 && index}>
                {userTabs.map(([title, path]: TabTuple) => (
                    <TabLink pathName={path} tab={[title, '']} key={title + path} />
                ))}
            </Tabs>
        </div>
    );
}
export default TopBarTabs;

const useStyles = makeStyles({
    root: {
        height: 'fit-content',
        position: 'absolute',
        bottom: 0,
        fontWeight: 'bold'
    }
});