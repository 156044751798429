import { useMemo } from "react";

import { useGetSelectFilter, useSetTableFilter } from "../../hooks";
import { SelectDropdown } from "../../types";

import TableSelect from './TableSelect'

interface TableSelectDropdownProps {
	select: SelectDropdown;
	setDrawerOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const TableSelectDropdown: React.FC<TableSelectDropdownProps> = ({ select, setDrawerOpen }) => {

	const getSelectFilter = useGetSelectFilter();
	const setTableFilter = useSetTableFilter();

	const defaultValue = useMemo(() => getSelectFilter(select) || select.defaultValue, [select]);

	const onSelectClick = (option: any) => {
		setTableFilter(select.selectName, option);
		setDrawerOpen && setDrawerOpen(false);
	}

	return (
		<TableSelect select={{ ...select, defaultValue }} onSelect={onSelectClick} />
	)
};

export default TableSelectDropdown;