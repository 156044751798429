import { Route, Routes, Navigate } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";

import { adminTableRoutes } from "../../constants";
import { AdminTableTypes } from "../../types";

import AdminTables from "./AdminTables";
import Admin from "./Admin";


const Admins: React.FC = () => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <Routes>
                <Route key="add" path="/add" element={<Admin />} />
                {adminTableRoutes.map(type => (
                    <Route
                        key={`${type}-page`}
                        path={`/${type}/:adminId`}
                        element={<Admin key={`${type}-comp-page`} type={type} />}
                    />
                ))}
                {adminTableRoutes.map(type => (
                    <Route
                        key={`${type}-table`}
                        path={`/${type}`}
                        element={<AdminTables key={`${type}-comp-table`} type={type} />}
                    />
                ))}
                <Route path="/*" element={<Navigate to={`/admins/${AdminTableTypes.Approved}`} replace />} />
            </Routes>
        </div>
    );
}

export default Admins;


const useStyles = makeStyles({
    container: {
        margin: "1rem min(2.5rem , 5vw)",
        flexGrow: 1,
        marginTop: 0,

        display: "flex",
        flexDirection: "column"
    }
});