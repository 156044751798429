import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { darkGray } from '../constants/colors';

interface DateDetailsProps {
    created?: string;
    approved?: string;
    title?: string;
};

export const DateDetails: React.FC<DateDetailsProps> = ({ created, approved, title }) => {

    const classes = useStyles();

    const createDate = useMemo(() => created && (new Date(created).toLocaleDateString('he-IL')), [created]);
    const approvalDate = useMemo(() => approved && (new Date(approved).toLocaleDateString('he-IL')), [approved]);

    return (
        <div className={classes.container}>
            {approvalDate && <div>{`${title} אושר ב-${approvalDate}`}</div>}
            {createDate && <div>{`${title} נרשם ב-${createDate}`}</div>}
        </div>
    )
};

const useStyles = makeStyles(({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        fontFamily: 'Assistant-Regular',
        color: darkGray,
        gap: 5,
        flex: 1,
    }
}));