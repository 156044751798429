import { CandidateEducation } from "../types";

export const candidateEducations: Record<CandidateEducation, string> = {
    [CandidateEducation.HighSchool]: 'תיכונית',
    [CandidateEducation.AfterHighSchool]: 'על תיכונית',
    [CandidateEducation.TeachingCertificate]: 'תעודת הוראה',
    [CandidateEducation.FirstDegree]: 'תואר ראשון',
    [CandidateEducation.SecondDegree]: 'תואר שני',
    [CandidateEducation.ThirdDegree]: 'תואר שלישי',
}


export const candidateEducationsArray: { id: string; value: string }[] =
    Object.keys(candidateEducations).map((key) => ({ id: key, value: candidateEducations[key as CandidateEducation] }))
