import { requestStartPassed } from "./requestStartPassed";
import { RequestStatus, RequestTable } from "../types";
import { purple, red, teal } from "../constants";

export const getSchoolRequestChipProps = (request: RequestTable) => {
	if (request.status === RequestStatus.Deleted) return { color: red, label: 'נמחק' };
	else if (requestStartPassed(request.date, request.startTime)) return { color: purple, label: 'ארכיון' };
	else if (request.status === RequestStatus.Closed) return { color: red, label: 'סגור', };
	// else if (request.status === RequestStatus.Deleted) return { color: red, label: 'נמחק' };
	return { color: teal, label: 'פתוח' };
}