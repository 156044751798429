//components
import { StyledTableCell } from './StyledTableCell';

interface IconCellProps {
    hasIcon: boolean;
    index: number;
    icon?: [string, string] | JSX.Element;
    onClick?():void;
}

export const IconCell: React.FC<IconCellProps> = ({ hasIcon, index, icon, onClick }) => {
    return (
        <StyledTableCell key={index} onClick={onClick}>
            {icon && ('length' in icon && icon.length === 2) && hasIcon ? <img height='20px' src={icon[0]} alt={icon[1]} /> : (icon && hasIcon) ? icon : null}
        </StyledTableCell>
    )
};