import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
//components
import { StyledTableCell } from './StyledTableCell';

interface BoldSearchCellProps {
    index: number;
    text: string;
    searchText?: string;
    onClick?(): void;
    title?: string | JSX.Element;
}

export const BoldSearchCell: React.FC<BoldSearchCellProps> = ({ text, searchText, onClick, index, title }) => {

    const classes = useStyles();

    const parts = useMemo(() => {
        if (!searchText) return;

        const matches: Array<[number, number]> = [];
        text = text.toString();
        const inputValueIndex = text.toLowerCase().indexOf(searchText.toLowerCase());

        if (inputValueIndex !== -1) {
            matches.push([inputValueIndex, inputValueIndex + searchText.length]);
        }

        return parse(text.toString(), matches);
    }, [text, searchText]);

    return (
        <StyledTableCell onClick={onClick} key={index}>
            {!!title && <span className={classes.title}>
                {title}
            </span>}

            {parts ? parts?.map((part: { text: string; highlight: boolean }, index: number) => (
                <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                </span>
            )) : text}
        </StyledTableCell>
    )
}

const useStyles = makeStyles({
    title: {
        fontFamily: 'Assistant-Bold',
        marginRight: 2
    }
});