import { useState, useMemo, useEffect, useCallback } from 'react';
import { useForm, useFormRefs, throttleFetch, FormHelperText, useFormInputStyles, FormInput, useTextFieldError, useAlert, useGetError } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import parse from 'autosuggest-highlight/parse';
import Input from '@material-ui/core/Input';
import axios from 'axios';
import clsx from 'clsx';

import AdminSchool, { AdminSchoolProps } from '../routes/schools/AdminSchool';
import { teal, useFormStyles } from '../constants';
import { AdminTable } from '../types';


const fetch = throttleFetch(200);

interface MultipleSelectAutoCompleteProps {
	renderOptionProps?: (value: AdminTable) => Partial<AdminSchoolProps>;
	placeHolder: string;
	isLoading?: boolean;
	showEdit?: boolean;
	showStar?: boolean;
	fetchUrl: string;
	id: string;
	schoolId?: string;
}

export const MultipleSelectAutoComplete: React.FC<MultipleSelectAutoCompleteProps> = ({ isLoading, fetchUrl, id, placeHolder, showStar, showEdit, renderOptionProps, schoolId }) => {

	const [value, setValue] = useState<AdminTable | null>(null);
	const [selectOptions, setSelectOptions] = useState<AdminTable[]>([]);
	const [inputValue, setInputValue] = useState('');

	const formInputClasses = useFormInputStyles();
	const formClasses = useFormStyles();
	const error = useTextFieldError(id);
	const innerRef = useFormRefs();
	const form = useForm();
	const classes = useStyles();
	const alert = useAlert();
	const getError = useGetError();


	const onChange = useCallback(
		(_event: React.ChangeEvent<{}>, newValue: AdminTable | null) => {
			if (!newValue) return;
			// setValue(newValue);
			if (!form.values[id]?.length)
				form.setFieldValue('mainId', newValue.id);
			form.setFieldValue(id, form.values[id]?.length ? [...form.values[id], newValue] : [newValue]);

		}, [setValue, setSelectOptions, form]);

	const onInputChange = useCallback(
		(event, newInputValue) => {
			if (!event) return;
			if (event.type === "click" || event.type === "onKeyDown")
				return (setInputValue(''), setValue(null));
			if (event.type === 'blur')
				return setInputValue('');
			setInputValue(newInputValue);
		}, [setInputValue, setValue, form]);

	const removeValue = (valueId: string) => {
		const values = form.values[id] || [];
		form.setFieldValue(id, values.filter((v: any) => v.id !== valueId));
	}

	const changeStar = (valueId: string) => {
		form.setFieldValue('mainId', valueId);
	}

	useEffect(() => {
		let active = true;
		if (isLoading) return;
		fetch(inputValue, async (inputValue: string) => {
			try {
				const { data } = await axios.get(fetchUrl, { params: { search: inputValue } });
				if (!active) return;
				setSelectOptions(data);
			} catch (err: any) {
				alert(getError(err))
				console.error(err)
			}
		});
		return () => { active = false; };
	}, [inputValue, value, isLoading, fetchUrl]);

	if (isLoading) return <FormInput id={id} value={placeHolder} description='' disabled={true} />

	return (
		<>
			{form.values[id]?.map((a: AdminTable, index: number) => {
				if (!a?.id) return;
				const props = renderOptionProps ? renderOptionProps(a) : {};
				return (
					<AdminSchool
						schoolId={schoolId}
						option={a}
						key={index}
						showEdit={!!showEdit}
						showStar={!!showStar}
						onRemove={() => { removeValue(a.id) }}
						changeStar={() => { changeStar(a.id) }}
						{...props}
					/>)
			})}
			<Autocomplete
				id={id}
				autoComplete
				value={value}
				handleHomeEndKeys
				onChange={onChange}
				options={selectOptions}
				noOptionsText="אין אפשרויות"
				innerRef={() => innerRef}
				classes={{ option: classes.color, noOptions: classes.color, }}
				onInputChange={onInputChange}
				getOptionSelected={(option: AdminTable, value: AdminTable) => option.id === value.id}
				filterOptions={(options: AdminTable[]) => options?.filter(o => form.values[id]?.findIndex((a: AdminTable) => a?.id == o?.id) < 0)}
				getOptionLabel={(option) => option.name}
				renderInput={(params) =>
					<div className={classes.container}>
						<Input
							type="text"
							disableUnderline
							{...params.InputProps}
							inputProps={{ ...params.inputProps }}
							placeholder={placeHolder}
							className={clsx(formInputClasses.input, classes.input, error && formClasses.inputError)}
						/>
						<FormHelperText text={error} error />
					</div>}
				renderOption={(option, state) => {

					const matches: Array<[number, number]> = [];
					const searchValue = state.inputValue.trim();
					const inputValueIndex = option?.name.toLowerCase().indexOf(searchValue.toLowerCase());

					if (inputValueIndex !== -1) {
						matches.push([inputValueIndex, inputValueIndex + searchValue.length]);
					}

					const parts = parse(option?.name.toString(), matches);

					return (
						<div>
							{parts ?
								parts?.map((part: { text: string; highlight: boolean }, index: number) => (
									<span key={index} style={{ fontWeight: part.highlight ? 700 : 400, direction: 'rtl' }}>
										{part.text}
									</span>
								))
								: <div>{option?.name}</div>
							}
						</div>
					);
				}}
			/>
		</>
	);
};

const useStyles = makeStyles(theme => ({
	color: {
		color: teal,
	},
	container: {
		marginTop: 10,
	},
	inputContainer: {
	},
	input: {
		width: '100%'
	},
}))