import { createRules, fullNameRegex, activePhoneRegex, activeEmailRegex } from "@hilma/forms"
import { isEmail, isEnum, isMobilePhone, length } from 'class-validator';

import { UserStatus, DaysOfWeek, Sector, CandidateEducation } from "../../types";

export const candidateRules = createRules({
    name: { required: true, regex: fullNameRegex, greaterThan: 2, smallerThan: 45 },
    phone: { activeRegex: activePhoneRegex, required: true, classValidator: (value: string) => isMobilePhone(value, 'he-IL') && (new RegExp(/^\d+/)).test(value) && length(value, 10, 10) },
    email: { required: true, activeRegex: activeEmailRegex, classValidator: (value: string) => isEmail(value) },
    heAddress: {
        required: true, classValidator: (heAddress: { description: string } | string) => {
            if (typeof heAddress != 'string')
                heAddress = heAddress.description
            return !!heAddress;
        }
    },
    city: {
        required: true, classValidator: (name: { heCityName: string } | string) => {
            if (typeof name != 'string')
                name = name.heCityName
            return !!name;
        }
    },
    radius: { required: true, classValidator: (radius: number) => radius <= 100, activeRegexErr: 'מוגבל עד ל100 קמ' },
    candidateWorkDay: { required: true, activeRegexErr: 'הינו חובה', classValidator: (days) => Array.isArray(days) && days.length && days.every(day => isEnum(Number(day), DaysOfWeek)) },
    candidatePrefSector: { required: true, activeRegexErr: 'הינו חובה', classValidator: (sectors) => Array.isArray(sectors) && sectors.length && sectors.every(sector => isEnum(sector, Sector)) },
    candidatePrefEducation: { required: true, activeRegexErr: 'הינו חובה', classValidator: (educations) => Array.isArray(educations) && educations.length && educations.every(education => isEnum(education, CandidateEducation)) },
    candidatePrefSubject: { required: true, activeRegexErr: 'הינו חובה', classValidator: (subjects) => Array.isArray(subjects) && subjects.length },
    candidatePrefGrade: { required: true, activeRegexErr: 'הינו חובה', classValidator: (grades) => Array.isArray(grades) && grades.length },
    status: { required: true, classValidator: (status) => isEnum(status, UserStatus) },
    // education: { required: true, classValidator: (education: Option) => isEnum(education.id, CandidateEducation) },
    image: { required: false },
    policeCheck: { required: true, accept: ['application/pdf'] },
    experience: {required: true, accept: ['application/pdf'] },
    cv: { required: true, accept: ['application/pdf'] },
    diploma: { required: false, accept: ['application/pdf'] },
    recommendation: { required: false, accept: ['application/pdf'] },
    cvRead: { required: true },
    policeCheckRead: { required: true },
});
