import React, { useEffect, useState } from 'react'
import { FormProvider, GoogleMapsProvider, useAlert, useForm, useUpdateOnSubmit, } from '@hilma/forms';
import { useNavigate, useLocation, useParams } from 'react-router';
import { useAsyncEffect } from '@hilma/tools';
import { useUser } from '@hilma/auth';
import axios from 'axios';

import { Admin, CandidateFormData, CandidateTableTypes, UserStatus } from '../../types';
import { CandidateDetailsTopBar, TitleAndBackArrow } from '../../componenets';
import { errors, candidateRules } from '../../constants';
import { getAlertError } from '../../functions';
import { FormStyled } from '../../general';

import CandidateArchivesRequests from './CandidateArchivesRequests';
import CandidateDetails from './CandidateDetails';

interface CandidateProps {
    type?: string;
}

const requiredValues = ["name", "phone", "email", "heAddress", "city", "radius", "candidateWorkDay", "candidatePrefSector", "candidatePrefSubject", "candidatePrefGrade", "education", "cv", "status"];

const initialValues = {
    name: '',
    phone: '',
    email: '',
    heAddress: '',
    radius: '',
    city: null,
    education: '',
    candidatePrefSector: [],
    candidateWorkDay: [],
    candidatePrefSubject: [],
    candidatePrefGrade: [],
    status: '',
    cv: null,
    cvPath: '',
    policeCheck: null,
    policeCheckPath: '',
    experience: null,
    gender:null,
    hasExperience: null,
    experiencePath: '',
    recommendation: null,
    recommendationPath: '',
    diploma: null,
    diplomaPath: '',
    image: null,
    imagePath: '',
    cvRead: false,
    policeCheckRead: false,
    requiredValues: [],
    imageFile: null,
};


const Candidate: React.FC<CandidateProps> = ({ type }) => {

    const paramId = useParams<{ id: string }>();
    const [loading, setLoading] = useState<boolean>(false);
    const [subjects, setSubjects] = useState<[]>([]);
    const [from, setFrom] = useState<string>('')
    const [tab, setTab] = useState(0);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useUser<Admin>();
    const alert = useAlert();
    const form = useForm();

    useEffect(() => { location.state && setFrom((location.state as { from: string }).from); }, [location]);

    useAsyncEffect(async () => {
        try {
            setLoading(true);
            const { data } = await axios.get<CandidateFormData>(`/api/candidate/get-one-candidate/${paramId.id}`);
            form.setValues(data);
        } catch (error: any) {
            console.error('error: ', error);
            alert(errors.getDataError);
        } finally {
            setLoading(false);
        }
    }, [])

    useAsyncEffect(async () => {
        const { data } = await axios.get('/api/subject/get-subjects-as-options');
        setSubjects(data)
    }, []);

    // const deleteCandidate = async () => {
    //     try {
    //         setLoading(true);
    //         await axios.delete(`/api/candidate/${paramId.id}`)
    //         alert("המשתמש נמחק בהצלחה", 'success')
    //         navigate(from !== '' ? from : `/candidates/${type || CandidateTableTypes.Pending}`, { replace: true })
    //         setLoading(false);
    //     } catch (err) {
    //         alert(errors.generalError)
    //         setLoading(false);
    //     }
    // }//uncomment for delete candidate

    const updateCandidate = async () => {
        try {
            setLoading(true);
            if (form.values.imageFile instanceof File)
                delete form.values.image;
            await axios.post(`/api/candidate/update/${paramId.id}`, {
                ...form.values,
                files: true,
                cityId: form.values?.city?.id,
                // education: form.values?.education?.id,
                heAddress: form.values.heAddress.description ?? form.values.heAddress,
            })
            alert(errors.candidateSaveSuccess, 'success');
            navigate(`/candidates/${type || CandidateTableTypes.Pending}`, { replace: true })
        } catch (err: any) {
            console.error('err: ', err);
            alert(getAlertError(err));
            if (err.status === 409) form.setFieldError('phone', errors.phoneExists);
            if (err.status === 422) form.setFieldError('heAddress', errors.googleMapsStation.israelLoc);
            setLoading(false);
        }
    }

    const handleSave = async () => {
        if (loading) return;
        form.setFieldValue('requiredValues', [...requiredValues, ...(form.values?.status === UserStatus.Approved ? ((form.values?.policeCheckPath || form.values?.policeCheck) ? ['cvRead', 'policeCheckRead'] : ['cvRead']) : [])]);
        form.handleSubmit();
    }

    useUpdateOnSubmit(updateCandidate);

    return (
        <>
            <TitleAndBackArrow
                title={form?.values?.name}
                onClick={() => navigate(from !== '' ? from : `/candidates/${type || CandidateTableTypes.Pending}`)}
            // endComponent={<StarRating numStars={form?.values?.rating} index={1} />} uncomment for reviews
            />
            <FormStyled>
                <CandidateDetailsTopBar tab={tab} setTab={setTab} />
                {tab ?
                    <CandidateArchivesRequests /> :
                    <CandidateDetails
                        loading={loading}
                        subjects={subjects}
                        handleSave={handleSave}
                        noEdit={!Number(user?.isAdmin)}
                        userId={paramId.id}
                    />
                }
            </FormStyled>
        </>
    )
}
const CandidateComp: React.FC<CandidateProps> = (props) => (
    <FormProvider initialValues={initialValues} rules={candidateRules}>
        <GoogleMapsProvider>
            <Candidate {...props} />
        </GoogleMapsProvider>
    </FormProvider>
)
export default CandidateComp;