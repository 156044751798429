import { createContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { wrap } from '@hilma/tools';

import { TabsDictionary, TabTuple } from '../types';
import { subTabsDictionary } from '../constants';

export const TopBarCurrentValueContext = createContext<number>(0);
export const TopBarPathnameContext = createContext<string | number>('');
export const TopBarTabsArrayContext = createContext<TabTuple[]>([])

export const TopBarProvider: React.FC = ({ children }) => {

	const location = useLocation();
	const pathname = useMemo(() => location.pathname.split('/')[1] as keyof TabsDictionary, [location.pathname]);
	// At times, the tabs may change and display different options/ sub routes- which are stored in the great and mighty tabsDictionary!!!  📘
	const tabsArray = useMemo<TabTuple[]>(() => (subTabsDictionary[pathname] || []), [pathname]);

	// Determine whether and which tab is active
	const currentValue = useMemo(() => {
		const subPathName = location.pathname.split('/')[2];
		const idx = tabsArray.findIndex(elm => elm[1] === subPathName);
		return idx < 0 ? 0 : idx;
	}, [location.pathname]);

	return (
		<TopBarCurrentValueContext.Provider value={currentValue}>
			<TopBarTabsArrayContext.Provider value={tabsArray}>
				<TopBarPathnameContext.Provider value={pathname}>
					{children}
				</TopBarPathnameContext.Provider>
			</TopBarTabsArrayContext.Provider>
		</TopBarCurrentValueContext.Provider>
	);
}