import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation } from 'react-router-dom';
import Tabs from '@material-ui/core/Tabs';
import clsx from 'clsx';

import { useTopBarPathname, useTopBarCurrentValue, useTopBarTabsArray } from '../../hooks';
import { boxShadow, teal, subTopBarGray, headerTabs } from '../../constants';
import { TabTuple } from '../../types';

import TabLink from './TabLink';

const SubTopBar: React.FC = () => {

	const currentValue = useTopBarCurrentValue();
	const tabsArray = useTopBarTabsArray();
	const pathname = useTopBarPathname();
	const location = useLocation();
	const classes = useStyles();

	const notExact = useMemo(() => //when not in tables sub title is hid
		!tabsArray.length ||
		location?.pathname?.split('/')?.slice(1)?.join('/') !== `${pathname}/${tabsArray[currentValue][1]}`
		, [location.pathname, pathname, tabsArray, currentValue]);

	if (!tabsArray.length || headerTabs[pathname]?.hideSubBar || notExact) return <div className={classes.margin} />;

	return (
		<div className={clsx(classes.container, classes.margin)}>
			<Tabs TabIndicatorProps={{ style: { backgroundColor: teal } }} value={currentValue}>
				{tabsArray.map((elm: TabTuple) => (
					<TabLink pathName={pathname as string} tab={elm} key={elm[0]} subTopBar />
				))}
			</Tabs>
		</div>
	)
};

export default SubTopBar;

const useStyles = makeStyles(theme => ({
	container: {
		boxShadow: `0 3px 6px ${boxShadow}`,
		background: subTopBarGray,
		paddingLeft: '125px',
		alignItems: 'flex-end',
		display: 'flex',
		marginBottom: 20,
		[theme.breakpoints.down(800)]: {
			paddingLeft: 0,
			'&>div': {
				margin: '0 auto'
			}
		}
	},
	margin: {
		height: 50,
	}
}));