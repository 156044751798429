import { FormAutocomplete, FormAutocompleteProps, FormInput, Option, useAlert, useForm, useGetError } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import { Subject } from '../types';

export interface CitiesAutocompleteProps {
	id: string;
	description: string;
	noOptionsText?: string;
	loading?: boolean;
	classes?: FormAutocompleteProps<Subject>['classes'];
	disabled?: boolean;
}

const CitiesAutocomplete: React.FC<CitiesAutocompleteProps> = ({ id, description, noOptionsText, loading, classes, disabled }) => {

	const form = useForm();
	const alert = useAlert();
	const getError = useGetError();

	const fetchPredictions = async (input: string) => {
		try {
			const { data } = await axios.get<Subject[]>('/api/subject/get-subject-autocomplete', { params: { input } });
			return data || [];
		} catch (err:any) {
			console.error(err);
			alert(getError(err));
			return [];
		}
	}

	if (loading) return <FormInput id={id} description={description} loading={true} />

	return (
		<FormAutocomplete
			disabled={disabled}
			id={id}
			classes={classes}
			description={description}
			noOptionsText={noOptionsText || "הכנס מקצוע"}
			initialValue={form.values[id]}
			fetchFunction={fetchPredictions}
			getOptionSelected={(option: Subject, value: Subject) => value.id === option.id}
			getOptionLabel={(option) => (typeof option === 'string' ? option : option.heName)}
			renderOption={(option, state) => {
				const matches: Array<[number, number]> = [];
				const searchValue = state.inputValue.trim();
				const inputValueIndex = option?.heName?.toLowerCase().indexOf(searchValue?.toLowerCase());

				if (inputValueIndex !== -1) {
					matches.push([inputValueIndex, inputValueIndex + searchValue.length]);
				}
				const parts = parse(option?.heName?.toString(), matches);

				return (
					//TODO: align to right
					<Grid item>
						{parts.map((part: { text: string; highlight: boolean }, index: number) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
								{part.text}
							</span>
						))}
					</Grid>
				);
			}}
		/>
	);
}

export default CitiesAutocomplete

const useStyles = makeStyles(theme => ({

}));
