import { FormAutocomplete, FormAutocompleteProps, FormInput, useAlert, useForm, useGetError } from '@hilma/forms';
import makeStyles from '@material-ui/core/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import { City } from '../types';

export interface CitiesAutocompleteProps {
    id: string;
    description: string;
    noOptionsText?: string;
    loading?: boolean;
    classes?: FormAutocompleteProps<City>['classes'];
    disabled?: boolean;
}

const CitiesAutocomplete: React.FC<CitiesAutocompleteProps> = ({ id, description, noOptionsText, loading, classes, disabled }) => {

    const innerClasses = useStyles();
    const form = useForm();
    const alert = useAlert();
    const getError = useGetError();

    const fetchPredictions = async (input: string) => {
        if (!input) return [];
        try {
            const { data } = await axios.get<City[]>('/api/city/get-cities-autocomplete', { params: { input } });
            return data || [];
        } catch (err: any) {
            console.error(err);
            alert(getError(err));
            return [];
        }
    }

    if (loading) return <FormInput id={id} description={description} loading={true} />

    return (
        <FormAutocomplete
            disabled={disabled}
            id={id}
            classes={classes}
            description={description}
            noOptionsText={noOptionsText || "הכנס ישוב"}
            initialValue={form.values[id]}
            fetchFunction={fetchPredictions}
            getOptionSelected={(option: City, value: City) => value.id === option.id}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.heCityName)}
            renderOption={(option, state) => {
                const matches: Array<[number, number]> = [];
                const searchValue = state.inputValue.trim();
                const inputValueIndex = option?.heCityName?.toLowerCase().indexOf(searchValue?.toLowerCase());

                if (inputValueIndex !== -1) {
                    matches.push([inputValueIndex, inputValueIndex + searchValue.length]);
                }
                const parts = parse(option?.heCityName?.toString(), matches);

                return (
                    //TODO: align to right
                    <Grid item>
                        {parts.map((part: { text: string; highlight: boolean }, index: number) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </Grid>
                );
            }}
        />
    );
}

export default CitiesAutocomplete

const useStyles = makeStyles(theme => ({

}));
