import { useMemo } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'react-router-dom';


import { useTableArrowOnClickLabel } from '../../../hooks';
import { CandidateJob, TableRow } from '../../../types';

import { StyledTableCell } from './StyledTableCell';
import { ArrowCellButton } from './ArrowCellButton';

interface ArrowCellProps {
    row: TableRow;
    index: number;
    arrowOnClick?(row: {}, index: number): void;
    to?(row: {}, index: number): string;
    div?: boolean;
}

export const ArrowCell: React.FC<ArrowCellProps> = ({ row, index, arrowOnClick, to, div = false }) => {

    const arrowOnClickLabel = useTableArrowOnClickLabel();
    const classes = useStyles();

    const toLink = useMemo(() => {
        return to && to(row, index);
    }, [to, row, index]);

    return (
        to && toLink ? (
            <StyledTableCell component={div ? 'div' : 'td'} align={arrowOnClickLabel ? 'center' : 'right'}>
                <Link to={toLink} className={classes.link}>
                    <ArrowCellButton label={arrowOnClickLabel} />
                </Link>
            </StyledTableCell >
        ) : (
            <StyledTableCell component={div ? 'div' : 'td'} align={arrowOnClickLabel ? 'center' : 'right'}>
                <ArrowCellButton label={arrowOnClickLabel} disabled={(row.acceptance || arrowOnClickLabel === 'שיבוץ מחליפה') && row.acceptance !== CandidateJob.Approved ? true : false} onClick={() => arrowOnClick && arrowOnClick(row, index)} />
            </StyledTableCell >
        )
    )
}

const useStyles = makeStyles({
    link: {
        textDecoration: 'none'
    },
});