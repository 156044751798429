import { RequestTableTypes, SelectDropdownMenu } from "../types";

export const requestTableRoutes: RequestTableTypes[] = [
	RequestTableTypes.Pending,
	RequestTableTypes.Closed,
	RequestTableTypes.Archive,
];

export const requestPageRoutes: RequestTableTypes[] = [
	RequestTableTypes.Pending,
	RequestTableTypes.Closed,
	RequestTableTypes.Archive,
];

export const requestTypes: SelectDropdownMenu[] = [
	{ value: RequestTableTypes.Pending, label: 'קריאות פתוחות' },
	{ value: RequestTableTypes.Closed, label: 'קריאות סגורות' },
	{ value: RequestTableTypes.Archive, label: 'ארכיון קריאות' },
];