import { FC, useState } from "react";
import { BottomStyled, Button, ConfirmPopup } from "../general"
import { FormDate, FormInput, FormSelect, FormTimeRange, GridifyChildren, Option, useAlert, useForm } from "@hilma/forms"
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from "@material-ui/styles";
import MuiButton from '@material-ui/core/Button';
import { useNavigate } from "react-router";
import axios from "axios";

import { useFormStyles } from "../constants";
import { Admin, RequestTableTypes } from "../types"
import { SchoolSelect, SubjectSelect } from ".";
import { useAsyncEffect } from '@hilma/tools';
import { FormikValues } from 'formik';
import { sectorsObject } from '../constants/sectors';
import { Sector } from '../types';
import { useUser } from "@hilma/auth-core";

interface RequestFormProps {
    requestId?: string,
    loadingMaps: boolean,
    gradesArray: Option[],
    sectorsArray: Option[],
    onSubmit: () => Promise<void>,
    type?: RequestTableTypes
    loading: boolean
    disabled: boolean
}

const today = new Date()

const RequestForm: FC<RequestFormProps> = ({ requestId, loadingMaps, gradesArray, sectorsArray, onSubmit, type, loading, disabled }) => {

    const classes = useStyles();
    const formClasses = useFormStyles();
    const navigate = useNavigate();
    const alert = useAlert();
    const [deleteRequestPopup, setDeleteRequestPopup] = useState<boolean>(false);
    const form = useForm();
    // const disabled: boolean = type === RequestTableTypes.Pending ? false : true;
    const user = useUser<Admin>();


    const deleteRequest = async () => {

        try {

            await axios.post(`/api/request/delete-a-request/${requestId}`)
            alert("הקריאה נמחקה בהצלחה", 'success')
            navigate('../')

        } catch (err: any) {
            console.error(err);

            alert(err?.status === 422 ? "לא ניתן למחוק בקשות מהעבר" : "הייתה תקלה במרכת", 'error')
        }
    }

    useAsyncEffect(async () => {

        if (type /* && [RequestTableTypes.Archive, RequestTableTypes.Closed].includes(type) */ || !form.values.schoolName?.id /* && !user?.schoolId */) return;

        try {

            const { data } = await axios.get(`/api/school/get-all-school-info/${form.values.schoolName?.id /* ?? user?.schoolId */}/${requestId}`)

            const { phone, sector } = data;

            form.setValues((prev: FormikValues) => ({
                ...prev,
                phone,
                sector: { id: sector, value: sectorsObject[sector as Sector] },
                // ...(user?.schoolId ? { schoolName: { id: data.id, name: data.name } } : {})
            }))

        } catch (err) {
            alert('ארעה שגיאה');

        }
    }, [form?.values?.schoolName?.id, /* user?.schoolId */])

    return (
        <>
            <div className={classes.formContainer}>

                <GridifyChildren>
                    <SchoolSelect disabled={disabled} id='schoolName' description='שם בית הספר' loading={loading} />
                    <FormInput disabled={disabled} id="phone" description={"מספר טלפון"} />

                    <FormDate disabled={disabled} minDate={today} id="date" description={"תאריך מילוי מקום"} />

                    <FormTimeRange id='time' dir='rtl' description='שעות' disabled={disabled} />

                    <FormSelect id='grade' description='כיתות' options={gradesArray} disabled={disabled} />
                    <SubjectSelect id='subject' loading={loading} description='מקצוע' disabled={disabled} />
                    {/* <FormSelect id='sector' description='מגזר' options={sectorsArray} disabled={disabled} /> */}

                    <br />

                    <FormInput disabled={disabled} id='note' description={"הערות"} multiline rows={7} inputProps={{ maxLength: 2000 }} />
                    <FormInput disabled={disabled} id='note' description={"הערות"} multiline rows={7} inputProps={{ maxLength: 2000 }} />

                </GridifyChildren>
            </div>
            {type !== RequestTableTypes.Closed ?
                <div className={classes.button}>
                    <BottomStyled>
                        {requestId && type !== RequestTableTypes.Archive &&
                            <MuiButton variant="outlined"
                                className={formClasses.delete}
                                onClick={() => setDeleteRequestPopup(true)} >
                                <DeleteIcon />
                                מחק לצמיתות
                            </MuiButton>}
                        {!requestId && <Button
                            onClick={onSubmit}
                            loading={loading}
                            children="הוספה"
                        // {!requestId ?"הוספה" : disabled ? "עריכה" : "שמירת שינויים"} * uncomment to enable editing option *
                        />}
                    </BottomStyled>
                </div> : null}
            <ConfirmPopup
                open={deleteRequestPopup}
                title="מחיקת בקשה"
                text={<div>
                    האם אתה בטוח שברצונך למחוק את הבקשה
                    <strong> לצמיתות</strong>
                    ?</div>}
                onClose={() => setDeleteRequestPopup(false)}
                onConfirm={deleteRequest}
                confirmText="מחיקה"
            />
        </>
    )
}
export default RequestForm

export const useStyles = makeStyles({
    formContainer: {
        boxShadow: 'none',
        display: 'flex',
        flexDirection: 'column',
    },
    stuff: {
        background: 'inherit', // this is here to stay, get over it ~06/03/2022 Ayelet Queen of css~
        '&>div': {
            boxSizing: 'border-box'
        },
        '&>svg': {
            top: '-2px',
            transform: 'scale(0.8)'
        },
        '&>.MuiSelect-select.Mui-disabled': {
            background: "#f5f5f5"
        }
    },
    note: {
        color: '#35788C',
        border: '0.5px solid #3E504C',
        display: 'flex',
        marginTop: '5px',
        transition: 'background-color 0.5s ease',
        fontFamily: 'Assistant-Regular',
        borderRadius: '5px',
        backgroundColor: 'transparent',
        padding: '5px 7px',
    },
    time: {
        display: 'flex',
        '&>#time': {
            textAlign: 'end',
            paddingLeft: '7px',
            margin: 'auto',
            fontSize: '0.95rem'
        },
    },
    button: {
        marginBottom: 30
    },
})