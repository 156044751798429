import { useMemo } from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Option, useForm } from "@hilma/forms";
import clsx from 'clsx';

import { ChipItem } from "..";

export interface ChipsProps {
    id: string;
    items: Option[];
    disabled?: boolean;
    multiple?: boolean;
    classes?: { container?: string, chip?: string };
}

const Chips: React.FC<ChipsProps> = ({ id, items, multiple, disabled, classes: chipClasses }) => {

    const classes = useStyles();
    const form = useForm()
    const selectedItems = useMemo(() => multiple ? form.values[id]?.map((val: any) => val.toString()) : form.values[id], [form.values[id]]);

    const onClick = (id: number | string) => {
        if (!multiple) {
            changeSelectedItem(id);
            return
        }

        const itemIndex = selectedItems.findIndex((item: any) => item === id)
        const newArray = JSON.parse(JSON.stringify(selectedItems));
        if (itemIndex !== -1) newArray.splice(itemIndex, 1)
        else newArray.push(id)
        onChange(newArray)
    }

    const changeSelectedItem = (id: number | string) => {
        onChange(id)
    }

    const onChange = async (value: (string | number) | (string | number)[]) => {
        await form.setFieldTouched(id, true)
        form.setFieldValue(id, value)
    }

    return (
        <div className={clsx(classes.container, chipClasses?.container)} >
            {items.map((item, i) =>
                <ChipItem key={i} value={item.value} onClick={() => onClick(item.id)} selected={multiple ? selectedItems.includes(item.id) : selectedItems === item.id} disabled={disabled} className={chipClasses?.chip} />
            )}
        </div>
    );
}

export default Chips

export const useStyles = makeStyles(theme => ({

    container: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    }
}));