import { AuthProvider, HomeRoute, PublicOnlyRoute, useAuth, UserProvider } from "@hilma/auth";
import { I18nProvider as I18nP, Alert, AlertProvider, useFilesUpload, GoogleMapsProvider } from '@hilma/forms';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { provide } from "@hilma/tools";

import { Home, Login, ResetPassword, SchoolSignUp, TermsAndConditions } from "./routes";
import { AccessibilityLink, Accessibility } from "./routes";
import { I18nProvider, muiTheme } from './constants';


import './stylesheets';
import { SM } from "./routes/SM";
import { useEffect, useState } from "react";
import InstallPopup from "./componenets/installpopup/InstallPopup";


const App: React.FC = () => {
  const [installPrompt, setInstallPrompt] = useState<Event | null>(null);

  // Event handler for the install prompt
  const handleInstallClick = () => {
    if (installPrompt instanceof Event) {
      const installEvent = installPrompt as any;
      installEvent.prompt();
      installEvent.userChoice.then((choiceResult: { outcome: string }) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");
        } else {
          console.log("User dismissed the A2HS prompt");
        }
        setInstallPrompt(null);
      });
    }
  };

  // Event handler for closing the InstallPopup
  const handleCloseClick = () => {
    setInstallPrompt(null);
  };

  // Effect to listen for the 'beforeinstallprompt' event
  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      setInstallPrompt(event);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);


  useFilesUpload();

  const auth = useAuth()

  return (
    <AuthProvider logoutOnUnauthorized={true} accessTokenCookie='kloklokl' >
      <UserProvider url='/api/admin/user-details'>
        <I18nProvider router={false}>
          <div className="App" dir='rtl' >
            <Routes>
              <Route
                path="/sm/:address"
                element={<SM />}
              />
              <Route
                path="/login"
                element={<PublicOnlyRoute component={Login} componentName="Login" />}
              />
              <Route
                path="/reset-password/*"
                element={<PublicOnlyRoute component={ResetPassword} componentName="ResetPassword" />}
              />
              <Route
                path="/first-login/*"
                element={<PublicOnlyRoute component={ResetPassword} componentName="ResetPassword" />}
              />
              <Route path='school-sign-up' element={<SchoolSignUp />} />
              <Route
                path="/terms-and-conditions/*"
                element={<TermsAndConditions />}
              />
              <Route
                path="/*"
                element={<HomeRoute components={{ Home }} redirectPath="/login" />}
              />
              <Route
                path="/accessibility/*"
                element={<Accessibility />}
              />
            </Routes>
            <AccessibilityLink />
            <Alert />
            {/* Use the InstallPopup component */}
            <InstallPopup
          installPrompt={installPrompt}
          onInstallClick={handleInstallClick}
          onCloseClick={handleCloseClick}
        />
      </div>
        </I18nProvider>
      </UserProvider>
    </AuthProvider>
  );
}

export default provide(
  Router,
  GoogleMapsProvider,
  AlertProvider,
  [I18nP, { router: false, muiPalette: muiTheme }],
  // [AuthProvider, { logoutOnUnauthorized: true, accessTokenCookie: 'kloklokl' }],
  // [UserProvider, { url: '/api/admin/user-details' }],
  // [I18nProvider, { router: false }],
)(App);