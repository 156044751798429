import { ChartData, ChartOptions } from "chart.js";

import { statisticsData } from "../types";
import { daysOfWeekArray } from "./daysOfWeek";
import { gradesArray } from "./grades";

export const statisticsComponentsAuthority = ({ requestsBarByDay = [], requestsBar = [], schoolCountBar = [], requestsByGradesBar = [[], [], []], requestsBySubjectsBar = [[], [], []], subjects = [] }: statisticsData): Array<{
    title: string, components: ({
        component: 'bar',
        props: { data: ChartData<"bar", number[], unknown>, options?: ChartOptions<"bar"> }
    } |
    {
        component: 'line',
        props: { data: ChartData<"line", number[], unknown>, options?: ChartOptions<"line"> }
    }
    )[]
}> => [
        {
            title: 'סיכום רשותי',
            components: [{
                component: 'bar',
                props: {
                    data: {
                        labels: ['בקשות החלפה שפורסמו', 'תגובות חיוביות שהתקבלו', 'אישורים סופיים'],
                        datasets: [
                            {
                                data: requestsBar,
                                backgroundColor: [
                                    "#35788C",
                                    "#B7D7DE",
                                    "#FFB71A",
                                ]
                            },
                        ]
                    },
                    options: {
                        plugins: {

                            legend: {
                                display: false,
                            }
                        }
                    }
                }
            },
            {
                component: "bar",
                props: {
                    data: {
                        labels: ['בתי ספר רשומים', 'בתי ספר פעילים'],
                        datasets: [
                            {
                                data: schoolCountBar,
                                backgroundColor: [
                                    "#35788C",
                                    "#6EC0C1",
                                ],

                            },
                        ]
                    },
                    options: {
                        indexAxis: 'y',
                        // aspectRatio: 2,
                        plugins: {
                            title: {
                                display: false,
                                text: `${schoolCountBar?.reduce((a, b) => a + b, 0)} כניסות`,
                            },
                            legend: {
                                display: false,
                                position: 'left'
                            }
                        }
                    }
                }
            }]
        },

        {
            title: 'פניות לפי שכבות גיל',
            components: [{
                component: 'bar',
                props: {

                    data: {
                        labels: gradesArray.map(g => g.value),
                        datasets: [
                            { label: 'בקשות החלפה שפורסמו', data: requestsByGradesBar[0], backgroundColor: "#35788C" },
                            { label: 'תגובות חיוביות שהתקבלו', data: requestsByGradesBar[1], backgroundColor: "#B7D7DE", },
                            { label: 'אישורים סופיים', data: requestsByGradesBar[2], backgroundColor: "#FFB71A" }
                        ]
                    },
                }
            }]
        },


        {
            title: 'פניות לפי מקצוע לימוד',
            components: [{
                component: 'line',
                props: {
                    data: {
                        labels: subjects,
                        datasets: [
                            { label: 'בקשות החלפה שפורסמו', data: requestsBySubjectsBar[0], backgroundColor: "#35788C", borderColor: "#35788C" },
                            { label: 'תגובות חיוביות שהתקבלו', data: requestsBySubjectsBar[1], backgroundColor: "#B7D7DE", borderColor: "#B7D7DE" },
                            { label: 'אישורים סופיים', data: requestsBySubjectsBar[2], backgroundColor: "#FFB71A", borderColor: "#FFB71A" }
                        ]
                    },
                }
            }]
        },

        {
            title: 'פניות לפי ימים',
            components: [{
                component: 'bar',
                props: {
                    data: {
                        labels: daysOfWeekArray.map(d => d.value),
                        datasets: [
                            {
                                data: requestsBarByDay,
                                backgroundColor: [
                                    "#35788C",
                                    "#B7D7DE",
                                    "#FFB71A",
                                ]
                            },
                        ]
                    },
                    options: {
                        plugins: {
                            legend: {
                                display: false,
                            }
                        }
                    }
                }
            }]
        }
    ]