import { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';

import { withForm, FormInput, useForm, FormRules, useAlert, FormCheckBox } from '@hilma/forms';

import { useLoginStyles } from '../../routes/Login';
import { useFormStyles } from '../../constants';

import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

import axios from 'axios';

interface ResetPasswordFormProps {
    id: string;
    title: string;
    emailEntry: boolean;
    isResetPassword: boolean;
    description: string;
    initialValues: { email?: string; password?: string; };
    rules: FormRules;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ id, title, emailEntry, description, isResetPassword }) => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);

    const alert = useAlert();
    const form = useForm();

    const formClasses = useFormStyles();
    const classes = useLoginStyles();

    const onSubmit = async () => {
        try {
            if (loading) return;
            form.handleSubmit();
            if (!form.isValid || !Object.values(form.touched).length) return;
            setLoading(true);
            if (emailEntry) {
                const res = await axios.post('/api/admin/verify-reset-pass-mail', {
                    token: params.get("token"),
                    email: params.get("email"),
                    password: form.values.password,
                });
                if (res.data) return navigate("/login");
                else alert('הייתה בעייה, אנא נסו שוב מאוחר יותר');
            }
            else {
                // if (sent) return alert('כבר שלחנו הודעה לכתובת המייל במידה והיא קיימת במערכת', 'info');
                let res = await axios.post(`/api/admin/send-reset-pass-mail/${isResetPassword}`, { email: form.values.email });
                if (res.data.notExist) throw new Error();
                if (res) {
                    alert('שלחנו הודעה לכתובת המייל במידה והיא קיימת במערכת', 'info');
                    setLoading(false);
                    navigate("/login")
                }
            }
        } catch (error) {
            setLoading(false);
            alert('הייתה בעייה, אנא נסו שוב מאוחר יותר');
        }
        setLoading(false);
    }

    return (
        <>
            <div className={classes.titleContainer}>
                {!emailEntry &&
                    <Link to="/login">
                        <IconButton color="primary">
                            <ArrowForwardIcon />
                        </IconButton>
                    </Link>
                }
                <div >{title}</div>
            </div>
            <FormInput
                id={id}
                description={description}
                classes={{ root: classes.inputRoot, input: formClasses.input, container: classes.inputContainer, errorContainer: classes.error }}
                type={(showPassword || id == 'email') ? "text" : "password"}
                endAdornment={id != 'email' && (
                    <IconButton onClick={() => setShowPassword(prev => !prev)}>
                        {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                )}
            />

            {!isResetPassword && <div style={{ alignSelf: 'flex-start', marginTop: 20 }}>
                <FormCheckBox id='terms' description={<div>קראתי את <a href='/terms-and-conditions' target='_blank'>תנאי השימוש</a></div>} />
            </div>}

            <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                classes={{
                    root: classes.buttonRoot,
                    label: classes.buttonLabel
                }}
                disabled={loading}
            >
                אישור
            </Button>
        </>
    );
}

export default withForm()(ResetPasswordForm);