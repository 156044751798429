import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncEffect } from '@hilma/tools';

import { gradeSelect, sectorSelect, SOCKET_ADMIN_CANDIDATES, SOCKET_ADMIN_REMOVE_CANDIDATES, weekDaySelect } from '../../constants';
import { CandidateTableTypes, SelectDropdown, SelectDropdownMenu } from '../../types';
import { GenericCandidateTable, StarRating } from '../../componenets';
import { useGetDropdownData } from '../../hooks';

interface CandidateTablesProps {
	type: CandidateTableTypes;
}

const tableTitle: Record<CandidateTableTypes, string> = {
	[CandidateTableTypes.Approved]: 'מחליפים מאושרים',
	[CandidateTableTypes.Pending]: 'מחליפים ממתינים לאישור',
	[CandidateTableTypes.Denied]: 'מחליפים שלא אושרו',
}

const CandidateTables: React.FC<CandidateTablesProps> = ({ type }) => {

	const [subjectOptions, setSubjectOptions] = useState<SelectDropdownMenu[]>([{ value: 'all', label: 'כל המקצועות' }]);

	const getDropdownData = useGetDropdownData();
	const navigate = useNavigate();

	const selects: SelectDropdown[] = useMemo(() => [
		{
			selectName: "subject",
			defaultValue: 'all',
			selectMenu: subjectOptions
		}, sectorSelect, gradeSelect, weekDaySelect,
	], [subjectOptions]);

	useAsyncEffect(async () => {
		setSubjectOptions(await getDropdownData('/api/subject/get-subjects-select-options'));
	}, []);



	return (
		<>
			<GenericCandidateTable
				// additionalColumns={type === CandidateTableTypes.Approved ? [{ field: 'rating', title: 'דירוג', customRender: (candidate, i) => <StarRating numStars={candidate.rating} index={i} /> }] : []} //uncomment for reviews
				shouldRemoveRowOnDeleteSocketEvent={(row, rowsToRemove) => rowsToRemove?.findIndex(r => r.id === row.id) > -1}
				deleteSocketEvent={type === CandidateTableTypes.Pending ? SOCKET_ADMIN_REMOVE_CANDIDATES : undefined}
				onAddSocketEventMessage={type === CandidateTableTypes.Pending ? 'נוספו מחליפים שממתינים' : undefined}
				addSocketEvent={type === CandidateTableTypes.Pending ? SOCKET_ADMIN_CANDIDATES : undefined}
				selects={type === CandidateTableTypes.Approved ? selects : undefined}
				arrowOnClick={(row) => navigate(`/candidates/${type}/${row.id}`)}
				showCount={type === CandidateTableTypes.Pending}
				dataUrl={'/api/candidate/all'}
				tableTitle={tableTitle[type]}
				requestData={{ type }}
				wholeRowClick
				type={type}
				search
			/>
		</>
	)
};


export default CandidateTables;
