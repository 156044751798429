import Button, { ButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';

import { ButtonLoading } from '../general';

export const OvalButton: React.FC<ButtonProps & { loading?: boolean }> = ({ children, loading, onClick, ...rest }) => {

	const classes = useStyles();

	return (
		<Button
			children={loading ? <ButtonLoading greenLoading={false} /> : children ?? "עריכה"}
			onClick={loading ? undefined : onClick}
			className={classes.editButton}
			startIcon={<EditIcon />}
			variant='outlined'
			color='primary'
			{...rest} />
	)
};

const useStyles = makeStyles(({
	editButton: {
		borderRadius: '1rem',
		fontFamily: 'Assistant-SemiBold',
		padding: '2px 15px',
		minHeight: 30
	}
}));