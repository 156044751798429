import { useCallback, useMemo, useState } from 'react';
import { CheckBox, dirStyleGenerator, FormHelperText, Option, OptionsRule, useDirection, useForm, useFormInputStyles, useFormRefs, useFormRules, useTextFieldError } from '@hilma/forms';
import Select, { SelectProps } from '@material-ui/core/Select';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';

interface FormMultipleSelectProps extends SelectProps {
	id: string;
	description: string;
	options?: Option[];
}

export const FormMultipleSelect: React.FC<FormMultipleSelectProps> = ({ id, dir, description, options: o, ...rest }) => {
	const [containerTarget, setContainerTarget] = useState<HTMLDivElement | null>(null);

	const direction = useDirection();

	const classes = useStyles({ dir: dir || direction });
	const formInputClasses = useFormInputStyles();

	const fieldError = useTextFieldError(id);
	const formRules = useFormRules();
	const innerRef = useFormRefs();
	const form = useForm();

	const selectedValues = useMemo(() => form.values[id] || [], [form.values[id]]);
	const options = useMemo(() => {
		return o ?? (formRules[id] as OptionsRule)?.options;
	}, [formRules, o, id]);

	const onChange = useCallback((event: React.ChangeEvent<any>) => {
		const idsOfSelected = [...new Set(event.target.value)];
		form.setFieldValue(id, idsOfSelected);
	}, [id, options, form]);

	return (
		<div ref={setContainerTarget}>
			<div className={formInputClasses.containLables}>
				<InputLabel htmlFor={id} className={formInputClasses.label}>{description}</InputLabel>
			</div>
			<Select
				id={id}
				disableUnderline
				dir={dir || direction}
				className={classes.inputRoot}
				classes={{
					root: clsx(formInputClasses.input, fieldError && formInputClasses.border, classes.root),
					select: classes.select,
				}}
				inputRef={(el) => { if (innerRef) innerRef.current[id] = el }}
				value={selectedValues.length ? selectedValues : []}
				onChange={onChange}
				renderValue={() => <div className={classes.chips}>
					{selectedValues.map((id: any) => <Chip variant='outlined' color='primary' key={id} label={options && options.find(option => option?.id == id)?.value} className={classes.chip} />)}
				</div>}
				multiple
				MenuProps={{
					container: containerTarget,
					classes: {
						list: classes.list
					},
					disableScrollLock: true,
				}}
				{...rest}
			>
				<MenuItem disabled value={-1} className={classes.menuItem}>בחר {description}</MenuItem>
				{options?.map((option, index) => {
					const selected = selectedValues.findIndex((i: any) => i == option.id) > -1;
					return (
						<MenuItem
							key={index}
							value={option.id}
							className={clsx(classes.menuItem, selected && classes.selectedListItem)}
							children={<><CheckBox checked={selected} />{option.value}</>}
						/>
					)
				})}
			</Select>
			<FormHelperText text={fieldError} error />
		</div>
	);
}

const useStyles = makeStyles((theme) => ({
	chips: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	chip: {
		margin: 2,
		height: 20
	},

	inputRoot: {
		width: '100%',
	},

	root: {
		...dirStyleGenerator('padding', '24px !important')
	},

	select: {
		alignItems: 'center',
		borderRadius: '5px !important',

		[theme.breakpoints.up(500)]: {
			padding: '4px 7px',
			fontSize: 17,
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 7px',
			fontSize: 22,
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 10px',
			fontSize: 23,
		},

		'&:focus': {
			borderRadius: '5px !important',
		}
	},

	icon: {
		position: 'absolute',

		[theme.breakpoints.up(500)]: {
			...dirStyleGenerator('', 4),
			width: 30,
			height: 30
		},
		[theme.breakpoints.down(500)]: {
			...dirStyleGenerator('', 4),
			width: 30,
			height: 30
		},
		[theme.breakpoints.up(1600)]: {
			...dirStyleGenerator('', 10),
			width: 40,
			height: 40
		},
		marginTop: 5,
	},

	list: {
		[theme.breakpoints.up(500)]: {
			padding: '4px 0',
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 0',
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 0',
		}
	},

	menuItem: {
		fontFamily: 'Assistant-Regular',
		color: theme.palette.primary.main,

		[theme.breakpoints.up(500)]: {
			padding: '4px 15px',
			fontSize: 17,
		},
		[theme.breakpoints.down(500)]: {
			padding: '6px 15px',
			fontSize: 22,
		},
		[theme.breakpoints.up(1600)]: {
			padding: '9px 15px',
			fontSize: 23,
		},
	},

	selectedListItem: {
		background: 'transparent !important',
	}
}));