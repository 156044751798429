import { CandidateJob } from "../types";

export const jobs: Record<CandidateJob, string> = {
	[CandidateJob.Assistant]: 'סייעת',
	[CandidateJob.Teacher]: 'גננת',
	[CandidateJob.Approved] : 'מאושר',
	[CandidateJob.Denied] : 'נדחה',
	[CandidateJob.Pending] : 'בהמתנה',
}

export const jobsOptions: Record<string, string> = {
	[CandidateJob.Assistant]: 'סייעת',
	[CandidateJob.Teacher]: 'גננת'
}

export const jobsArray: { id: string; value: string }[] =
	Object.keys(jobs).map((key) => ({ id: key, value: jobs[key as CandidateJob] }))


export const jobsOptionsArray: { id: string; value: string }[] =
	Object.keys(jobsOptions).map((key) => ({ id: key, value: jobs[key as CandidateJob] }))