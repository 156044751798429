import { useState } from "react";
import { FormInput, useAlert, useForm, useGetError } from "@hilma/forms";
import makeStyles from "@material-ui/core/styles/makeStyles";
import ArrowBackwardIcon from '@material-ui/icons/ArrowBack';
import Box from "@material-ui/core/Box";
import axios from "axios";

import { SchoolSignUpScreens } from "../../types";
import { Button } from "../../general";
import { teal } from "../../constants";


interface ManangerInfoFormProps {
	setCurrentScreen: React.Dispatch<React.SetStateAction<SchoolSignUpScreens>>;
}

const ManangerInfoForm: React.FC<ManangerInfoFormProps> = ({ setCurrentScreen }) => {

	const [loading, setLoading] = useState(false);

	const classes = useStyles();
	const form = useForm();
	const alert = useAlert();
	const getError = useGetError();


	const onSubmit = async () => {

		setLoading(true);

		try {

			setTimeout(() => { }, 0);

			form.handleSubmit();

			if (Object.keys(form.errors).length || !Object.keys(form.touched)) return;

			await axios.post('/api/school/sign-up-new-school', { ...form.values, cityId: form.values.city.id, sector: form.values.sector.id, files: true });

			setCurrentScreen(SchoolSignUpScreens.Success);
		} catch (err: any) {
			
			alert(getError(err));
		} finally {
			setLoading(false);
		}
	}


	return (
		<div className={classes.container}>
			<FormInput id='schoolAdminName' description="שם מנהל" />
			<FormInput id='schoolAdminPhone' description="טלפון" />
			<FormInput id='schoolAdminEmail' description="מייל" />

			<Box className={classes.navigation}>
				<Button
					disabled={loading}
					variant="outlined"
					onClick={() => setCurrentScreen(SchoolSignUpScreens.SchoolInfo)}
					startIcon={<ArrowBackwardIcon />}
				>חזור</Button>

				<Button color='primary' disabled={loading} className={classes.nextButton} onClick={onSubmit}>הרשמה</Button>
			</Box>
		</div>
	);
}

export default ManangerInfoForm;

const useStyles = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		margin: '0 auto',
		width: '60vw',
		[theme.breakpoints.down(800)]: {
			width: '90vw'
		}
	},
	nextButton: {
		background: teal,
	},
	navigation: {
		justifyContent: 'space-between',
		display: 'flex',
		marginTop: 25,
		'& button': {
			borderRadius: 5,
			boxShadow: 'none',
			fontFamily: 'Assistant-Bold',
			'&:hover': {
				border: 'none'
			}
		},
		'& svg': {
			transform: 'rotate(180deg)'
		}
	}
}));