import makeStyles from '@material-ui/core/styles/makeStyles';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import { useTableAddButtonLabel, useTableAddButtonOnClick, useTableAddButtonYellow } from '../../../hooks';
import { darkYellow, forestGreen, yellow } from '../../../constants';

export const AddButton: React.FC = () => {

    const addButtonOnClick = useTableAddButtonOnClick();
    const addButtonYellow = useTableAddButtonYellow();
    const addButtonLabel = useTableAddButtonLabel();
    const classes = useStyles();


    if (!addButtonOnClick) return null;

    return (
        <Button
            variant="contained"
            color="secondary"
            className={clsx(classes.addButton, addButtonYellow && classes.yellow)}
            startIcon={<AddCircleIcon />}
            onClick={addButtonOnClick}
            children={addButtonLabel}
        />
    );
};

//styling
export const useStyles = makeStyles(theme => ({
    addButton: {
        height: '2rem',
        borderRadius: '1rem',
        fontFamily: 'Assistant-Bold',
        color: 'white',
        [theme.breakpoints.down(800)]: {
            whiteSpace: 'nowrap'
        }
    },
    yellow: {
        backgroundColor: yellow,
        color: forestGreen,
        '&:hover': {
            backgroundColor: darkYellow,
        }
    }
}));
