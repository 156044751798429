import { SchoolTableTypes } from "../types";

export const schoolTableRoutes: SchoolTableTypes[] = [
	SchoolTableTypes.Approved,
	SchoolTableTypes.Pending,
	SchoolTableTypes.Denied,
];

export const schoolPageRoutes: SchoolTableTypes[] = [
	SchoolTableTypes.Approved,
	SchoolTableTypes.Pending,
	SchoolTableTypes.Denied,

];