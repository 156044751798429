import { useState, ChangeEvent, useMemo, useEffect } from 'react';
import produce from 'immer';
import moment from 'moment';
import clsx from 'clsx';
import 'date-fns';

import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import { DatePicker } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { lightGray, teal } from '../../constants';
import { SelectDropdown } from '../../types';

interface TableSelectProps {
    select: SelectDropdown;
    onSelect?(item: any): void;
}
const MIN_DATE = new Date(2000, 1, 1); //I set initial min date to 01 01 2000
const MAX_DATE = new Date(); //I set max date to today
const CHOSEN_DATE = "CHOSEN_DATE";

//!! If the selects aren't acting properly between tables and select value are saved from a different table you may need to add key's to the components
const TableSelect: React.FC<TableSelectProps> = ({ select, onSelect }) => {
    const classes = useStyles()
    const { selectMenu, defaultValue, dateRange, minWidth, selectName, ...rest } = select;
    const [selectedOption, setSelectedOption] = useState<any>(defaultValue);
    const [isOpened, setIsOpened] = useState(false);

    const [chosenDate, setChosenDate] = useState<[Date | null, Date | null]>([null, null]);
    const [minDate, setMinDate] = useState<Date | null>(MIN_DATE);
    const [openDate, setOpenDate] = useState(false);

    const chooseDateClick = () => {
        setChosenDate([null, null]);
        setMinDate(MIN_DATE);
        setOpenDate(true);
    };

    const onClick = (event: ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== CHOSEN_DATE) {
            setSelectedOption(event.target.value);
            if (onSelect) onSelect(event.target.value);
        }
    };

    const handleChangeDate = (date: MaterialUiPickersDate) => {
        if (!chosenDate[0]) {
            setMinDate(date);
            setChosenDate([moment(date)/* .add(-1, 'day') */.toDate(), null]);
        } else {
            setChosenDate(produce(draft => { draft[1] = date }))
            setOpenDate(false)
            setSelectedOption(CHOSEN_DATE)
            if (onSelect) onSelect([chosenDate[0], date]);
        }
    }

    const dateString = useMemo(
        () => !chosenDate[0] || !chosenDate[1] ? "" : `${chosenDate[0] && chosenDate[0].getDate() + "." + (chosenDate[0].getMonth() + 1) + "." + (chosenDate[0].getFullYear() % 100)}-${chosenDate[1] && chosenDate[1].getDate() + "." + (chosenDate[1].getMonth() + 1) + "." + (chosenDate[1].getFullYear() % 100)}`,
        [chosenDate]);

    useEffect(() => {
        if (select.dateRange && Array.isArray(defaultValue)) {
            setSelectedOption(CHOSEN_DATE);
            setChosenDate([new Date(defaultValue[0]), new Date(defaultValue[1])]);
        } else setSelectedOption(defaultValue);
    }, [defaultValue]);

    return (
        <FormControl>
            <Select
                className={clsx(classes.container, isOpened ? classes.selectEmpty : classes.selectClosed)}
                value={selectedOption || selectedOption === 0 ? selectedOption : defaultValue}
                renderValue={selectedOption === CHOSEN_DATE ? () => dateString : undefined}
                inputProps={{ classes: { icon: classes.icon } }}
                style={{ minWidth: minWidth ?? 145 }}
                disableUnderline/* ={isOpened ? true : false} */
                onClose={() => setIsOpened(false)}
                onOpen={() => setIsOpened(true)}
                IconComponent={ExpandMoreIcon}
                onChange={onClick}
                displayEmpty
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    getContentAnchorEl: null,
                    PaperProps: { className: classes.paperProps }
                }}
                {...rest}
            >
                {rest.placeholder && <MenuItem className={classes.menuItem} disabled value=''>{rest.placeholder}</MenuItem>}
                {selectMenu.map((item, index) => <MenuItem className={classes.menuItem} key={index} value={item.value}>{item.label}</MenuItem>)}
                {dateRange && <MenuItem onClick={chooseDateClick} className={classes.menuItem} value={CHOSEN_DATE}>בחר טווח תאריכים</MenuItem>}
            </Select>
            <DatePicker
                ToolbarComponent={() => <div className={classes.dateTitle}> {chosenDate[0] ? "בחר תאריך סיום" : "בחר תאריך התחלה"}</div>}
                style={{ width: minWidth ? minWidth : 145 }}
                InputProps={{ disableUnderline: true }}
                className={classes.datePicker}
                onChange={handleChangeDate}
                // maxDate={MAX_DATE}
                format="dd/MM/yy"
                minDate={minDate}
                variant="inline"
                open={openDate}
                value={null}
            />
        </FormControl >
    );
}

export default TableSelect;

const useStyles = makeStyles(theme => ({

    datePicker: {
        height: 0,
    },

    icon: {
        color: teal,
        paddingLeft: '6px',
        paddingRight: '6px',
        position: "absolute"
    },

    container: {
        fontFamily: "Assistant-Regular",
        border: `1px solid ${teal}`,
        transition: 'all 0.5s',
        textAlign: 'left',
        height: '1.8rem',
        fontSize: "0.9em",
        color: teal,
        "& .MuiSelect-select": {
            paddingRight: "16px",
            paddingLeft: "16px"
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: 'transparent',
        },
        [theme.breakpoints.down(800)]: {
            width: '100%',
        }
    },


    selectEmpty: {
        boxShadow: "0px 9px 9px rgba(0, 0, 0, 0.3)",
        borderTopRightRadius: "4px",
        backgroundColor: lightGray,
        borderTopLeftRadius: "4px",
    },

    selectClosed: {
        backgroundColor: 'transparent',
        borderRadius: '1rem',
    },

    menuItem: {
        color: teal,
        fontFamily: "Assistant-Regular",
        justifyContent: 'end',
        fontSize: '0.9rem',
        display: 'flex',
    },

    dateTitle: {
        color: teal,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        fontFamily: "Assistant-Bold",
    },

    paperProps: {
        boxShadow: "0px 9px 9px rgba(0, 0, 0, 0.3)",
        borderBottom: `1px solid ${teal}`,
        borderRight: `1px solid ${teal}`,
        borderLeft: `1px solid ${teal}`,
        marginRight: -1,
        marginTop: -2,
        backgroundColor: '#f5f5f5',
        boxSizing: 'content-box',
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        maxHeight: "295px",

        '& .MuiList-padding': {
            padding: "0"
        }
    }
}));
