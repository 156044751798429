//mui
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface CheckedButtonsProps {
	icon?: JSX.Element;
	onClick?(): void;
	buttonText?: string;
	loading?: boolean;
}
export const CheckedButtons: React.FC<CheckedButtonsProps> = ({ icon, onClick, buttonText, loading }) => {
	const classes = useStyles()
	return (
		<Button className={classes.checkedButtons} disabled={loading} color='primary' onClick={onClick} startIcon={icon}>
			{buttonText}
		</Button>
	)
};

//styling
const useStyles = makeStyles({
	checkedButtons: {
		marginLeft: '15px',
		fontFamily: 'Assistant-SemiBold',
	},
});