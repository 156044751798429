import { useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ButtonProps } from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';

import { ConfirmPopup, ConfirmPopupProps } from '../general';
import { OvalButton } from './OvalButton';

interface EditAndDeleteButtonsProps {
    editButtonProps?: ButtonProps & { loading?: boolean };
    deleteButtonProps?: ButtonProps & { loading?: boolean };
    deletePopupProps?: Partial<ConfirmPopupProps>;
};

export const EditAndDeleteButtons: React.FC<EditAndDeleteButtonsProps> = ({ editButtonProps, deleteButtonProps, deletePopupProps }) => {

    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <>
            <div className={classes.container}>
                {deleteButtonProps && <OvalButton children='מחיקה' startIcon={<DeleteIcon />} onClick={() => setDeletePopup(true)} {...deleteButtonProps} />}
                {editButtonProps && <OvalButton {...editButtonProps} />}
            </div>
            {deletePopupProps &&
                <ConfirmPopup
                    onClose={() => setDeletePopup(false)}
                    open={deletePopup}
                    {...deletePopupProps}
                />}
        </>
    )
};

const useStyles = makeStyles(({
    container: {
        display: 'flex',
        gap: 10,
    }
}));