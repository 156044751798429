import { useState, ChangeEvent, useMemo, useEffect } from 'react';
import produce from 'immer';
import moment from 'moment';
import 'date-fns';
//mui
import makeStyles from '@material-ui/core/styles/makeStyles';
import createStyles from '@material-ui/core/styles/createStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
//style
import clsx from 'clsx';
import { SelectDropdown } from '../types/interfaces/selectDropDown.interface';
import { teal } from '../constants';
//ts

interface SelectProps {
    select: SelectDropdown;
    onSelect?(item: any): void;
}
const MIN_DATE = new Date(2000, 1, 1); //I set initial min date to 01 01 2000
const MAX_DATE = new Date(); //I set max date to today
const CHOSEN_DATE = "CHOSEN_DATE";

const Selects: React.FC<SelectProps> = ({ select, onSelect }) => {
    const classes = useStyles()
    const { selectMenu, defaultValue, dateRange, minWidth, selectName, ...rest } = select;
    const [selectedOption, setSelectedOption] = useState<any>(defaultValue);
    const [isOpened, setIsOpened] = useState(false);

    const [chosenDate, setChosenDate] = useState<[Date | null, Date | null]>([null, null]);
    const [minDate, setMinDate] = useState<Date | null>(MIN_DATE);
    const [openDate, setOpenDate] = useState(false);

    const chooseDateClick = () => {
        setChosenDate([null, null]);
        setMinDate(MIN_DATE);
        setOpenDate(true);
    };

    const onClick = (event: ChangeEvent<{ value: unknown }>) => {
        if (event.target.value !== CHOSEN_DATE) {
            setSelectedOption(event.target.value);
            if (onSelect) onSelect(event.target.value);
        }
    };

    const handleChangeDate = (date: MaterialUiPickersDate) => {
        if (!chosenDate[0]) {
            setMinDate(date);
            setChosenDate([moment(date)/* .add(-1, 'day') */.toDate(), null]);
        } else {
            setChosenDate(produce(draft => { draft[1] = date }))
            setOpenDate(false)
            setSelectedOption(CHOSEN_DATE)
            if (onSelect) onSelect([chosenDate[0], date]);
        }
    }

    const dateString = useMemo(
        () => !chosenDate[0] || !chosenDate[1] ? "" : `${chosenDate[0] && chosenDate[0].getDate() + "." + (chosenDate[0].getMonth() + 1) + "." + (chosenDate[0].getFullYear() % 100)}-${chosenDate[1] && chosenDate[1].getDate() + "." + (chosenDate[1].getMonth() + 1) + "." + (chosenDate[1].getFullYear() % 100)}`,
        [chosenDate]);
    useEffect(() => {
        if (select.dateRange && Array.isArray(defaultValue)) {
            setSelectedOption(CHOSEN_DATE);
            setChosenDate([new Date(defaultValue[0]), new Date(defaultValue[1])]);
        } else setSelectedOption(defaultValue);
    }, [defaultValue]);

    return (
        <FormControl>
            <Select
                style={{ minWidth: minWidth ? minWidth : 145 }}
                value={selectedOption || selectedOption === 0 ? selectedOption : defaultValue}
                onChange={onClick}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                    },
                }}
                renderValue={selectedOption === CHOSEN_DATE ? () => dateString : undefined}
                disableUnderline={isOpened ? true : false}
                className={clsx(classes.sel, isOpened ? classes.selectEmpty : classes.selectClosed)}
                onOpen={() => setIsOpened(true)}
                onClose={() => setIsOpened(false)}
                MenuProps={{
                    anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                    },
                    transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                    },
                    disableScrollLock: true,
                    getContentAnchorEl: null,
                    PaperProps: { style: { backgroundColor: '#f5f5f5', maxHeight: "295px", borderTopLeftRadius: 0, borderTopRightRadius: 0, boxShadow: "0px 9px 9px rgba(0, 0, 0, 0.3)" } }
                }}
                {...rest}
            >
                {rest.placeholder && <MenuItem className={classes.men} disabled value=''>{rest.placeholder}</MenuItem>}
                {selectMenu.map((item, index) =>
                    <MenuItem className={classes.men} key={index} value={item.value}>{item.label}</MenuItem>
                )}
                {dateRange && <MenuItem onClick={chooseDateClick} className={classes.men} value={CHOSEN_DATE}>בחר טווח תאריכים</MenuItem>}
            </Select>
            <DatePicker
                open={openDate}
                value={null}
                format="dd/MM/yy"
                style={{ width: minWidth ? minWidth : 145 }}
                className={classes.datePicker}
                InputProps={{
                    disableUnderline: true,
                }}
                onChange={handleChangeDate}
                ToolbarComponent={() => { return <div id="select-date-picker-title">{chosenDate[0] ? "בחר תאריך סיום" : "בחר תאריך התחלה"}</div> }}
                minDate={minDate}
                maxDate={MAX_DATE}
                variant="inline"
            />
        </FormControl >
    );
}

export default Selects;

const useStyles = makeStyles((theme) =>
    createStyles({
        datePicker: {
            backgroundColor: 'red',
            height: 0,
        },
        selectExpand: {
            backgroundColor: "e6e6e6",
            textAlign: "initial"
        },
        icon: {
            color: teal,
            paddingLeft: '6px',
            paddingRight: '6px',
            position: "absolute"
        },
        sel: {
            color: teal,
            fontFamily: "Assistant-Regular",
            "& .MuiSelect-select": {
                paddingRight: "16px",
                paddingLeft: "16px"
            },
            "& .MuiSelect-select:focus": {
                backgroundColor: 'transparent',
            },
        },
        selectEmpty: {
            backgroundColor: "e6e6e6",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            boxShadow: "0px 9px 9px rgba(0, 0, 0, 0.3)",
        },
        selectClosed: {
            backgroundColor: 'transparent',
        },
        men: {
            color: teal,
            justifyContent: "end",
            fontFamily: "Assistant-Regular",
            fontSize: '0.9rem',
        }
    }),
);