import makeStyles from "@material-ui/core/styles/makeStyles";

export const usePopupStyles = makeStyles(theme => ({

    container: {
        backgroundColor: "#fdfdfd",
        color: theme.palette.primary.main
    },

    title: {
        fontWeight: "bold",
        fontSize: "1.6rem",
    },
    text: {
        fontSize: "1.3rem",
        marginBottom: "2em",
    },
    
    bottom: {
        display: "flex",
        justifyContent: "end",
        gap: 10,
    },

    content: {
        padding: '0px 30px 30px 30px',
    },

    cancel: {
        margin: 5,
        padding: 7,
    }

}));