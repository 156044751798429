import { useMemo, useState, useEffect } from "react";
import { FormProvider, useAlert, useForm, useOnSubmitRef } from "@hilma/forms";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonProps } from "@material-ui/core/Button";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";

import {
  getAlertError,
  getSchoolRequestChipProps,
  getRequestTableStatus,
} from "../../functions";
import {
  errors,
  schoolRules,
  requestTypeSelect,
  weekDaySelect,
} from "../../constants";
import {
  TitleAndBackArrow,
  EditAndDeleteButtons,
  TableChip,
} from "../../componenets";
import { RequestTable, SchoolStatus, SchoolTableTypes } from "../../types";

import RequestTableGeneric from "../requests/RequestTableGeneric";
import EditSchool from "./EditSchool";
import { useLocation } from "react-router";

interface SchoolProps {
  type?: SchoolTableTypes;
}

const initialValues = {
  image: null,
  imagePath: "",
  name: "",
  admins: [],
  mainId: "",
  schoolSymbol: "",
  phone: "",
  sector: "",
  heAddress: "",
  city: "",
  status: "",
  imageFile: null,
};

const School: React.FC<SchoolProps> = ({ type }) => {
  const [isEditing, setIsEditing] = useState<boolean>(
    type === SchoolTableTypes.Pending
  );
  const [loading, setLoading] = useState<boolean>(false);

  const location = useLocation();

  const { schoolId } = useParams<{ schoolId: string }>();

  const onSubmitRef = useOnSubmitRef();
  const navigate = useNavigate();
  const alert = useAlert();
  const form = useForm();

  const saveSchool = (add?: boolean) => {
    if (loading) return;
    onSubmitRef.current = () => save(add);
    form.handleSubmit();
  };

  const save = async (add?: boolean) => {
    try {
      if (Object.values(form.errors).some((e) => !!e)) return;
      setLoading(true);
      if (form.values.imageFile instanceof File) delete form.values.image;
      if (add) form.values.status = SchoolStatus.Approved;
      const { data } = await axios.post(
        `/api/school/update/${schoolId ?? ""}`,
        {
          ...form.values,
          files: true,
          imagePath: form.values.imagePath,
          sector: form.values.sector?.id,
          city: form.values?.city?.heCityName,
          heAddress: form.values.heAddress.description ?? form.values.heAddress,
        }
      );
      alert(
        add ? errors.addDetailsSuccess : errors.updateDetailsSuccess,
        "success"
      );
      navigate(`/schools/${form.values.status}${add ? `/${data.id}` : ""}`);
    } catch (error: any) {
      console.error("error: ", error);
      alert(
        getAlertError(
          error,
          add ? errors.addSchoolError : errors.updateDetailsError
        )
      );
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoading(true);
      await axios.delete(`/api/school/delete/${schoolId}`);
      alert(errors.deleteSchoolSuccess, "success");
      navigate(`/schools/${type}`);
    } catch (error: any) {
      console.error("error: ", error);
      alert(errors.deleteSchoolError);
      setLoading(false);
    }
  };

  const ovalButtonProps = useMemo<ButtonProps>(() => {
    if (!type)
      return {
        onClick: () => saveSchool(true),
        style: { color: "white" },
        variant: "contained",
        color: "secondary",
        children: "הוספה",
        startIcon: null,
        loading,
      };
    if (isEditing)
      return {
        onClick: () => saveSchool(),
        style: { color: "white" },
        variant: "contained",
        color: "secondary",
        startIcon: null,
        children: "עדכן",
        loading,
      };
    return { onClick: () => setIsEditing(true) };
  }, [isEditing, type, saveSchool, loading]);

  useEffect(() => {
    if (location.state) setIsEditing(true);
  }, []);

  useAsyncEffect(async () => {
    if (!schoolId) return;
    try {
      setLoading(true);
      const { data } = await axios.get(
        `/api/school/get-school-details/${schoolId}`
      );
      form.setValues({
        ...data,
        image: data.imagePath,
        sector: { id: data.sector },
        heAddress: { description: data.heAddress },
        mainId: data.schoolMainAdmin?.id,
        admins: [...(data.schoolAdmin || [])],
      });
    } catch (error) {
      console.error("error: ", error);
      alert(errors.getDataError);
    } finally {
      setLoading(false);
    }
  }, [schoolId]);

  return (
    <>
      <TitleAndBackArrow
        title={(isEditing ? "" : "קריאות של ") + form?.values?.name}
        onClick={() =>
          isEditing && type !== SchoolTableTypes.Pending
            ? setIsEditing(false)
            : navigate(`/schools/${type}`)
        }
        endComponent={
          <EditAndDeleteButtons
            editButtonProps={ovalButtonProps}
            deleteButtonProps={
              isEditing && form.values?.canDelete ? {} : undefined
            }
            deletePopupProps={{
              title: "מחיקת בית ספר",
              text: (
                <div>
                  האם אתה בטוח שברצונך למחוק את בית הספר
                  <strong> לצמיתות</strong>?
                </div>
              ),
              confirmText: "מחיקה",
              onConfirm: handleDelete,
            }}
          />
        }
      />
      {!type || isEditing ? (
        <EditSchool loading={loading} schoolId={schoolId} />
      ) : (
        <RequestTableGeneric
          arrowOnClick={(row: RequestTable) =>
            navigate(`/requests/${getRequestTableStatus(row)}/${row.id}`, {
              state: {
                from: `/schools/${SchoolTableTypes.Approved}/${schoolId}`,
              },
            })
          }
          additionalColumns={[
            { field: "acceptorName", title: "שם מחליף", type: "boldSearch" },
            {
              title: "סטטוס",
              customRender: (row) => (
                <TableChip {...getSchoolRequestChipProps(row)} />
              ),
            },
          ]}
          selects={[weekDaySelect, requestTypeSelect]}
          requestData={{ school: schoolId }}
        />
      )}
    </>
  );
};
const SchoolComp: React.FC<SchoolProps> = (props) => (
  <FormProvider initialValues={initialValues} rules={schoolRules}>
    <School {...props} />
  </FormProvider>
);
export default SchoolComp;
