import makeStyles from '@material-ui/styles/makeStyles';
import moment from 'moment';
import clsx from 'clsx';

import { daysOfWeekObject } from '../constants';
import { DaysOfWeek } from '../types';

interface FormattedDateProps {
	date?: string | Date;
	classes?: { container?: string, text?: string };
}

export const FormattedDate: React.FC<FormattedDateProps> = ({ date, classes }) => {

	const styleClasses = useStyles();

	if (!date) return null;

	return (
		<div className={clsx(styleClasses.container, classes?.container)}>
			<span className={clsx(styleClasses.dayOfWeek, classes?.text)} >{`יום ${daysOfWeekObject[new Date(date).getDay() as DaysOfWeek]}`}</span>
			<span className={classes?.text}>{`| ${moment(date).format('DD.MM.YY')}`}</span>
		</div >
	)
};

const useStyles = makeStyles(({
	container: {
		display: 'flex'
	},
	dayOfWeek: {
		textAlign: "start",
		paddingInlineEnd: "5px"
	}
}))