import { useAlert, useBaseI18n } from '@hilma/forms';
import axios from 'axios';

import { SelectDropdownMenu } from '../types';

export const useGetDropdownData = () => {
	const alert = useAlert();
	const errors = useBaseI18n(i18n => i18n.error);

	const getDropdownData = async (url: string) => {
		const { data } = await axios.get<SelectDropdownMenu[]>(url)
			.catch(() => {
				alert(errors.generalSystem);
				return { data: [] as SelectDropdownMenu[] };
			});
		return (prevState: SelectDropdownMenu[]) => [...prevState, ...data];
	}

	return getDropdownData;
}